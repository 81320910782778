/* ------------------------------ core imports ------------------------------ */
import { useState, useEffect, createContext } from "react";
import { Routes, Route } from "react-router-dom";

/* ---------------------------- internal imports ---------------------------- */

import DeveloperMenu from "../views/DeveloperMenu";
import FocusStructure from "../views/FocusStructure";
import ForgotPassword from "../views/ForgotPassword";
import Login from "../views/Login";
import Account from "../views/account/Account";
import PasswordReset from "../views/PasswordReset";
import UserManager from "../views/user-manager/UserManager";
import ProfileManager from "../views/profiles/ProfileManager";
import EmailVerifyReturn from "../views/EmailVerifyReturn";
import EmailVerifyNotice from "../views/EmailVerifyNotice";
import NoticeScreen from "../views/NoticeScreen";
import Dashboard from "../views/Dashboard";
import RouteNotFound from "../views/RouteNotFound";
import UserDetail from "../views/user-detail/UserDetail";
import ProfileDetail from "../views/profile-detail/ProfileDetail";
import ProfileInvite from "../views/profile-invite/ProfileInvite";
import CoreStructure from "../views/CoreStructure";
import Onboarding from "../views/onboarding/Onboarding";
import ErrorFallback from "../views/ErrorFallback";
import RequireAuth from "./RequireAuth";
import AuthService from "../services/AuthService";
import Transcribe from "../views/transcriber/Transcribe";
import Caller from "../views/caller/Caller";

// external imports
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

//  - exporting context -
export const DarkMode = createContext(false);

// - export the app -
export default function App() {
  // - config -
  const includeExamplesPages = process.env.MIX_INCLUDE_EXAMPLE_PAGES == "true";
  const includeFeaturesPages =
    process.env.MIX_FEATURE_ORGANISATION_PROFILES == "true";

  // - state -
  const [darkMode, setDarkMode] = useState(false);
  const [examplePages, setExamplePages] = useState(null);

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    // If we want to import the example pages import example pages
    if (includeExamplesPages) importExamplePages();

    // Attach callback to Auth Service so the current user updates when the auth service updates
    AuthService.addUpdateUserCallback("app_dark_mode", (currentUser) =>
      setDarkMode(currentUser.dark_mode == 1),
    );
  }, []);

  /* -------------------------------- functions ------------------------------- */
  // Imports the example pages from the ExampleRoutes file
  async function importExamplePages() {
    // Load example pages if they are needed
    setExamplePages(
      (await import("../views/examples/routes/ExampleRoutes")).default,
    );
  }

  useEffect(() => {
    console.log(examplePages);
  }, [examplePages]);

  // changes the dark mode setting
  async function changeDarkMode(newDarkMode) {
    setDarkMode(newDarkMode);
    await AuthService.changeDarkMode(newDarkMode);
  }

  return (
    <div className="App">
      <Toaster />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <DarkMode.Provider value={{ darkMode, changeDarkMode }}>
          <Routes>
            {/* No Auth Pages */}
            <Route path="verify-email/:id" element={<EmailVerifyReturn />} />
            <Route path="verify-email" element={<EmailVerifyNotice />} />
            <Route
              path="blocked"
              element={
                <NoticeScreen
                  title="Blocked"
                  message="You have been blocked from accessing the system, please contact an administrator for more information."
                />
              }
            />
            <Route
              path="no-profile"
              element={
                <NoticeScreen
                  title="No Profile"
                  message={
                    <>
                      You don't have a profile connected to your account, you
                      will need to be added to a profile or have one created for
                      you. Please contact your organisation profile manager(s)
                      or a system administrator for more information. <br />
                      <br /> Login to a different account using the
                      <Link to="/login"> login </Link> page.
                    </>
                  }
                />
              }
            />
            <Route
              path="profile-not-found"
              element={
                <NoticeScreen
                  title="Profile Not Found"
                  message={
                    <>
                      You do not have permission to see the profile you are are
                      trying to access or it may have been removed. If you need
                      access to a profile you are currently unable to see please
                      contact a profile manager. <br /> <br /> Return
                      <Link to="/"> home</Link>.
                    </>
                  }
                />
              }
            />
            <Route path="login" element={<Login />} />
            <Route path="password-reset" element={<PasswordReset />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="register/:id"
              element={
                <FocusStructure>
                  <Onboarding />
                </FocusStructure>
              }
            />

            {/* Authed Pages  */}
            <Route
              path="/"
              element={
                <RequireAuth>
                  <CoreStructure title="Dashboard">
                    <Dashboard />
                  </CoreStructure>
                </RequireAuth>
              }
            />
            <Route
              path="developer-menu"
              element={
                <RequireAuth permissions={["see admin navigation"]}>
                  <CoreStructure title="Developer Menu">
                    <DeveloperMenu />
                  </CoreStructure>
                </RequireAuth>
              }
            />
            <Route
              path="caller"
              element={
                <RequireAuth>
                  <CoreStructure title="Caller">
                    <Caller />
                  </CoreStructure>
                </RequireAuth>
              }
            />
            <Route
              path="transcribe"
              element={
                <RequireAuth>
                  <CoreStructure title="Transcriber">
                    <Transcribe />
                  </CoreStructure>
                </RequireAuth>
              }
            />
            <Route
              path="account"
              element={
                <RequireAuth>
                  <CoreStructure title="My Account">
                    <Account />
                  </CoreStructure>
                </RequireAuth>
              }
            />
            <Route
              path="user-manager"
              element={
                <RequireAuth permissions={["view users"]}>
                  <CoreStructure title="User Manager">
                    <UserManager />
                  </CoreStructure>
                </RequireAuth>
              }
            />
            <Route
              path="user-manager/:id"
              element={
                <RequireAuth permissions={["view users"]}>
                  <CoreStructure title="User Details">
                    <UserDetail />
                  </CoreStructure>
                </RequireAuth>
              }
            />

            {includeFeaturesPages ? (
              <>
                <Route
                  path="profiles"
                  element={
                    <RequireAuth permissions={["view profiles"]}>
                      <CoreStructure title="Profile Manager">
                        <ProfileManager />
                      </CoreStructure>
                    </RequireAuth>
                  }
                />
                ,
                <Route
                  path="profile/:id"
                  element={
                    <RequireAuth>
                      <CoreStructure title="Profile Details">
                        <ProfileDetail />
                      </CoreStructure>
                    </RequireAuth>
                  }
                />
                <Route
                  path="profile-invite/:id"
                  element={
                    <RequireAuth>
                      <CoreStructure title="Profile Details">
                        <ProfileInvite />
                      </CoreStructure>
                    </RequireAuth>
                  }
                />
              </>
            ) : null}

            {/* Show example pages if we have any (controlled by .env) */}
            {examplePages ? examplePages : null}

            {/* Shows Error page for when the route was not found in the above list */}
            <Route
              path="*"
              element={
                <RequireAuth>
                  <RouteNotFound />
                </RequireAuth>
              }
            />
          </Routes>
        </DarkMode.Provider>
      </ErrorBoundary>
    </div>
  );
}
