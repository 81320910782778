/* ------------------------------ core imports ------------------------------ */
import { forwardRef, useContext } from "react";

/* ---------------------------- external imports ---------------------------- */
import { Dropdown } from "react-bootstrap";
import { LuChevronDown } from "react-icons/lu";

/* ---------------------------- internal imports ---------------------------- */

import { DarkMode } from "../App";

// Return a JSX dropdown that accepts row actions and populates the dropdown with the actions
export default function DatatableRowActions(props) {
  // destruct props passed from parent
  const { label, data, actions } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */

  /* --------------------------------- effects -------------------------------- */

  /* -------------------------------- functions ------------------------------- */
  // capture the action menu scroll event and pass it to the main content
  function handleActionMenuScroll(event) {
    // get main content
    const mainContent = document.getElementById("main-content");

    // scroll main content using the delta from the scroll event
    mainContent?.scrollBy(event.deltaX, event.deltaY);
  }

  //custom toggle UI
  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <div className={darkMode ? "chevron-selector_dark" : "chevron-selector"}>
        <LuChevronDown
          size="20"
          style={{ color: darkMode ? "white" : "black", margin: "auto" }}
        />
      </div>
    </a>
  ));

  // custom menu UI
  const CustomMenu = forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">{children}</ul>
        </div>
      );
    },
  );

  /* --------------------------------- markup --------------------------------- */
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        variant="secondary"
        id="dropdown-basic"
        style={{ height: "100%" }}
      >
        {label}
      </Dropdown.Toggle>

      <div
        onWheel={handleActionMenuScroll}
        style={{ zIndex: 9999, position: "fixed", inset: "0 0 auto auto" }}
      >
        <Dropdown.Menu as={CustomMenu}>
          {actions.length == 0 ? (
            <Dropdown.Item disabled>
              <div className={"tw-flex tw-items-center"}>{"No Actions"}</div>
            </Dropdown.Item>
          ) : (
            actions.map((action, index) => (
              <Dropdown.Item key={index} onClick={() => action.onClick(data)}>
                <div className={"tw-flex tw-items-center"}>
                  <span className="pe-2">{action.icon}</span>
                  {action.label}
                </div>
              </Dropdown.Item>
            ))
          )}
        </Dropdown.Menu>
      </div>
    </Dropdown>
  );
}
