/* ------------------------------ core imports ------------------------------ */
import { useState, forwardRef, useRef, useImperativeHandle } from "react";

/* ---------------------------- external imports ---------------------------- */
import Toggle from "react-toggle";
import { VscEdit, VscSave } from "react-icons/vsc";

// Internal Imports
import Form from "./Form";

export default forwardRef((props, ref) => {
  // Get Properties from props (passed from parent)
  const {
    onStartEdit, // A Callback function used when isEditing becomes true because the user toggled the form
    onSuccess, // A Callback function used when a the form is submitted successfully
    onSubmit, // A Callback function used when the user attempts to submit the form
    disabled, // Boolean indicates if the form is disabled or not
    canEdit = true, // Boolean used to control if the form can be edited
    useCols = false, // Boolean used to control if the form should use columns
  } = props;

  const [isEditing, setIsEditing] = useState(false);

  /* ---------------------------------- refs ---------------------------------- */
  // create form ref
  let formRef = useRef();

  // use ref passed in from parent as form ref or create new form ref
  ref = ref ? ref : useRef();

  // use imperative handle allows the parent to access functions from this component
  useImperativeHandle(ref, () => ({
    ...formRef.current, // include any ref methods / properties from the formRef
    isEditing,
  }));

  // Runs when the form submit is successful
  async function afterSubmit(response) {
    // Reset green ticks on form items be setting them all to untouched (unchanged)
    formRef.current.form().setTouched({}, false);
    setIsEditing(false);
  }

  // Toggles the status of the form from editing to viewing
  function toggleForm() {
    // If form is currently being edited we should save our changes
    if (isEditing) {
      formRef.current.form().handleSubmit();
    } else {
      // Run parents start edit method
      onStartEdit && onStartEdit();

      // Toggle isEditing status
      setIsEditing(!isEditing);
    }
  }

  return (
    <>
      {/* Only show toggle if can edit is true */}
      {canEdit && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Toggle
            checked={isEditing}
            onChange={() => toggleForm()}
            icons={{
              checked: (
                <VscSave
                  style={{ color: "white", fontSize: 12, paddingBottom: 1 }}
                />
              ),
              unchecked: (
                <VscEdit
                  style={{ color: "white", fontSize: 12, paddingBottom: 1 }}
                />
              ),
            }}
          />
        </div>
      )}
      <Form
        {...props}
        ref={formRef}
        useCols={useCols}
        onSubmit={(data) => {
          if (onSuccess) onSubmit(data);
          afterSubmit(data);
        }}
        onSuccess={(data) => {
          if (onSuccess) onSuccess(data);
          afterSubmit(data);
        }}
        disabled={!isEditing || disabled}
      />
    </>
  );
});
