// Provides the ability to generate consistent colors from a value

// list of classes that hold the system colors
const colors = [
  "#597dff", // Blue
  "#ff5959", // Red
  "#f4ff59", // Yellow
  "#d059ff", // Purple
  "#59ff64", // Green
  "#ffaf59", // Orange
];

// gets a hex code from any string or int (Add more options as and when needed)
export function getColorFromValue(value) {
  if (Number.isInteger(value)) return getColorFromInt(value);
  else return getColorFromString(value);
}

// gets a hex color from a string
export function getColorFromString(value) {
  // Convert string to int
  let intValue = 0;

  for (var i = 0; i < value.length; i++) {
    intValue += value[i].charCodeAt(0); // get char as int
  }

  return getColorFromInt(intValue);
}

// gets a hex color from an int
export function getColorFromInt(value) {
  // get colorIndex based on the amount of colors to chose from
  const colorIndex = value % colors.length;

  return colors[colorIndex];
}
