/* ------------------------------ core imports ------------------------------ */
import { Fragment, useEffect, useState, useRef, useContext } from "react";

/* ---------------------------- internal imports ---------------------------- */

import { DarkMode } from "../../components/App";

/* ---------------------------- external imports ---------------------------- */
import { Popover, Transition } from "@headlessui/react";
import APIClient from "../../services/clients/APIClient";
import toast from "react-hot-toast";
import ButtonGroup from "../../components/buttons/ButtonGroup";
import AuthService from "../../services/AuthService";
import { LuArchiveRestore, LuSave, LuSaveAll, LuTrash2 } from "react-icons/lu";
import DraggableViewColumns from "./DraggableViewColumns";
import HiddenViewColumn from "./HiddenViewColumns";

export default function ViewManagerPopover(props) {
  // destructing props
  const {
    visible, // bool to check if the popover is visible
    columns: initialColumns, // array of columns to be used in the popover
    selectedView, // the currently selected view
    onHide, // A function to run when the modal is hidden
    onChange, // A function used to apply change view changes to the parent
    datatableKey, // The id/key of the datatable
  } = props;

  /* ---------------------------------- refs ---------------------------------- */
  const buttonRef = useRef(null);
  const openRef = useRef(false);

  // - contexts -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */
  // search value that updates when new data is entered into the search bar
  const [searchValue, setSearchValue] = useState("");

  // these states are to store the calculated values, once we've worked out if they're meant to be hidden or visible.
  const [currentColumns, setCurrentColumns] = useState([]);

  // these are the states that will be used to render the checkboxes, based around the search value
  const [filterResult, setFilterResult] = useState([]);

  // state that handles UI elements
  const [promptDelete, setPromptDelete] = useState(false);

  // default data for general tabs
  const [visibleDefault, setVisibleDefault] = useState([]);

  // state to hold the name of the view
  const [viewName, setViewName] = useState("");

  // state to hold the status of the save as component
  const [saveAs, setSaveAs] = useState(false);

  const [hasBeenOpened, setHasBeenOpened] = useState(false);

  /* --------------------------------- effects -------------------------------- */
  // this effect will run when the search value is changed
  useEffect(() => {
    // if there is no value in the search bar, set the visible and hidden results to the visible and hidden columns
    if (searchValue.trim() === "") {
      setFilterResult(currentColumns);
    } else {
      // otherwise, filter the filter the columns based on the filter string
      setFilterResult(
        currentColumns.filter((item) => {
          return item.header.toLowerCase().includes(searchValue.toLowerCase());
        }),
      );
    }
  }, [searchValue, currentColumns, currentColumns]);

  // this effect will run when the selected view is changed
  useEffect(() => {
    // if selectedView is set, then we should do a request and get the data from the api
    if (selectedView) {
      getCurrentColumns();
    } else if (visibleDefault && initialColumns) {
      // otherwise, we should set the visible columns to the default columns
      setDefaultView();
    }
  }, [selectedView, visibleDefault, initialColumns]);

  // this effect will loop through the colums and populate the default data array
  useEffect(() => {
    if (initialColumns) {
      let defaultCols = [];

      initialColumns.forEach((col) => {
        if (col.default === true) {
          defaultCols.push(col.accessorKey);
        }
      });

      setVisibleDefault(defaultCols);
    }
  }, [initialColumns]);

  // this effect will trigger the button to be clicked when the popover is opened
  // this is literally the only way to get the popover to open on click, without breaking the overlay of the page
  useEffect(() => {
    const currentlyOpen =
      buttonRef.current?.dataset["headlessuiState"] == "open";

    // if visible is not the same as currently open
    if (visible != currentlyOpen) buttonRef.current.click();

    // if the popover is not visible, then we want to set the overflow of the main content to auto, otherwise we want to set it to hidden
    if (!visible) {
      document.getElementById("main-content").style.overflow = "auto";
    } else {
      document.getElementById("main-content").style.overflow = "hidden";
    }
  }, [visible]);

  // a use effect to set the name value to a new or existing view
  useEffect(() => {
    if (selectedView) {
      setViewName(selectedView.label);
    } else {
      setViewName("");
    }
  }, [selectedView]);

  /* -------------------------------- functions ------------------------------- */
  // this function hides the popover using the button that controls the popover
  function hidePopover() {
    // if the popover is currently open close the popover using the popover button
    if (openRef.current) buttonRef.current.click();
  }

  // uses the selected view to calculate the display cols
  function getCurrentColumns() {
    // return if there is no selected view
    if (!selectedView?.data?.columns) return;

    // update the current columns by maping through the initial columns and setting the show and order properties
    // then sort by the order property
    setCurrentColumns(
      initialColumns
        .map((col) => {
          // get the columns matching view column if one exists
          const matchingViewColumns = selectedView?.data.columns.find(
            (visibleCol) => visibleCol.accessor === col.accessorKey,
          );

          // return col data with show and order properties
          return {
            ...col,
            show: matchingViewColumns ? true : false,
            order: matchingViewColumns ? matchingViewColumns.order : null,
          };
        })
        .sort((a, b) => a.order - b.order),
    );
  }

  // resets some of the form states to their default values
  function resetForm() {
    setViewName("");
    setSaveAs(false);
    setPromptDelete(false);
    setSearchValue("");
  }

  // Save updates to the current view to the backend
  function eView() {
    // get on the columns that have 'show' set to true.
    const accessors = currentColumns
      .filter((col) => col.show)
      .map((col) => col.accessorKey);

    if (viewName.trim() === "")
      return toast.error("Please enter a name for the view");

    //  update the view via the API
    APIClient.patch(`view/${selectedView.value}`, {
      columns: accessors,
      name: viewName,
      include: ["columns"],
    })
      .then(
        (res) => {
          // if the view was updated successfully, then we want to get the visible columns again, and close the popover
          toast.success("View updated successfully");

          // Apply changes to parent component
          onChange(res);

          // hide the popover after making changes
          hidePopover();
        },
        // if there was an error, then we want to display a toast message, and log the error to the console
      )
      .catch((err) => {
        toast.error("There was an error updating the view");
        console.error(err);
      });
  }

  // creates a new view using the values stored within this component
  function nView() {
    // get on the columns that have 'show' set to true.
    const accessors = currentColumns
      .filter((col) => col.show)
      .map((col) => col.accessorKey);

    if (viewName.trim() === "")
      return toast.error("Please enter a name for the view");

    // post the view via the API
    APIClient.post(
      `profile/${AuthService.currentUser.active_profile.id}/view`,
      {
        name: viewName,
        columns: accessors,
        users: [{ id: AuthService.currentUser.id, role_id: 2 }],
        key: datatableKey,
        include: ["columns", "users"],
      },
    )
      .then((res) => {
        toast.success("View created successfully");

        // Apply changes to parent component
        onChange(res);

        getCurrentColumns();

        // hide the popover after making changes
        hidePopover();
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.error(err);
      });
  }

  // resets the popover to show the default columns
  function setDefaultView() {
    // update the current columns to the initial columns
    setCurrentColumns(
      initialColumns.map((col) => {
        return {
          ...col,
          // if the column is default then show is true, otherwise show is false
          show: col.default ? true : false,
        };
      }),
    );
  }

  // delete the currently selected view from the backend
  function dView() {
    toast.promise(APIClient.delete(`view/${selectedView.value}`), {
      loading: "Deleting View",
      success: (response) => {
        // Apply changes to parent component
        onChange(null);

        // hide popover after deleting the view
        hidePopover();

        return "View deleted successfully";
      },
      error: (error) => {
        console.error(error);
        return `Failed to delete the view - ${error.data.message}`;
      },
    });
  }

  return (
    <Popover className="tw-relative">
      {({ open }) => {
        // store open value in ref
        openRef.current = open;

        // when the open value changes
        useEffect(() => {
          // if closing
          if (!open) {
            // if the popover has been opened then we are hiding the popover
            if (hasBeenOpened) {
              // run on hide function
              onHide();

              // reset the form
              resetForm();
            } else {
              // The popover just loaded and is hidden
            }
          } else {
            // set that the popover has been opened
            setHasBeenOpened(true);
          }
        }, [open]);

        return (
          <>
            <Popover.Button
              ref={buttonRef}
              className="tw-invisible tw-absolute tw-inline-flex tw-items-center tw-gap-x-1 tw-text-sm tw-font-semibold tw-leading-6 tw-text-hue-900"
              style={{ marginRight: "-50px" }}
            >
              <span>Open popover</span>
            </Popover.Button>
            <Popover.Overlay className="tw-fixed tw-inset-0 tw-z-50 tw-bg-black tw-opacity-30 tw-transition-opacity" />

            <Transition
              show={open}
              as={Fragment}
              enter="tw-transition tw-ease-out tw-duration-200"
              enterFrom="tw-opacity-0 tw-Translate-y-1"
              enterTo="tw-opacity-100 tw-Translate-y-0"
              leave="tw-transition tw-ease-in tw-duration-150"
              leaveFrom="tw-opacity-100 tw-Translate-y-0"
              leaveTo="tw-opacity-0 tw-Translate-y-1"
            >
              <Popover.Panel
                static
                className="tw-absolute tw-z-50"
                style={{ left: "-320px", top: 50 }}
              >
                <div
                  style={{
                    width: 0,
                    height: 0,
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                    borderBottom: `${darkMode ? "20px solid #475569" : "20px solid #e0e0e0"}`,
                    margin: "auto",
                  }}
                />
                <div
                  className={`${darkMode ? "tw-border-2 tw-border-hue-500 tw-bg-hue-700" : "tw-bg-white"} tw-w-96 tw-rounded-lg tw-shadow-md `}
                >
                  {/* header area */}
                  <div
                    className={`${darkMode ? "tw-bg-hue-800" : "tw-bg-hue-100"} tw-flex tw-flex-col tw-rounded-t-lg tw-px-5 tw-py-5`}
                  >
                    <span className="tw-font-sans tw-text-lg tw-font-semibold ">
                      View Manager
                    </span>
                  </div>

                  {/* search bar */}
                  <input
                    type="text"
                    className={`${darkMode ? "tw-bg-hue-600" : "tw-bg-hue-50"} tw-input tw-w-full tw-px-5 tw-py-3 tw-font-sans tw-text-sm tw-font-light`}
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />

                  {/* body area */}
                  <div
                    className={`tw-border ${darkMode ? "tw-border-hue-800 tw-bg-hue-700" : "tw-border-hue-200"} tw-flex tw-h-80 tw-flex-col tw-overflow-auto tw-p-5`}
                  >
                    <span className="tw-font-sans tw-text-xs tw-font-bold">
                      Visible
                    </span>
                    {filterResult === undefined || filterResult.length === 0 ? (
                      <span
                        className={`${darkMode ? "tw-bg-hue-900" : "tw-bg-hue-50"} tw-mt-2 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-p-10 tw-font-sans tw-shadow-sm`}
                      >
                        No results found
                      </span>
                    ) : (
                      <DraggableViewColumns
                        columnData={currentColumns}
                        columnsToShow={filterResult.filter(
                          (column) => column.show,
                        )}
                        setColumnData={setCurrentColumns}
                      />
                    )}

                    <hr className="tw-mt-7" />

                    <span className="tw-mt-5 tw-font-sans tw-text-xs tw-font-bold">
                      Hidden
                    </span>
                    {filterResult === undefined || filterResult.length === 0 ? (
                      <span
                        className={`${darkMode ? "tw-bg-hue-900" : "tw-bg-hue-50"} tw-mt-2 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-p-10 tw-font-sans tw-shadow-sm`}
                      >
                        No results found
                      </span>
                    ) : (
                      <HiddenViewColumn
                        columnData={currentColumns}
                        columnsToShow={filterResult.filter(
                          (column) => !column.show,
                        )}
                        setColumnData={setCurrentColumns}
                      />
                    )}
                  </div>

                  {/* footer area */}
                  {/* name input */}
                  {!saveAs && (
                    <div className="tw-flex tw-flex-col tw-px-5 tw-pt-3">
                      <label className="tw-mr-2 tw-pb-2 tw-font-sans tw-text-xs tw-font-bold">
                        Name
                      </label>
                      <input
                        type="text"
                        className={`${darkMode ? "tw-border-hue-700 tw-bg-hue-500" : "tw-border-hue-300 tw-bg-hue-50"} tw-input tw-w-full tw-rounded-md tw-border tw-bg-hue-50 tw-p-2 tw-font-sans tw-text-sm`}
                        placeholder="Enter a name for the view"
                        value={viewName}
                        onChange={(e) => setViewName(e.target.value)}
                      />
                    </div>
                  )}

                  {!promptDelete && !saveAs && (
                    <div
                      className={`${darkMode ? "tw-bg-hue-800" : "tw-bg-hue-100"} tw-mt-5 tw-rounded-b-lg`}
                    >
                      <div className="tw-flex tw-flex-row tw-justify-center tw-px-5 tw-pb-3 tw-pt-5">
                        <ButtonGroup
                          isVertical={true}
                          containerStyle={{ width: "100%" }}
                          actions={[
                            {
                              label: "Restore Default",
                              type: "button",
                              className: "tw-text-sm",
                              onClick: () => setDefaultView(),
                              icon: <LuArchiveRestore className="tw-mr-1" />,
                            },
                            {
                              label: "Save View",
                              type: "button",
                              className: "tw-text-sm",
                              onClick: () => {
                                if (selectedView) eView();
                                else nView();
                              },
                              icon: <LuSave className="tw-mr-1" />,
                            },
                          ]}
                        />

                        {selectedView && (
                          <ButtonGroup
                            isVertical={true}
                            containerStyle={{
                              width: "100%",
                              marginLeft: "15px",
                            }}
                            actions={[
                              {
                                label: "Save As New",
                                type: "button",
                                className: "tw-text-sm",
                                onClick: () => setSaveAs(true),
                                icon: <LuSaveAll className="tw-mr-1" />,
                              },
                              {
                                label: "Delete View",
                                type: "button",
                                className: "tw-text-sm",
                                onClick: () => setPromptDelete(true),
                                icon: <LuTrash2 className="tw-mr-1" />,
                                variant: "danger",
                              },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {/* save as component */}
                  {saveAs && (
                    <div
                      className={`${darkMode ? "tw-bg-hue-800" : "tw-bg-hue-100"} tw-flex  tw-flex-col tw-items-center tw-px-16 tw-py-7 tw-text-center`}
                    >
                      <span className="tw-pb-3 tw-text-sm">
                        What do you want to call this new view?
                      </span>

                      <input
                        type="text"
                        className={`${darkMode ? "tw-border-hue-700 tw-bg-hue-500" : "tw-border-hue-300 tw-bg-hue-50"} tw-input tw-mb-3 tw-w-full tw-rounded-md tw-border tw-p-2 tw-text-sm`}
                        placeholder="Name"
                        value={viewName}
                        onChange={(e) => setViewName(e.target.value)}
                      />
                      <ButtonGroup
                        actions={[
                          {
                            label: "Cancel",
                            type: "button",
                            onClick: () => {
                              setSaveAs(false);
                            },
                          },
                          {
                            label: "Save",
                            type: "button",
                            onClick: nView,
                          },
                        ]}
                      />
                    </div>
                  )}

                  {promptDelete && (
                    <div
                      className={`${darkMode ? "tw-bg-hue-800" : "tw-bg-hue-100"} tw-mt-4 tw-flex tw-flex-col tw-items-center tw-px-16 tw-py-7 tw-text-center`}
                    >
                      <span className="tw-pb-3 tw-text-sm">
                        Are you sure you want to delete this view?
                      </span>
                      <ButtonGroup
                        actions={[
                          {
                            label: "Cancel",
                            type: "button",
                            onClick: () => {
                              setPromptDelete(false);
                            },
                          },
                          {
                            label: "Delete",
                            type: "button",
                            onClick: dView,
                            variant: "danger",
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
}
