import { Spinner } from "react-bootstrap";
import LoadingAnimation from "../../img/loading.png";
import { Image } from "react-bootstrap";

export default function LoadingSpinner({ isActive, type, style }) {
  if (typeof isActive == "undefined" || isActive) {
    const BaseSpinner = (
      <Spinner className="themeTextColour" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );

    switch (type) {
      case "table":
        return (
          <tr>
            <td colSpan="10000" className="tw-h-20">
              <div
                className="tw-flex tw-flex-col tw-content-center tw-items-center tw-justify-center tw-py-20"
                style={{ ...style }}
              >
                <Spinner animation="border" role="status" />
              </div>
            </td>
          </tr>
        );
      case "widget":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...style,
            }}
          >
            {BaseSpinner}
          </div>
        );
      default:
        return (
          <div className="spinnerBackground">
            <Image
              src={LoadingAnimation}
              className="tw-h-12 tw-w-12 tw-animate-spin"
            />
          </div>
        );
    }
  } else {
    return <></>;
  }
}
