/* ------------------------------ core imports ------------------------------ */
import { forwardRef, useRef, useImperativeHandle } from "react";

/* ---------------------------- internal imports ---------------------------- */

import Form from "../../../components/forms/Form";
import FilterRules from "../rules/FilterRules";
import FilterAvailability from "../availability/FilterAvailability";
import GeneralTabs from "../../../components/navigation/GeneralTabs";
import AuthService from "../../../services/AuthService";

/* ---------------------------- external imports ---------------------------- */
import { LuArrowLeft } from "react-icons/lu";
import * as Yup from "yup"; // Used to define the validation for our inputs

// default export
export default forwardRef((props, ref) => {
  // destruct props passed from parent
  const {
    setShowNewFilterUI,
    fieldOptions, // The fieldOptions that can be used for filtering with the datatable
    datatableKey, // The key for the datatable used to identify it
  } = props;

  /* ---------------------------------- refs ---------------------------------- */
  // Ref for filter rules
  const rulesRef = useRef();
  // Ref for user rules
  const usersRef = useRef();
  // Ref for name form
  const nameFormRef = useRef();

  // use imperative handle allows the parent to access functions from this component
  useImperativeHandle(ref, () => ({
    gFilterData() {
      const formValues = nameFormRef.current.form().values;

      // Build and return filter data object
      return {
        rules: rulesRef.current.gRules(), // get filter rules
        users: usersRef.current.gAvailability(), // get user availability rules
        name: formValues.name, // get name from form
        view_id: formValues.view?.value, // get the id of the selected view from the form
      };
    },
  }));

  /* --------------------------------- markup --------------------------------- */
  return (
    <>
      <div>
        <div style={{ marginBottom: -22, display: "flex" }}></div>
        <div className="tw-flex tw-justify-between">
          <button
            className="button-regular tw-flex tw-self-center"
            onClick={() => {
              setShowNewFilterUI(false);
            }}
          >
            <LuArrowLeft className="tw-mr-2 tw-mt-1" />
            Cancel
          </button>
          <div className="tw-w-[40rem]">
            <Form
              ref={nameFormRef}
              id="filter-name"
              inputData={[
                [
                  {
                    label: "Default View",
                    type: "select",
                    name: "view",
                    asyncRoute: `profile/${AuthService.currentUser.active_profile.id}/view`,
                    asyncData: { datatableKey },
                    asyncMap: ({ id, name }) => ({
                      label: `${name}`,
                      value: id,
                    }),
                    isClearable: true,
                  },
                  {
                    name: "name",
                    label: "New Filter Name",
                    type: "text",
                    validation: Yup.string()
                      .required("Name is required")
                      .max(255),
                  },
                ],
              ]}
            />
          </div>
        </div>
      </div>

      <GeneralTabs
        backgroundClasses={{ dark: "tw-bg-hue-800", light: "tw-bg-hue-100" }}
        categories={{
          Rules: <FilterRules ref={rulesRef} fieldOptions={fieldOptions} />,
          Availability: <FilterAvailability ref={usersRef} />,
        }}
      />
    </>
  );
});
