/* ------------------------------ core imports ------------------------------ */
import { useState } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs
import toast from "react-hot-toast";

// Internal Imports
import Modal from "../../../components/modals/Modal";
import Form from "../../../components/forms/Form";

// Launches a modal that can be used to change the current users password
export default function UpdateAccountPasswordModal(props) {
  const [isSubmitting, setIsSubmitting] = useState(false); // stores errors for the backend

  // Runs when the form submit is successful
  const onSuccess = () => {
    // launch success toast
    toast.success("Password updated successfully");

    // hide modal
    props.onHide();
  };

  return (
    <Modal
      visible={props.visible}
      onHide={props.onHide}
      title={"Change Password"}
      actions={[
        {
          label: "Update Password",
          variant: "primary",
          key: "save",
          type: "submit",
          form: "update-password",
          disabled: isSubmitting,
        },
      ]}
    >
      <Form
        submitRoute="authed-user/password"
        submitMethod="patch"
        onSuccess={onSuccess}
        setIsSubmitting={setIsSubmitting}
        id="update-password"
        inputData={[
          {
            name: "currentPassword",
            label: "Current password",
            placeholder: "Enter your current password",
            type: "password",
            validation: Yup.string().required("Current password is required"),
          },
          {
            name: "newPassword",
            label: "New password",
            placeholder: "Enter your new password",
            type: "password",
            validation: Yup.string()
              .min(10, "New password must be at least 10 characters")
              .required("New password is required")
              .matches(
                new RegExp(process.env.MIX_PASSWORD_REGEX),
                "The new password must contain one uppercase and lowercase character as well as one number and special character",
              ),
          },
          {
            name: "confirmPassword",
            label: "Confirm password",
            placeholder: "Confirm your new password",
            type: "password",
            validation: Yup.string()
              .required("Confirm password is required")
              .oneOf(
                [Yup.ref("newPassword"), null],
                "New password and confirm password must match",
              ),
          },
        ]}
      />
    </Modal>
  );
}
