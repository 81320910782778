/* ------------------------------ core imports ------------------------------ */
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";

/* ---------------------------- external imports ---------------------------- */
import { evaluate, isNumber } from "mathjs";
import { Form } from "react-bootstrap";

/* ---------------------------- internal imports ---------------------------- */

import { DarkMode } from "../../App";

// Basic Form Template including validation
export default forwardRef((props, ref) => {
  // Destruct props object into constants
  const {
    name,
    label,
    type,
    placeholder,
    onChange,
    onBlur,
    isValid,
    isInvalid,
    feedback,
    formula,
    options,
  } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */
  const [formulaFeedbackMessage, setFormulaFeedbackMessage] = useState(null);
  const [formulaResult, setFormulaResult] = useState("");
  const [isEvaluating, setIsEvaluating] = useState(false); // are we currently evaluation a formula
  const [isEvaluationQueued, setIsEvaluationQueued] = useState(false); // once the evaluation is finished should we evaluate again

  /* --------------------------------- effects -------------------------------- */
  // If the formula or formula variables are updated evaluate formula
  useEffect(() => {
    evaluateFormula();
  }, [formula, options]);

  // use imperative handle allows the parent to access functions from this component
  useImperativeHandle(ref, () => ({
    evaluateFormula,
  }));

  /* -------------------------------- functions ------------------------------- */
  function evaluateFormula() {
    // if the formula is currently evaluating
    if (isEvaluating) {
      // queue the evaluation to run again once finished
      setIsEvaluationQueued(true);
    } else {
      // set that we are evaluating
      setIsEvaluating(true);

      // If options is a function run function which should return array otherwise assume options is an array
      const variables = options instanceof Function ? options() : options;

      // initialize formula variables
      let formulaVariables = {};

      // if variables is not null
      if (variables) {
        // loop through each value and add to variables object
        for (let key in variables) {
          if (typeof variables[key] != "Object") {
            // append _ to the start and end of key to create a formula variable key
            formulaVariables[`_${key.replace(/ /g, "")}_`] = variables[key];
          }
        }
      }

      try {
        const result = evaluate(formula, formulaVariables);
        setFormulaResult(result);
        onChange(result); // pass new result to parent on change
        setFormulaFeedbackMessage(null);
      } catch (error) {
        setFormulaFeedbackMessage(
          `Invalid Formula: ${parseFormulaFeedbackMessage(error.message)}.`,
        );
        setFormulaResult("");
        onChange(null); // pass null as result to parent
      }

      // we are no longer evaluating
      setIsEvaluating(false);

      if (isEvaluationQueued) {
        // set evaluation is queued to false
        setIsEvaluationQueued(false);

        // run evaluation again
        evaluateFormula();
      }
    }
  }

  // uses a evaluate error message to create a more user readable message
  function parseFormulaFeedbackMessage(rawMessage) {
    return rawMessage
      .replace(/^Undefined symbol /g, " Formula Requires a ")
      .replace(/_/g, "'");
  }

  // - jsx -
  return (
    <Form.Group controlId={name} key={name}>
      {label && (
        <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
          {label}
        </Form.Label>
      )}
      <Form.Control
        disabled={true}
        type={type}
        placeholder={placeholder}
        name={name}
        value={formulaResult}
        onChange={onChange}
        onBlur={onBlur}
        isValid={isValid}
        isInvalid={formulaFeedbackMessage || isInvalid ? true : false}
      />
      <Form.Control.Feedback type="invalid">
        {formulaFeedbackMessage || feedback}
      </Form.Control.Feedback>
    </Form.Group>
  );
});
