/* ------------------------------ core imports ------------------------------ */
import { useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";

// Internal Imports
import DisplayCard from "./DisplayCard";

export default function CardGroup(props) {
  const {
    data,
    cols,
    search,
    shrinkAt = "md", // The breakpoint at which the button will shrink (2xl, xl, lg, md, sm or xs)
  } = props;

  // to hold the search query when it's being typed
  const [query, setQuery] = useState("");
  // to hold the data that will be displayed
  const [cardData, setCardData] = useState(data);

  // when the component mounts, set the columns

  // when the component mounts, set the cardData
  useEffect(() => {
    setCardData(data);
  }, [data]);

  // when the query changes, filter the data
  useEffect(() => {
    if (query) {
      const lowerCaseQuery = query.toLowerCase();
      const filteredData = data.filter((card) =>
        card?.searchableTerms.some((term) =>
          term?.toString().toLowerCase().includes(lowerCaseQuery),
        ),
      );
      setCardData(filteredData);
    } else {
      setCardData(data);
    }
  }, [query]);

  if (cardData) {
    return (
      <>
        {/* search bar for searching cards, aligned right */}
        {/* if search is called */}
        {search && (
          <Row className="mb-4">
            <div className="col-12">
              <input
                type="text"
                className="form-control form_control_light"
                placeholder="Search"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </Row>
        )}
        <Row>
          {cardData.map((card, index) => {
            return (
              <div className={`col-12 col-${shrinkAt}-${cols}`} key={index}>
                <DisplayCard cardData={card} />
              </div>
            );
          })}
        </Row>
      </>
    );
  } else {
    return (
      <>
        <h1>Loading...</h1>
      </>
    );
  }
}
