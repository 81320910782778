/* ------------------------------ core imports ------------------------------ */
import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";

/* ---------------------------- internal imports ---------------------------- */

import Form from "../../../components/forms/Form";
import AuthService from "../../../services/AuthService";
import FilterAvailability from "../availability/FilterAvailability";
import FilterRules from "../rules/FilterRules";
import GeneralTabs from "../../../components/navigation/GeneralTabs";
import FormSelect from "../../../components/forms/inputs/FormSelect";
import { DarkMode } from "../../../components/App";

/* ---------------------------- external imports ---------------------------- */
import { LuPlus, LuFilter, LuPencil, LuSave } from "react-icons/lu";
import * as Yup from "yup"; // Used to define the validation for our inputs
import { Col, Row } from "react-bootstrap";

// default export
export default forwardRef((props, ref) => {
  // destruct props passed from parent
  const {
    filter,
    selectedFilterOption, // the selected filter option
    setSelectedFilterOption, // callback used to update the selected filter option
    setShowNewFilterUI,
    isEditor,
    datatableKey, // The key for the datatable used to identify it
    fieldOptions, // The fieldOptions that can be used for filtering with the datatable
  } = props;

  /* --------------------------------- state --------------------------------- */
  // state for is rename mode is active / visible
  const [isRenaming, setIsRenaming] = useState(false);
  const [selectedViewOption, setSelectedViewOption] = useState(
    filter?.view ? { label: filter.view?.name, value: filter.view?.id } : null,
  );

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* ---------------------------------- refs ---------------------------------- */
  // Ref for filter rules
  const rulesRef = useRef();
  // Ref for user rules
  const usersRef = useRef();
  // Ref for rename form
  const renameFormRef = useRef();
  // Ref for filter select
  const filterSelectRef = useRef();

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    if (!isRenaming) {
      //refresh filter options
      filterSelectRef.current.loadOptions();
    }
  }, [isRenaming]);

  useEffect(() => {
    if (filter?.view) {
      setSelectedViewOption({ label: filter?.view?.name, value: filter?.view?.id });
    } else {
      setSelectedViewOption(null);
    }
  }, [filter]);

  // use imperative handle allows the parent to access functions from this component
  useImperativeHandle(ref, () => ({
    gFilterData() {
      // Build and return filter data object
      return {
        view_id: selectedViewOption?.value, // The ID of the selected view
        rules: rulesRef.current.gRules(), // get filter rules
        users: usersRef.current.gAvailability(), // get user availability rules
      };
    },
  }));

  /* -------------------------------- functions ------------------------------- */
  // handles when the user successfully changes the name of the selected filter
  function onNameChange(newFilter) {
    // Update filter name
    filter.name = newFilter.name;

    // update the selected filter option
    setSelectedFilterOption({ ...selectedFilterOption, label: newFilter.name });

    // change status of is renaming to false
    setIsRenaming(false);
  }

  // when the default view select updates we use this function to set the new default view ID
  function handleChangeDefaultView(data) {
    setSelectedViewOption(data);
  }

  // - styles -
  const styles = {
    warningIcon: {
      fontSize: 50,
      marginBottom: 10,
    },
  };

  /* --------------------------------- markup --------------------------------- */
  return (
    <div>
      {isRenaming ? (
        <>
          <Form
            ref={renameFormRef}
            id="rename-filter"
            disabled={!isEditor}
            submitRoute={`filter/${filter.id}`}
            submitMethod="patch"
            onSuccess={onNameChange}
            resetOnSubmit={false}
            inputData={[
              {
                name: "name",
                label: "Edit filter name",
                type: "text",
                initialValue: filter.name,
                validation: Yup.string().required("Name is required").max(255),
                elementStyle: { marginBottom: 10 },
              },
            ]}
          />
          <Row>
            <Col xs={12} md={6}>
              <button
                className="button-regular tw-mb-6 tw-flex tw-w-full tw-items-center tw-justify-center tw-text-sm"
                onClick={() => renameFormRef.current.form().handleSubmit()}
              >
                Done
              </button>
            </Col>
            <Col xs={12} md={6}>
              <button
                className="button-regular tw-mb-6 tw-flex tw-w-full tw-items-center tw-justify-center tw-text-sm"
                onClick={() => setIsRenaming(false)}
              >
                Cancel
              </button>
            </Col>
          </Row>
          <hr />
        </>
      ) : (
        <>
          <div className="tw-flex tw-w-full tw-gap-5">
            <FormSelect
              ref={filterSelectRef}
              label="Selected Filter"
              className="tw-w-full"
              action={{
                icon: <LuPencil />,
                function: () => {
                  if (isRenaming) {
                    // submit form
                    renameFormRef.current.form().handleSubmit();
                  } else {
                    setIsRenaming(true);
                  }
                },
                tooltip: "Rename Filter",
                disabled: !filter || !isEditor,
              }}
              id="filter-select"
              value={selectedFilterOption}
              isClearable={true}
              onChange={setSelectedFilterOption}
              asyncRoute={`profile/${AuthService.currentUser.active_profile.id}/filter`}
              asyncData={{
                datatableKey,
                include: ["isDefault", "view.columns", "values"],
              }}
              asyncMap={({ id, name, is_default, view, values }) => ({
                label: `${name}`,
                value: id,
                isDefault: is_default,
                view,
                values,
              })}
              placeholder="Select Filter"
            />

            <FormSelect
              label="Default View"
              id="default-view"
              disabled={!selectedFilterOption}
              className="tw-w-full"
              value={selectedViewOption}
              isClearable={true}
              onChange={handleChangeDefaultView}
              asyncRoute={`profile/${AuthService.currentUser.active_profile.id}/view`}
              asyncData={{ datatableKey }}
              asyncMap={({ id, name }) => ({ label: `${name}`, value: id })}
              placeholder="Select View"
            />
            <span
              className="tw-items-centre tw-flex tw-h-full tw-w-72 tw-flex-col tw-justify-end"
              style={{ height: 75 }}
            >
              <button
                style={{ height: 45 }}
                onClick={() => {
                  setShowNewFilterUI(true);
                }}
                className="button-regular tw-flex tw-h-full tw-items-center tw-justify-center tw-text-sm"
              >
                <LuPlus className="tw-mr-1" />
                New Filter
              </button>
            </span>
          </div>
        </>
      )}

      {/* if profile is set */}
      {filter && (
        <div className="tw-pt-6">
          <GeneralTabs
            backgroundClasses={{
              dark: "tw-bg-hue-800",
              light: "tw-bg-hue-100",
            }}
            categories={{
              Rules: (
                <FilterRules
                  fieldOptions={fieldOptions}
                  isEditor={isEditor}
                  ref={rulesRef}
                  initialRules={filter.rules}
                />
              ),
              Availability: (
                <div>
                  <FilterAvailability
                    isEditor={isEditor}
                    ref={usersRef}
                    filter={filter}
                  />
                </div>
              ),
            }}
          />
        </div>
      )}
      {/* if profile is not set */}
      {!filter && (
        <div
          className={`${darkMode ? "tw-bg-hue-800" : "tw-bg-hue-100"} tw-mt-5 tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-bg-hue-100 tw-p-24 tw-shadow-sm`}
        >
          <div className="tw-flex tw-max-w-sm tw-flex-col tw-items-center tw-justify-center tw-text-center">
            <LuFilter
              className={` ${darkMode ? "tw-text-white" : "tw-text-hue-700"} tw-mb-5`}
              style={styles.warningIcon}
            />
            <p className="tw-mb-1 tw-font-sans tw-text-lg tw-font-semibold">
              No Filter Selected
            </p>
            <p
              className={` ${darkMode ? "tw-text-hue-300" : "tw-text-hue-500"} tw-font-sans  tw-text-lg`}
            >
              Select an existing filter or creating a new filter
            </p>
          </div>
        </div>
      )}
    </div>
  );
});
