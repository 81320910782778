import { useContext } from "react";
import { DarkMode } from "../../components/App";
import { DndContext } from "@dnd-kit/core"; // used to make sortable selects have draggable options
import { SortableContext } from "@dnd-kit/sortable"; // used to make sortable selects have draggable options
import DraggableViewColumn from "./draggableViewColumn";

// Component that renders a group of draggable view columns
export default function DraggableViewColumns(props) {
  // destructuring data
  const {
    columnsToShow, // the data that is currently being displayed, is a result of the search
    columnData, // the data that is being used to render the checkboxes, is the full list of columns, before the search
    setColumnData, // the function that will be used to update the column data
  } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* -------------------------------- functions ------------------------------- */
  // function that is called when the user stops dragging an item
  function handleDragEnd({ active, over }) {
    // get the index of the active and over items
    const activeIndex = columnData.findIndex(
      (column) => active.id === column.accessorKey,
    );
    const overIndex = columnData.findIndex(
      (column) => over.id === column.accessorKey,
    );

    // reorder the column data moving the active item to the index of the over item
    const newColumnData = [...columnData];

    // remove the active item from the array
    const [removedColumn] = newColumnData.splice(activeIndex, 1);

    // insert the active item at the index of the over item
    newColumnData.splice(overIndex, 0, removedColumn);

    // update the column data
    setColumnData(newColumnData);
  }

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-border-hue-200 tw-py-2">
      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext
          items={columnsToShow.map((column) => column.accessorKey)}
        >
          {columnsToShow.map((column, index) => (
            <DraggableViewColumn
              key={column.accessorKey}
              column={column}
              columnData={columnData}
              setColumnData={setColumnData}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}
