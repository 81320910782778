/* ------------------------------ core imports ------------------------------ */
import { useEffect, useState, useRef } from "react";
/* ---------------------------- internal imports ---------------------------- */
import Modal from "../../../components/modals/Modal";
import APIClient from "../../../services/clients/APIClient";
import NewFilter from "../elements/NewFilter";
import EditFilter from "../elements/EditFilter";
import RemoveFilter from "./RemoveFilter.modal";
import AuthService from "../../../services/AuthService";
/* ---------------------------- external imports ---------------------------- */
import toast from "react-hot-toast";

export default function FilterManager(props) {
  const {
    visible, // is the model currently visible to the user
    onHide, // A function to run when the modal is hidden
    datatableKey, // the key/id to identify the datatable
    columns, // The column data for the datatable
    initialFilterOption, // the initial selected filter option
  } = props;

  /* --------------------------------- state --------------------------------- */
  const [isSubmitting, setIsSubmitting] = useState(false); // state for if the modal is submitting
  const [filterData, setFilterData] = useState(); // state for the filter data - this is where the filter data is stored once the request has been made
  const [selectedFilterOption, setSelectedFilterOption] = useState(null); // state for the active filter id
  const [showNewFilterUI, setShowNewFilterUI] = useState(false); // state for if the new filter UI is visible
  const [showDeleteModal, setShowDeleteModal] = useState(false); // are we currently showing the delete modal
  const [isEditor, setIsEditor] = useState(false); // is the user an editor of the selected filter
  const [fieldOptions, setFieldOptions] = useState([]); // The fieldOptions that can be used within filter rules

  /* ---------------------------------- refs ---------------------------------- */
  // Ref for edit filter view
  const editFilterRef = useRef();

  // Ref for new filter view
  const newFilterRef = useRef();

  
  /* --------------------------------- effects -------------------------------- */
  // when the ID of the active filter changes, fetch the filter data
  useEffect(() => {
    gFilter();
  }, [selectedFilterOption]);

  // When the modal is made visible change the selected filter option to the initial value
  useEffect(() => {
    setSelectedFilterOption(initialFilterOption);
  }, [visible]);

  // When the filter updates we need to update the users editor status
  useEffect(() => {
    if (filterData) {
      // if we find a matching user with the editor role then the current user can edit
      setIsEditor(
        filterData.users.find(
          (user) =>
            user.id == AuthService.currentUser.id && user.pivot.role_id == 2,
        ) != null,
      );
    } else {
      // If there is no filter then there is nothing to edit
      setIsEditor(false);
    }
  }, [filterData]);

  // When the columns are updated, update the field options
  useEffect(() => {
    if (columns) {
      setFieldOptions(
        columns.map((column) => {
          // Create field object to be selected in select box
          return { label: column.header, value: column.accessorKey };
        }),
      );
    }
  }, [columns]);

  /* -------------------------------- functions ------------------------------- */
  // fetches the filter data by making a GET request to the API to get the filter with the id of selectedFilterOption
  function gFilter() {
    if (selectedFilterOption != null) {
      APIClient.get(`filter/${selectedFilterOption.value}`, {
        include: [
          "users",
          "rules.operator",
          "rules.conjunction",
          "rules.values",
          "view",
        ],
      })
        .then((response) => {
          // Set the filter state to the response data
          setFilterData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setFilterData(null);
    }
  }

  // saves the updated filter data to the backend
  async function sFilter(close = false) {
    setIsSubmitting(true);

    // instantiate new selected filter to be populated based on if statements below
    let newSelectedFilter;

    // set submit Data with includes to be used in both creating and updating
    let submitData = {
      include: [
        "users",
        "rules.operator",
        "rules.conjunction",
        "rules.values",
        "isDefault",
        "view.columns",
        "values",
      ],
      ...(showNewFilterUI
        ? newFilterRef.current.gFilterData()
        : editFilterRef.current.gFilterData()), // spread data from new or edit form
    };

    if (showNewFilterUI) {
      // add includes submit data (options to be included in return data)
      submitData.key = datatableKey;

      //make a post request to the API to create a new filter
      await toast.promise(
        APIClient.post(
          `profile/${AuthService.currentUser.active_profile.id}/filter`,
          submitData,
        ),
        {
          loading: "Creating Filter",
          success: (response) => {
            newSelectedFilter = response;
            return "Filter created successfully";
          },
          error: (error) => {
            console.error(error);

            // set is submitting to false
            setIsSubmitting(false);

            return `Failed to create Filter - ${error.data.message}`;
          },
        },
      );
    } else {
      //make a patch request to the API to update the filter
      await toast.promise(
        APIClient.patch(`filter/${selectedFilterOption.value}`, submitData),
        {
          loading: "Updating Filter",
          success: (response) => {
            newSelectedFilter = response;
            return "Filter updated successfully";
          },
          error: (error) => {
            console.error(error);

            // set is submitting to false
            setIsSubmitting(false);

            return `Failed to update Filter - ${error.data.message}`;
          },
        },
      );
    }

    // construct new selected filter option using filter data
    const newFilterOption = {
      label: newSelectedFilter.name,
      value: newSelectedFilter.id,
      isDefault: newSelectedFilter.is_default,
      view: newSelectedFilter.view,
      values: newSelectedFilter.values,
    };

    // set the filter state to the response data
    setFilterData(newSelectedFilter);
    setSelectedFilterOption(newFilterOption);
    setShowNewFilterUI(false);

    // if close, close the modal
    if (close) handleModalClose(newFilterOption);

    // set is submitting to false
    setIsSubmitting(false);
  }

  // handles when the modal is closed
  function handleModalClose() {
    // send filter option to onHide function
    onHide(selectedFilterOption);
  }

  // holds the actions that should be taken after removing the selected filter
  function onRemoveFilter() {
    setSelectedFilterOption(null);
    setFilterData(null);
  }

  /* --------------------------- pre jsx computation -------------------------- */
  // Disable the buttons if based on modal state
  const disableButtons =
    isSubmitting || (!showNewFilterUI && (!selectedFilterOption || !isEditor));

  /* --------------------------------- markup --------------------------------- */
  return (
    <>
      <Modal
        title="Filter Manager"
        visible={visible && !showDeleteModal}
        size="6xl"
        onHide={handleModalClose}
        actions={[
          ...(!showNewFilterUI
            ? [
                {
                  label: "Remove",
                  variant: "danger",
                  key: "remove",
                  type: "button",
                  onClick: () => setShowDeleteModal(true),
                  disabled: disableButtons,
                },
              ]
            : []), // only show remove button if we are not in the new filter ui
          {
            label: "Save",
            variant: "secondary",
            key: "save",
            type: "button",
            onClick: sFilter,
            disabled: disableButtons,
          },
          {
            label: "Save & Close",
            variant: "primary",
            key: "save-and-close",
            type: "button",
            onClick: () => sFilter(true),
            disabled: disableButtons,
          },
        ]}
      >
        {/* if showNewFilterUI is set to true, show newFilter */}
        {showNewFilterUI ? (
          <NewFilter
            ref={newFilterRef}
            datatableKey={datatableKey}
            fieldOptions={fieldOptions}
            setShowNewFilterUI={setShowNewFilterUI}
          />
        ) : (
          <EditFilter
            ref={editFilterRef}
            datatableKey={datatableKey}
            fieldOptions={fieldOptions}
            isEditor={isEditor}
            filter={filterData}
            selectedFilterOption={selectedFilterOption}
            setSelectedFilterOption={setSelectedFilterOption}
            setShowNewFilterUI={setShowNewFilterUI}
          />
        )}
      </Modal>
      <RemoveFilter
        visible={showDeleteModal}
        filter={filterData}
        onHide={() => setShowDeleteModal(false)}
        onSuccess={onRemoveFilter}
      />
    </>
  );
}
