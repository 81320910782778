import { Form, Container, Row, Col } from "react-bootstrap";

// Blocked.js
export default function NoticeScreen({ title, message }) {
  return (
    <div className="unAuthBackground">
      <div className="unAuthUtilityContainer">
        <Container className="p-5">
          <Row>
            <Col>
              <h2 className="pb-3 text-center"> {title} </h2>
              <Form style={{ width: 500, textAlign: "center" }}>
                <Form.Text>{message}</Form.Text>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
