/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// internal imports
import StepBar from "../forms/StepBar";
import { DarkMode } from "../App";

export default function StepBarWidget({
  steps,
  progress,
  backgroundLineColour,
}) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <div
      className={darkMode ? "step-box-container_dark" : "step-box-container"}
    >
      <StepBar progress={progress} steps={steps} />
    </div>
  );
}
