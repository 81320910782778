/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// - internal imports-
import { sanitiseObject } from "../../../services/data-formatting/objects";
import { DarkMode } from "../../App";

/* ---------------------------- external imports ---------------------------- */
import { Form } from "react-bootstrap";

// Basic Form Template including validation
export default function FormInput(props) {
  // destruct props passed from parent
  const {
    name, // The name of the input used for it's control ID and key
    label, // (Optional) The Label of the input
    feedback, // (Optional) The feedback message to be displayed when the input is invalid
    style, // (Optional) The style of the input
    subtype, // (Optional) The subtype of the input such as textarea
    isDisabled = false, // (Optional) Should the input be disabled
    className, // (Optional) The classes to apply to the input
    icon: initialIcon, // (Optional) The icon to be displayed in the input
  } = props;

  // if the icon is a function then use it to construct a react component
  const icon = typeof initialIcon == "function" ? initialIcon() : initialIcon;

  //  - content -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------- pre jsx computation -------------------------- */
  // sanitise props for the form control by removing any excluded keys
  const sanitisedProps = sanitiseObject({ ...props }, [
    "initialValue",
    "colSize",
    "colStyle",
    "isVisible",
    "isDisabled",
    "icon",
    "className",
  ]);

  // - jsx -
  return (
    <Form.Group
      controlId={name}
      key={name}
      className={`${className || ""} form-control-container`}
    >
      {icon && (
        <span className={darkMode ? "form-icon_dark" : "form-icon_light"}>
          {icon}
        </span>
      )}
      {label && (
        <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
          {label}
        </Form.Label>
      )}
      <Form.Control
        onWheel={(e) => e.target.blur()} // prevent scrolling on number inputs
        // add padding to the left if we have an icon to display
        style={{ ...(icon ? { paddingLeft: "2.5rem" } : {}), ...style }}
        as={subtype || "input"}
        disabled={isDisabled}
        className={darkMode ? "form-control_dark" : "form-control_light"}
        {...sanitisedProps}
      />
      <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    </Form.Group>
  );
}
