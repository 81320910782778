// External Imports
import toast from "react-hot-toast";

// Internal Imports
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import APIClient from "../../../services/clients/APIClient";

// Launches a modal that can be used to change the selected users password
export default function RemoveUserModal({
  visible,
  onHide,
  user,
  profile,
  onSuccess,
}) {
  function deleteUserFromProfile(id) {
    APIClient.delete(`profile/${profile.id}/user/${id}`)
      .then((response) => {
        toast.success("User removed successfully");
        onHide();
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        toast.error(`Failed to remove user - ${error}`);
      });
  }

  if (user) {
    return (
      <ConfirmationModal
        title={`Remove ${user.forename}?`}
        message={`Are you sure you want to remove ${user.forename} ${user.surname} from ${profile.name}?`}
        actions={[
          { label: "Cancel", variant: "secondary", onClick: onHide },
          {
            label: "Confirm",
            variant: "danger",
            onClick: () => deleteUserFromProfile(user.id),
          },
        ]}
        visible={visible}
        onHide={onHide}
      />
    );
  } else {
    return null;
  }
}
