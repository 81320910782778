/* ------------------------------ core imports ------------------------------ */
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

/* ---------------------------- external imports ---------------------------- */
import { LuTrash, LuEye, LuUserCog } from "react-icons/lu";

/* ---------------------------- internal imports ---------------------------- */

import Datatable from "../../components/tables/Datatable";
import { formatDateForDatatable } from "../../services/data-formatting/dates";
import AddNewProfileModal from "./elements/AddNewProfileModal";
import ViewExistingProfileModal from "./elements/ViewExistingProfileModal";
import RemoveProfileConfirmation from "./elements/RemoveProfileConfirmation";
import DataTableWidget from "../../components/widgets/DataTableWidget";

export default function ProfileManager() {
  // react hook
  let navigate = useNavigate();

  /* --------------------------------- state --------------------------------- */
  const [nProfileOpen, setNProfileOpen] = useState(false);
  const [eProfileOpen, setEProfileOpen] = useState(false);
  const [confirmRemoval, setConfirmRemoval] = useState(false);

  /* ---------------------------------- refs ---------------------------------- */
  const dataTableRef = useRef();

  /* -------------------------------- functions ------------------------------- */
  // runs when a profile is deleted
  function deleteProfileSuccess() {
    dataTableRef.current.refresh();
  }

  // runs when a new profile is added
  function addProfileSuccess() {
    // refresh datatable to show new value
    dataTableRef.current.refresh();

    // hide profile
    setNProfileOpen(false);
  }

  return (
    <>
      <Datatable
        subTitle="This table shows all the organisation profiles that are currently included in the system."
        ref={dataTableRef}
        hasExport={true}
        columns={[
          { header: "#", accessorKey: "id" },
          { header: "Name", accessorKey: "name" },
          {
            header: "Users",
            accessorKey: "users_count",
          },
          {
            header: "Date Created",
            accessorKey: "created_at",
            cell: ({ getValue }) => formatDateForDatatable(getValue()),
          },
        ]}
        route="profile"
        requestData={{ includeCount: ["users"] }}
        headerActions={[
          {
            label: "Add Profile",
            icon: LuUserCog,
            type: "headerButton",
            key: "add-profile",
            onClick: () => setNProfileOpen(true),
            variant: "outline-secondary",
          },
        ]}
        isDropdown={true}
        rowActions={[
          {
            variant: "secondary",
            label: "View",
            icon: <LuEye />,
            key: "save",
            onClick: (data) => navigate(`/profile/${data.original.id}`),
          },
          {
            variant: "danger",
            label: "Remove",
            icon: <LuTrash />,
            key: "delete",
            onClick: (data) => setConfirmRemoval([true, `${data.original.id}`]),
          },
        ]}
      />
      <AddNewProfileModal
        visible={nProfileOpen}
        onHide={() => setNProfileOpen(false)}
        onSuccess={addProfileSuccess}
      />
      <ViewExistingProfileModal
        visible={eProfileOpen}
        onHide={() => setEProfileOpen(false)}
      />
      <RemoveProfileConfirmation
        visible={confirmRemoval[0]}
        onHide={() => setConfirmRemoval(false)}
        profile={confirmRemoval[1]}
        onSuccess={deleteProfileSuccess}
      />
    </>
  );
}
