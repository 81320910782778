/* ------------------------------ core imports ------------------------------ */
import { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";

// internal imports
import { DarkMode } from "./App.jsx";
import AuthService from "../services/AuthService.jsx";
import ProfilePicture from "./ProfilePicture.jsx";
import { truncate } from "../services/data-formatting/strings.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import WelcomeModal from "./modals/WelcomeModal.jsx";
import updateMessages from "../../../updateMessages.json";

// external imports
import { Popover, Transition } from "@headlessui/react";
import { Dropdown } from "react-bootstrap";
import { LuShuffle, LuFingerprint, LuGlasses, LuRocket } from "react-icons/lu";
import { FaLightbulb, FaRegLightbulb, FaRunning } from "react-icons/fa";

export default function Header() {
  // nav hook
  let navigate = useNavigate();

  // -states -
  const [PMWStatus, setPMWStatus] = useState(false);
  const [accountDropdown, setAccountDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [user, setUser] = useState(AuthService.currentUser);
  const [profilePicker, setProfilePicker] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  // - context
  const { darkMode, changeDarkMode } = useContext(DarkMode);

  /* -------------------------------- functions ------------------------------- */
  function handleResize() {
    setIsMobile(window.innerWidth < 1000);
  }

  // updates the current user state
  function onProfileSelect() {
    setUser(AuthService.currentUser);
  }

  function navigateAndClose({ closeMethod, href }) {
    closeMethod();
    navigate(href);
  }

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // the different options for the navigation
  const solutions = [
    {
      key: "view-active-profile",
      name: "View active profile",
      description: "View your currently active profile",
      icon: LuGlasses,
      onClick: (close) =>
        navigateAndClose({
          href: `/profile/${user.active_profile.id}`,
          closeMethod: close,
        }),
    },
    {
      key: "switch-profiles",
      name: "Switch profiles",
      description: "Change your active profile",
      icon: LuShuffle,
      onClick: () => setProfilePicker(true),
    },
    {
      key: "my-account",
      name: "My account",
      description: "View your account information",
      icon: LuFingerprint,
      onClick: (close) =>
        navigateAndClose({ href: `/account`, closeMethod: close }),
    },
    {
      key: "whats-new",
      name: `What's new`,
      description: "View the latest updates to the platform",
      icon: LuRocket,
      onClick: () => setShowWelcomeModal(true),
    },
  ];

  const callsToAction = [
    {
      name: "Dark mode off",
      onClick: () => changeDarkMode(!darkMode),
      icon: darkMode ? FaLightbulb : FaRegLightbulb,
    },
    {
      name: "Logout",
      onClick: () => AuthService.logout(),
      icon: FaRunning,
    },
  ];

  /* --------------------------- pre jsx computation -------------------------- */
  // removing the manage active profile option if the user does not have the permission
  if (!user.checkPermission("manage active profile")) {
    solutions.splice(0, 1);
  }

  let username;
  if (typeof AuthService.currentUser != "undefined") {
    username = truncate(
      `${AuthService.currentUser.forename} ${AuthService.currentUser.surname}`,
      20,
    );
  } else {
    username = "Unknown";
  }

  if (AuthService.currentUser == null) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <>
        <Popover className="relative">
          {({ close }) => (
            <>
              <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-hue-900 tw-outline-none">
                <Dropdown show={accountDropdown}>
                  <div
                    className={
                      darkMode ? "user-box-wrapper_dark" : "user-box-wrapper"
                    }
                    onClick={() => setPMWStatus(!PMWStatus)}
                  >
                    {!isMobile && (
                      <span
                        className={
                          darkMode ? "user-box-name_dark" : "user-box-name"
                        }
                      >
                        {username}
                      </span>
                    )}
                    <ProfilePicture
                      user={AuthService.currentUser}
                      size="small"
                    />
                  </div>
                </Dropdown>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="tw-transition tw-ease-out tw-duration-200"
                enterFrom="tw-opacity-0 tw-Translate-y-1"
                enterTo="tw-opacity-100 tw-Translate-y-0"
                leave="tw-transition tw-ease-in tw-duration-150"
                leaveFrom="tw-opacity-100 tw-Translate-y-0"
                leaveTo="tw-opacity-0 tw-Translate-y-1"
              >
                <Popover.Panel className="control-center-panel">
                  {({ close }) => (
                    <div
                      className={
                        darkMode
                          ? "control-center-container_dark"
                          : "control-center-container"
                      }
                    >
                      <div className="tw-p-4">
                        {profilePicker ? (
                          <>
                            <ProfileListView
                              setProfilePicker={setProfilePicker}
                              onSelect={onProfileSelect}
                              profiles={user.profiles}
                              goBack={setProfilePicker}
                            />
                          </>
                        ) : (
                          solutions.map((item) => (
                            <span
                              key={item.key}
                              className="tw-cursor-pointer"
                              onClick={() => {
                                item?.onClick(close);
                              }}
                            >
                              <div
                                key={item.name}
                                className={
                                  darkMode
                                    ? "tw-group tw-relative tw-flex tw-gap-x-6 tw-rounded-lg tw-p-4 hover:tw-bg-hue-600"
                                    : "tw-group tw-relative tw-flex tw-gap-x-6 tw-rounded-lg tw-p-4 hover:tw-bg-hue-50"
                                }
                              >
                                <div
                                  className={
                                    darkMode
                                      ? "tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-md tw-bg-hue-600 group-hover:tw-bg-neutral-800"
                                      : "tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-md tw-bg-hue-50 group-hover:tw-bg-hue-50"
                                  }
                                >
                                  <item.icon
                                    className={
                                      darkMode
                                        ? "tw-h-6 tw-w-6 tw-text-hue-200 group-hover:tw-text-indigo-400"
                                        : "tw-h-6 tw-w-6 tw-text-hue-600 group-hover:tw-text-primary_accent"
                                    }
                                    aria-hidden="true"
                                  />
                                </div>
                                <div>
                                  <p
                                    className={
                                      darkMode
                                        ? "control-center-header_dark"
                                        : "control-center-header"
                                    }
                                  >
                                    {item.name}
                                  </p>
                                  <p
                                    className={
                                      darkMode
                                        ? "control-center-description_dark"
                                        : "control-center-description "
                                    }
                                  >
                                    {item.description}
                                  </p>
                                </div>
                              </div>
                            </span>
                          ))
                        )}
                      </div>
                      <div
                        className={
                          darkMode
                            ? "control-center-cta-container_dark"
                            : "control-center-cta-container"
                        }
                      >
                        {callsToAction.map((item) => (
                          <span
                            key={item.name}
                            onClick={item?.onClick}
                            className={
                              darkMode
                                ? "control-panel-cta-text_dark"
                                : "control-panel-cta-text"
                            }
                          >
                            <item.icon
                              className="tw-h-5 tw-w-5 tw-flex-none tw-text-hue-400"
                              aria-hidden="true"
                            />
                            {item.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        <WelcomeModal
          visible={showWelcomeModal}
          onHide={() => setShowWelcomeModal(false)}
          title={`Welcome to Spark ${updateMessages.version}`}
          size="2xl"
          message={updateMessages.message}
          changeLog={updateMessages.changeLog}
          actions={[
            {
              label: "Continue",
              variant: "primary",
              key: "save",
              onClick: () => setShowWelcomeModal(false),
            },
          ]}
        />
      </>
    );
  }
}

export function ProfileListView({ profiles, onSelect, setProfilePicker }) {
  // context
  const { darkMode, changeDarkMode } = useContext(DarkMode);
  const hasProfiles = profiles.length > 0;

  /* -------------------------------- functions ------------------------------- */
  async function changeActiveProfile(id) {
    await AuthService.changeActiveProfile(id);
    onSelect();
  }

  // for each profile in the list, create a list group item
  const listGroupItems = profiles.map((profile, index) => {
    if (hasProfiles)
      return (
        <div onClick={() => changeActiveProfile(profile.id)}>
          <div
            key={profile.name}
            className={
              darkMode
                ? "tw-group tw-relative tw-flex tw-justify-between tw-gap-x-6 tw-rounded-lg tw-border-b tw-border-hue-400/20 tw-p-2 hover:tw-bg-hue-600"
                : "tw-group tw-relative tw-flex tw-justify-between tw-gap-x-6 tw-rounded-lg tw-border-b tw-border-hue-400/20 tw-p-2 hover:tw-bg-hue-50"
            }
          >
            <div>
              {profile.name.length > 20
                ? profile.name.substring(0, 50) + "..."
                : profile.name}
            </div>
            {profile.id == AuthService.currentUser.active_profile.id && (
              <span className="py-1 tw-inline-flex tw-items-center tw-rounded-md tw-bg-green-50 tw-px-2 tw-text-xs tw-font-medium tw-text-green-700 tw-ring-1 tw-ring-inset tw-ring-green-600/20">
                Active
              </span>
            )}
          </div>
        </div>
      );
    else {
      return (
        <div className="tw-p-2">
          <div
            key={profile.name}
            className="tw-group tw-relative tw-flex tw-gap-x-6 tw-rounded-lg tw-p-2 hover:tw-bg-hue-50"
          >
            <div>
              No profiles found
              <span className="tw-absolute tw-inset-0" />
            </div>
          </div>
        </div>
      );
    }
  });

  // - return jsx -
  return (
    <>
      <div className="tw-p-4" variant="flush">
        <button
          className={
            darkMode ? "button-regular_dark tw-mb-3" : "button-regular tw-mb-3"
          }
          onClick={() => setProfilePicker(false)}
        >
          Return
        </button>
        {listGroupItems}
      </div>
    </>
  );
}
