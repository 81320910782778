import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import LoadingSpinner from "./LoadingSpinner";
import NoticeScreen from "../views/NoticeScreen";
import { Link } from "react-router-dom";
import WelcomeModal from "./modals/WelcomeModal";
import updateMessages from "../../../updateMessages.json";
import Moment from "moment";

// Ensures enclosed child components are not shown unless the user is authenticated
export default function RequireAuth({ children, permissions = [] }) {
  const [loading, setLoading] = useState(true);
  const [authedResponse, setAuthedResponse] = useState(null);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  let navigate = useNavigate();

  // Runs when the app first loads
  useEffect(() => {
    if (AuthService.currentUser != null) {
      // if they have been previously authenticated we will give them instant access
      setLoading(false);

      // We still want to check that their authentication is valid
      AuthService.isAuthenticated()
        .then((response) => {
          setAuthedResponse(response);
        })
        .catch((error) => {
          setAuthedResponse(error);
          // if we have a redirect as part of the error we should redirect to that route
          if (error.data != null && error.data.redirect != null) {
            navigate(error.data.redirect, { replace: true });
          }

          setLoading(false);
        });
    } else {
      // else we need to check if they are authenticated using their auth token
      AuthService.isAuthenticated()
        .then((response) => {
          setAuthedResponse(response);
          setLoading(false);
        })
        .catch((error) => {
          setAuthedResponse(error);
          // if we have a redirect as part of the error we should redirect to that route
          if (error.data != null && error.data.redirect != null) {
            navigate(error.data.redirect, { replace: true });
          }

          setLoading(false);
        });
    }
  }, []);

  // Run when value of loading is changed
  useEffect(() => {
    // if loading has stopped and we have a currentUser and we have an lastUpdated date
    if (!loading && AuthService.currentUser && updateMessages.lastUpdated) {
      if (AuthService.currentUser.last_active_at) {
        // If user has a last active at value then we need to use it to figure out if the system has been updated since they last used the system
        // get last system update as moment date
        const lastUpdate = Moment(updateMessages.lastUpdated, "DD/MM/YYYY");

        // get last active at as moment date
        const lastActiveAt = Moment(AuthService.currentUser.last_active_at);

        // if the user was last active before the last update, show the user
        if (lastActiveAt.isSameOrBefore(lastUpdate)) {
          setShowWelcomeModal(true);
        }
      } else {
        // If the user does not have a last active at value then this is their first time using the system
        // Show the welcome modal
        setShowWelcomeModal(true);
      }
    }
  }, [loading]);

  if (loading)
    // display loading spinner
    return <LoadingSpinner />;
  else {
    let hasPermissions = true;
    if (AuthService.currentUser != null) {
      permissions.forEach((permission) => {
        // If the user does not have the permission then change has permission to false
        if (!AuthService.checkPermission(permission)) {
          hasPermissions = false;
        }
      });
    } else {
      // if there is no user assume they don't have permission
      hasPermissions = false;
    }

    // If the user can Access the Page
    if (hasPermissions) {
      return (
        <>
          <WelcomeModal
            visible={showWelcomeModal}
            onHide={() => setShowWelcomeModal(false)}
            title={`Welcome to Spark ${updateMessages.version}`}
            size="lg"
            message={updateMessages.message}
            changeLog={updateMessages.changeLog}
            actions={[
              {
                label: "Continue",
                variant: "primary",
                key: "save",
                onClick: () => setShowWelcomeModal(false),
              },
            ]}
          />
          {children}
        </>
      );
    } else {
      return (
        <NoticeScreen
          title="Forbidden"
          message={
            <>
              You do not have permission to access this page.
              <br /> Return to <Link to="/"> home </Link>
            </>
          }
        />
      );
    }
  }
}
