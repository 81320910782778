import Cookie from "universal-cookie";

const cookie = new Cookie();

class CookieService {
  //Getter for any Cookies stored in the browser using the key
  get(key) {
    return cookie.get(key);
  }

  //Setter for adding Cookies to the browser
  set(key, value, options) {
    cookie.set(key, value, options);
  }

  //Can be used to remove stored in the browser using the key
  remove(key) {
    cookie.remove(key);
  }
}

export default new CookieService();
