export default function profileRoles(role_id) {
  // switch statement to return the role name from the id
  switch (role_id) {
    case 2:
      return "Manager";
    case 3:
      return "Owner";
    default:
      return "Member";
  }
}
