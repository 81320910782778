import { useState, useEffect, useRef } from "react";

/* ---------------------------- internal imports ---------------------------- */
import FormSelect from "../../../components/forms/inputs/FormSelect";
import FilterManager from "../modals/FilterManager.modal";
import AuthService from "../../../services/AuthService";
import APIClient from "../../../services/clients/APIClient";

/* ---------------------------- external imports ---------------------------- */
import toast from "react-hot-toast";
import { LuFilter, LuHeart, LuHeartOff } from "react-icons/lu";

export default function FilterManagerSelect(props) {
  // destruct props passed from parent
  const {
    datatableKey, // the key/id of the datatable
    initialFilterId, // The id of the initially selected filter
    onChange, // Callback function to run when the selected filter is changed
    columns, // The column data for the datatable
    refresh, // Callback function to run when the filter manager is closed
  } = props;

  /* --------------------------------- state --------------------------------- */
  const [showFilterManager, setShowFilterManager] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [defaultFilterId, setDefaultFilterId] = useState(null);

  /* ---------------------------------- refs ---------------------------------- */
  const selectRef = useRef();

  /* --------------------------------- effects -------------------------------- */
  // When the filter manager select first loads we should fetch the default filter for the table if the datatable
  useEffect(() => {
    if (initialFilterId) {
      fetchFilterData(initialFilterId);
    }

    // get the default filter and overwrite current if no filter is selected
    gDefaultFilter(!initialFilterId);
  }, []);

  /* -------------------------------- functions ------------------------------- */
  // fetches the filter data by Making a GET request to the API to get the filter with the id of initialFilterId
  function fetchFilterData(id) {
    APIClient.get(`filter/${id}`, {
      include: ["view.columns", "values"],
    })
      .then(({ data }) => {
        // Set the filter state to the response data
        handleOnChange({
          label: data.name,
          value: data.id,
          isDefault: true,
          view: data.view,
          values: data.values,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handles when the form select value is changed
  function handleOnChange(newValue) {
    console.log("handleOnChange", newValue);

    // set local copy of filter
    setSelectedFilter(newValue);

    // run parent on change callback
    onChange(newValue);
  }

  // handles when the filter manager is closed
  function handleFilterManagerClose(updatedOption) {
    // run parent refresh callback
    refresh();

    // update selected filter option to match the filter managers before it was closed
    handleOnChange(updatedOption);

    // refresh options within the filter select
    selectRef.current.loadOptions();

    setShowFilterManager(false);
  }

  // updates the datatables default filter
  async function gDefaultFilter(overwriteCurrentFilter = false) {
    // if the user has an active profile
    if (AuthService.currentUser.active_profile) {
      const response = await APIClient.get(
        `profile/${AuthService.currentUser.active_profile.id}/filter/default/${datatableKey}`,
        {
          include: ["view.columns", "values"],
        },
      ).catch((error) => {
        toast.error(
          `Datatable failed to get default filter: ${error.data.message}`,
        );
        console.error(error.data.message);
      });

      // if there is a default filter set it
      if (response?.data) {
        const { id, name, view, values } = response.data;

        const filterOption = {
          label: name,
          value: id,
          isDefault: true,
          view,
          values,
        };

        // update default filter id
        setDefaultFilterId(id);

        // update the users selected filter ID
        if (overwriteCurrentFilter) handleOnChange(filterOption);
      } else {
        // update default filter id to null
        setDefaultFilterId(null);

        // set the filter to null
        if (overwriteCurrentFilter) handleOnChange(null);
      }
    } else {
      if (overwriteCurrentFilter) handleOnChange(null);
    }
  }

  // edits the stored default filter
  function eDefaultFilter(isDefault) {
    toast.promise(
      APIClient.patch(
        `profile/${AuthService.currentUser.active_profile.id}/filter/${isDefault ? null : initialFilterId}/default/${datatableKey}`,
      ),
      {
        loading: "Updating Default Filter",
        success: (response) => {
          // update selected filter to change the value of isDefault
          setSelectedFilter({
            ...selectedFilter,
            isDefault: !isDefault,
          });

          setDefaultFilterId(response?.data?.id);

          return "Default Filter Updated Successfully";
        },
        error: (error) => {
          console.error(error);
          return `Failed to update default filter - ${error.data.message}`;
        },
      },
    );
  }

  /* --------------------------------- markup --------------------------------- */
  return (
    <>
      <FormSelect
        ref={selectRef}
        name="filter"
        value={selectedFilter}
        onChange={handleOnChange}
        asyncRoute={`profile/${AuthService.currentUser.active_profile.id}/filter`}
        asyncData={{
          datatableKey,
          include: ["view.columns", "values"],
        }}
        asyncMap={({ id, name, is_default, view, values }) => ({
          label: `${name}`,
          value: id,
          isDefault: id == defaultFilterId,
          view,
          values,
        })}
        isClearable={true}
        className="tw-mr-3 tw-w-60"
        actions={[
          {
            function: () => {
              setShowFilterManager(true);
            },
            icon: <LuFilter />,
            tooltip: "Edit Filter",
          },
          {
            function: () => {
              eDefaultFilter(selectedFilter?.value == defaultFilterId);
            },
            icon:
              selectedFilter?.value == defaultFilterId ? (
                <LuHeartOff />
              ) : (
                <LuHeart />
              ),
            tooltip:
              selectedFilter?.value == defaultFilterId
                ? "Remove Default"
                : "Set Default",
            disabled: !selectedFilter,
          },
        ]}
      />
      <FilterManager
        visible={showFilterManager}
        onHide={handleFilterManagerClose}
        datatableKey={datatableKey}
        columns={columns}
        initialFilterOption={selectedFilter}
      />
    </>
  );
}
