import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Image,
  Spinner,
} from "react-bootstrap";
import AuthService from "../services/AuthService";
import { Link, useNavigate } from "react-router-dom";

// EmailVerifyReturn.js
export default function EmailVerifyReturn() {
  const [validatedStatus, setValidatedStatus] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    // get id
    let pathnameSplit = window.location.pathname.split("/");
    let id = pathnameSplit[pathnameSplit.length - 1];

    // Gets the token and email from url
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let expires = urlParams.get("expires");
    let hash = urlParams.get("hash");
    let signature = urlParams.get("signature");

    // Begin Email Validation
    validateEmail(id, expires, hash, signature);
  });

  //Send user id/hash/signature from URL to Auth Service
  const validateEmail = async (id, expires, hash, signature) => {
    const postData = {
      expires: expires,
      hash: hash,
      signature: signature,
    };

    const response = await AuthService.verifyEmail(id, postData);
    if (response.status == 200) {
      setValidatedStatus(true);
    } else {
      setValidatedStatus("error");
    }
  };

  //Set default UI text elements
  let messageContent = "";
  let Content = "Validating...";
  let showSpinner = false;

  //Define UI text elements based on validation status
  switch (validatedStatus) {
    case false:
      Content = "Validating...";
      showSpinner = true;
      break;
    case true:
      messageContent =
        "You have succesfully verified your email! Redirecting to login...";
      Content = "Verified!";
      showSpinner = true;
      setTimeout(() => navigate("/login"), 2000);
      break;
    default:
      messageContent =
        "There has been an error validating your email, please retry the verification link or contact an administrator";
      Content = "Email verification failed!";
      showSpinner = false;

      break;
  }

  return (
    <>
      <div className="unAuthBackground">
        <div className="unAuthUtilityContainer">
          <Container className="p-5">
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  width: 500,
                }}
              >
                <h2 className="pb-3 text-center">{Content}</h2>
                <p className="pb-3">{messageContent}</p>

                {showSpinner && (
                  <Spinner
                    className="pt-3 themeTextColour"
                    animation="border"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
