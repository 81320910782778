/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// Internal Imports
import Toggle from "react-toggle";
import { DarkMode } from "../App";

/* ---------------------------- external imports ---------------------------- */
import { LuArrowRight, LuArrowLeft } from "react-icons/lu";

export default function SiderToggler(props) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <div style={{ position: "fixed", bottom: 0, left: 0, padding: 14 }}>
      <Toggle
        onChange={() => props.toggleSidebar()}
        className={darkMode ? "dark-toggle" : ""}
        icons={{
          checked: (
            <LuArrowLeft
              style={{
                color: "white",
                fontSize: 12,
                paddingBottom: 1,
              }}
            />
          ),
          unchecked: (
            <LuArrowRight
              style={{
                color: "white",
                fontSize: 12,
                paddingBottom: 1,
              }}
            />
          ),
        }}
      />
    </div>
  );
}
