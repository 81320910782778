/* ------------------------------ core imports ------------------------------ */
import { useRef } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs

// Internal Imports
import ToggleableForm from "../../../components/forms/ToggleableForm";
import Roles from "../../../services/data-formatting/profileRoles";
import AuthService from "../../../services/AuthService";

export default function UserProfileDetailsForm({
  user,
  onSuccess: pOnSuccess,
  canEdit,
}) {
  const updateUserForm = useRef();

  // Runs when the form submit is successful
  function onSuccess(response) {
    // if the updated user id matches the current user ID we should update the authed users details
    if (user.id == AuthService.currentUser.id) AuthService.isAuthenticated();

    // Run parents on success method
    pOnSuccess();
  }

  return (
    <ToggleableForm
      ref={updateUserForm}
      canEdit={canEdit}
      submitRoute={`profile/${user.pivot.profile_id}/user/${user.id}`}
      submitMethod="patch"
      submitData={{ include: ["profiles"] }}
      onSuccess={onSuccess}
      id="update-user"
      resetOnSubmit={false}
      inputData={[
        {
          name: "forename",
          label: "Forename(s)",
          placeholder: "Users forename(s)",
          type: "text",
          initialValue: user.forename,
          validation: Yup.string().required("Forename is required").max(255),
        },
        {
          name: "surname",
          label: "Surname",
          placeholder: "Users Surname",
          type: "text",
          initialValue: user.surname,
          validation: Yup.string().required("Surname is required").max(255),
        },
        {
          disabled: true,
          name: "email",
          label: "Email",
          placeholder: "Users Email",
          type: "email",
          initialValue: user.email,
          validation: Yup.string()
            .email()
            .required("Email is required")
            .max(255),
        },
        {
          name: "role_id",
          label: "Role",
          type: "select",
          initialValue: {
            label: Roles(user.pivot.role_id),
            value: user.pivot.role_id,
          },
          options: [
            { label: Roles(1), value: 1 },
            { label: Roles(2), value: 2 },
          ],
        },
      ]}
    />
  );
}
