/* ------------------------------ core imports ------------------------------ */
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

/* ---------------------------- internal imports ---------------------------- */

import { default as useQuaryParams } from "../../services/useParams";
import AuthService from "../../services/AuthService";
import StepAssistant from "../../components/widgets/StepAssistant";
import S1_Information from "./S1_Information";
import S2_UserDetails from "./S2_UserDetails";
import S3_Complete from "./S3_Complete";

/* ---------------------------- external imports ---------------------------- */

// Description of what the component does
export default function Onboarding() {
  /* --------------------------------- state --------------------------------- */
  const [validatedStatus, setValidatedStatus] = useState("validating");
  const [canProceed, setCanProceed] = useState(false);
  const [user, setUser] = useState(null);

  // get id from
  const { id } = useParams();
  const { expires, hash, signature } = useQuaryParams();

  let navigate = useNavigate();

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    // Begin Email Validation
    validateEmail(id, expires, hash, signature);
  }, []);

  /* ---------------------------------- refs ---------------------------------- */
  const formRef = useRef();

  /* -------------------------------- functions ------------------------------- */
  //Send user id/hash/signature from URL to Auth Service
  async function validateEmail(id, expires, hash, signature) {
    const postData = {
      expires: expires,
      hash: hash,
      signature: signature,
    };

    const response = await AuthService.verifyEmail(id, postData);
    if (response.status == 200) {
      setValidatedStatus("validated");
      setCanProceed(true);
      setUser(response.data.user);
    } else {
      setValidatedStatus("error");
      setCanProceed(false);
      setUser(null);
    }
  }

  async function submitUserData(data) {
    // submit data to backend
    let result = await formRef.current
      .form()
      .submitForm()
      .catch((error) => {
        return false;
      });

    // return true or false to indicate if we can progress
    return result != null && result != false;
  }

  /* --------------------------- pre jsx computation -------------------------- */

  const data = [
    {
      title: "Welcome to Spark",
      body: <S1_Information status={validatedStatus} />,
      style: {
        textAlign: "center",
      },
      nextCondition: canProceed,
      nextConditionMessage:
        "Email must be validated before continuing, please wait.",
      style: {
        minHeight: "200px",
      },
    },
    {
      title: "About you",
      body: (
        <S2_UserDetails
          formRef={formRef}
          initialUser={user}
          hash={hash}
          expires={expires}
          signature={signature}
        />
      ),
      checkMethod: submitUserData,
    },
    {
      title: "Congratulations",
      body: <S3_Complete />,
      style: {
        textAlign: "center",
      },
    },
  ];

  /* --------------------------------- markup --------------------------------- */
  return (
    <StepAssistant
      steps={data}
      escapeRoute="/"
      finishRoute="/"
      leaveWarning={true}
      elementStyle={{ width: 800 }}
    />
  );
}
