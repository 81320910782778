/* ------------------------------ core imports ------------------------------ */
import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

/* ---------------------------- external imports ---------------------------- */
import { LuChevronLeft, LuEye, LuUserPlus, LuTrash } from "react-icons/lu";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

// Internal Imports
import APIClient from "../../services/clients/APIClient";
import UpdateUserPasswordModal from "./elements/UpdateUserPasswordModal";
import UserDetailsForm from "./elements/UserDetailsForm";
import RemoveUserModal from "./elements/RemoveUserModal";
import { formatDateForDatatable } from "../../services/data-formatting/dates";
import DataBadge from "../../components/data-display/DataBadge";
import { DarkMode } from "../../components/App";
import DataRibbon from "../../components/data-display/DataRibbon";

// structure
import DynamicLayout from "../../components/layouts/DynamicLayout";
import ProfileWidget from "../../components/widgets/ProfileWidget";
import FormWidget from "../../components/widgets/FormWidget";
import ActionWidget from "../../components/widgets/ActionWidget";

export default function UserDetail() {
  // - context -
  const { darkMode, setDarkMode } = useContext(DarkMode);

  let navigate = useNavigate();
  let { id } = useParams();

  // - states-
  const [user, setUser] = useState(null);
  const [showPasswordResetModal, setPasswordResetModal] = useState(false);
  const [isDeleteUserVisible, setIsDeleteUserVisible] = useState(false);
  const [ribbonData, setRibbonData] = useState([]);

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    fetchUser();
  }, [id]);

  useEffect(() => {
    let data = [];
    if (user) {
      console.log(
        `Last Active: ${formatDateForDatatable(user.last_active_at)}`,
      );
      data = [
        {
          label: `Last Active: ${formatDateForDatatable(user.last_active_at)}`,
          icon: LuEye,
        },
        {
          label: `Email Verified: ${formatDateForDatatable(user.email_verified_at)}`,
          icon: LuUserPlus,
        },
        {
          label: `Registered: ${formatDateForDatatable(user.created_at)}`,
          icon: LuUserPlus,
        },
      ];
    }
    setRibbonData(data);
  }, [user]);

  /* -------------------------------- functions ------------------------------- */
  async function fetchUser() {
    await setUser(
      (await APIClient.get(`user/${id}`, { include: ["roles", "profiles"] }))
        .data,
    );
  }

  /* --------------------------------- markup --------------------------------- */
  if (user) {
    return (
      <>
        <DataRibbon data={ribbonData} />
        <Link to="/user-manager">
          <button
            className={
              darkMode
                ? "button-regular_dark tw-mb-4 tw-flex tw-items-center tw-text-sm"
                : "button-regular tw-mb-4 tw-flex tw-items-center tw-text-sm"
            }
          >
            <LuChevronLeft style={{ fontSize: 18 }} /> All Users
          </button>
        </Link>

        <DynamicLayout
          data={[
            {
              gap: 5,
              cols: [
                {
                  width: 4,
                  content: (
                    <>
                      <ProfileWidget user={user} />
                      <ActionWidget
                        actions={[
                          {
                            label: "Reset Users Password",
                            key: "reset-password",
                            onClick: () => setPasswordResetModal(true),
                          },
                          {
                            variant: "danger",
                            label: "Remove",
                            key: "delete",
                            onClick: () => setIsDeleteUserVisible(true),
                          },
                        ]}
                      />
                    </>
                  ),
                },
                {
                  width: 8,
                  content: (
                    <FormWidget title="About">
                      <UserDetailsForm user={user} setUser={setUser} />
                    </FormWidget>
                  ),
                },
              ],
            },
          ]}
        />

        {/* modals */}
        <UpdateUserPasswordModal
          visible={showPasswordResetModal}
          onHide={() => setPasswordResetModal(false)}
          id={id}
        />
        <RemoveUserModal
          visible={isDeleteUserVisible}
          onHide={() => setIsDeleteUserVisible(false)}
          onSuccess={() => {
            navigate("/user-manager");
          }}
          user={user}
        />
      </>
    );
  } else {
    return <h1>Loading...</h1>;
  }
}
