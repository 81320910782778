/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// internal imports
import { DarkMode } from "../App";

// used to display a single card element
export default function DisplayCard({ cardData }) {
  // destruct cardData passed from parent
  const {
    thumbnail, // image to be displayed as the thumbnail
    header, // content to be displayed in the header of the card
    title, // content to be displayed as the title of the card
    subtitle, // content to be displayed as the subtitle of the card
    body, // content to be displayed in the body of the card
    footer, // content to be displayed in the footer of the card
  } = cardData;

  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <div className="tw-w-full tw-max-w-sm lg:tw-flex lg:tw-max-w-full">
      <div
        className={`${darkMode ? "tw-border-hue-600 tw-bg-hue-800" : "tw-border-hue-200 tw-bg-hue-50"} tw-mb-5 tw-flex tw-w-full tw-flex-col tw-justify-between tw-rounded-lg tw-border tw-border-solid tw-p-4 tw-leading-normal tw-shadow-md `}
      >
        {header && (
          <div className="tw-border-b">
            <div className="tw-mb-2 tw-text-hue-500">{header}</div>
          </div>
        )}
        {thumbnail && (
          <img
            className="tw-mb-4 tw-h-48 tw-w-full tw-rounded-md tw-object-cover tw-object-center"
            src={thumbnail.source}
            alt="thumbnail"
          />
        )}
        {title && (
          <div className="tw-border-b">
            <p
              className={`${darkMode ? "tw-text-white" : "tw-text-hue-500"} tw-flex tw-items-center tw-break-all tw-text-sm`}
            >
              {title}
            </p>
            {subtitle && (
              <div
                className={`${darkMode ? "tw-text-white" : "tw-text-hue-800"} tw-mb-2 tw-text-xl tw-font-medium`}
              >
                {subtitle}
              </div>
            )}
          </div>
        )}
        {body && (
          <div
            className={`${darkMode ? "tw-text-white" : "tw-text-hue-700"}tw-text-base tw-whitespace-break-spaces tw-py-3`}
          >
            {body}
          </div>
        )}
        {footer && (
          <div className="tw-flex tw-items-center tw-border-t tw-pt-3">
            <div className="tw-text-sm">
              <span
                className={`${darkMode ? "tw-text-white" : "tw-text-hue-500"} tw-text-sm tw-leading-none tw-text-hue-500`}
              >
                {footer}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
