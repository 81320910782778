/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// Internal imports
import { DarkMode } from "../App";

// External Imports
import { Row } from "react-bootstrap";

// Component used to create a profile picture for a given user and size
export default function FormWidget({ title, children }) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    //if padding is true, add the viewContainer class to the div
    <div
      className={`view-container tw-mb-5 ${darkMode ? "view-container_dark" : "view-container_light"}`}
    >
      <Row>
        <span className={darkMode ? "title-font_dark" : "title-font"}>
          {title}
        </span>
        {children}
      </Row>
    </div>
  );
}
