/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";
import { Link } from "react-router-dom";

// - internal imports
import ButtonGroup from "../buttons/ButtonGroup";
import { DarkMode } from "../App";

/* ---------------------------- external imports ---------------------------- */
import { LuChevronLeft } from "react-icons/lu";

export default function NavigationWidget(props) {
  const {
    actions,
    backRoute, // The route the the user can return to
    backMessage,
  } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */

  /* --------------------------------- effects -------------------------------- */

  /* -------------------------------- functions ------------------------------- */

  // - styles -

  /* --------------------------- pre jsx computation -------------------------- */

  /* --------------------------------- markup --------------------------------- */
  return (
    <div className="tw-flex tw-w-full tw-justify-between">
      <div className="tw-flex tw-items-end">
        {backRoute && (
          <Link to={backRoute}>
            <button
              className={`tw-mb-4 ${darkMode ? "button-regular_dark" : "button-regular"}`}
            >
              <LuChevronLeft style={{ fontSize: 18 }} />
              {backMessage ? backMessage : "Back"}
            </button>
          </Link>
        )}
      </div>
      <div>
        <ButtonGroup useRowsAndCols={true} actions={actions} />
      </div>
    </div>
  );
}
