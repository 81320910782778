// Imports
import Model from "./Model";
import Placeholder from "../../img/placeholder.png";

export default class Profile extends Model {
  constructor(data) {
    // Pass to parent constructor with formatter for custom variables
    super(data, (key, value) => {
      switch (key) {
        case "picture":
          // Create single profile
          return value
            ? `data:image/${value.extension};base64, ${value.data}`
            : Placeholder;
        default:
          return value;
      }
    });
  }
}
