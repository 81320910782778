/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// Internal Imports
import { DarkMode } from "../../App";

/* ---------------------------- external imports ---------------------------- */
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Form } from "react-bootstrap";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

export default function FormDateRangePicker(props) {
  // destruct props passed from parent
  const { name, isDisabled, isRequired } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <Form.Group style={{ display: "flex", flexDirection: "column" }}>
      {props.label && (
        <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
          {props.label}
        </Form.Label>
      )}
      <DateRangePicker
        disabled={isDisabled}
        required={isRequired}
        format="dd/MM/yyyy"
        minDate={new Date(1900, 0, 1)}
        calendarAriaLabel="Toggle calendar"
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        className={`${darkMode ? "spark-datetimeformat-wrapper_dark" : "spark-datetimeformat-wrapper"}`}
        yearAriaLabel="Year"
        clearAriaLabel="Clear value"
        calendarClassName={
          darkMode ? "spark-date-picker_dark" : "spark-date-picker"
        }
        // Using Inner Ref to fix Safari only issue where the date picker closes when clicking on the calendar see https://app.clickup.com/t/8693npfvz
        inputRef={(ref) => {
          ref?.setAttribute("tabindex", "0");
        }}
        {...props}
      />
    </Form.Group>
  );
}
