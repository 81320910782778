import { useContext } from "react";
import WelcomeImage from "../../../img/spark_patch.png";

// Internal Imports
import Modal from "./Modal";
import { DarkMode } from "../App";

export default function WelcomeModal(props) {
  // Destruct props into constants
  const { title, message, changeLog = [] } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <Modal {...props}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img src={WelcomeImage} alt="logo" className="tw-w-full tw-border-white tw-border tw-rounded-md" />
        <span
          className={`tw-mt-4 tw-p-3 ${darkMode ? "tw-text-hue-200" : "tw-text-hue-500 "}`}
        >
          <span
            className={
              darkMode
                ? "tw-pt-2 tw-font-sans tw-text-2xl tw-font-bold tw-text-white"
                : "tw-pt-2 tw-font-sans tw-text-2xl tw-font-bold tw-text-hue-900"
            }
          >
            What's New in Spark Portal?
          </span>
          <p className="tw-pt-3">{message}</p>

          {/* changelog area */}
          <ul className="tw-list-disc tw-pt-2">
            {changeLog.map((change, index) => (
              <li key={index}>{change}</li>
            ))}
          </ul>
        </span>
      </div>
    </Modal>
  );
}
