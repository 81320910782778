// removes unwanted keys from an object returning the resulting object
export function sanitiseObject(object, excludedKeys) {
  // Remove all the excluded keys to create sanitised object
  excludedKeys.forEach((excludedKey) => {
    delete object[excludedKey];
  });

  // returns sanitised object
  return object;
}
