/* ---------------------------- internal imports ---------------------------- */

import ButtonGroupButton from "./ButtonGroupButton";

// Basic Modal Template using Bootstrap modal
export default function ButtonGroup(props) {
  const {
    actions = [],
    data,
    className,
    buttonStyle,
    containerStyle,
    isVertical,
    shrinkAt, // The breakpoint at which the buttons will shrink (2xl, xl, lg, md, sm or xs)
  } = props;

  // Maps the actions to button group buttons
  const mapActions = () => {
    if (!actions.length || actions === null) return <code>Config error</code>;
    return actions.map((action, index) => {
      // Setup button style
      let style = action.style ? action.style : {};
      style = { ...style, ...buttonStyle };

      return (
        <ButtonGroupButton
          key={index}
          {...action}
          className={`${action.className || ""} ${className || ""} ${isVertical ? "tw-my-1" : "tw-mr-2"}`}
          data={action.data || data}
          style={style}
          variant={action.variant}
          shrinkAt={shrinkAt}
        />
      );
    });
  };

  // If we want to use rows and cols then we need to surround the actions in a row
  return (
    <div
      className={`tw-flex ${isVertical ? "tw-flex-col" : "tw-flex-row"}`}
      style={containerStyle}
    >
      {mapActions()}
    </div>
  );
}
