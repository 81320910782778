/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// Internal Imports
import { DarkMode } from "../../App";

/* ---------------------------- external imports ---------------------------- */
import DateTimePicker from "react-datetime-picker";
import { Form } from "react-bootstrap";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

export default function FormDateTimePicker(props) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <Form.Group style={{ display: "flex", flexDirection: "column" }}>
      {props.label && (
        <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
          {props.label}
        </Form.Label>
      )}
      <DateTimePicker
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.isDisabled}
        required={props.isRequired}
        format={"y-MM-dd h:mm:ss a"}
        clearIcon={null}
        disableClock={true}
        className={`${darkMode ? "spark-datetimeformat-wrapper_dark" : "spark-datetimeformat-wrapper"}`}
        {...props}
        calendarClassName={
          darkMode ? "spark-date-picker_dark" : "spark-date-picker"
        }
        // Using Inner Ref to fix Safari only issue where the date picker closes when clicking on the calendar see https://app.clickup.com/t/8693npfvz
        inputRef={(ref) => {
          ref?.setAttribute("tabindex", "0");
        }}
      />
    </Form.Group>
  );
}
