/* ------------------------------ core imports ------------------------------ */
import {
  forwardRef,
  useEffect,
  useState,
  useRef,
  useContext,
  useImperativeHandle,
} from "react";

/* ---------------------------- internal imports ---------------------------- */

import { getColorFromValue } from "../../../services/AutomaticColourPicker"; // selects a color based on value entered
import APIClient from "../../../services/clients/APIClient";
import { DarkMode } from "../../App";

/* ---------------------------- external imports ---------------------------- */
import toast from "react-hot-toast";
import {
  default as BasicSelect,
  components as SelectComponents,
} from "react-select"; // This is the component we are using for the select box
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable"; // allows react-select to create new values within the select
import { Col, Form, Row } from "react-bootstrap"; // Holds the bootstrap form components we are using
import chroma from "chroma-js"; // Manages on the fly adjustments to colors
import { DndContext } from "@dnd-kit/core"; // used to make sortable selects have draggable options
import { SortableContext, useSortable } from "@dnd-kit/sortable"; // used to make sortable selects have draggable options
import { CSS } from "@dnd-kit/utilities";
import { LuX, LuGrip, LuClipboard } from "react-icons/lu";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Select input to be used in forms
export default forwardRef((props, ref) => {
  // destruct props passed from parent
  const {
    name, // The name of the component used for the controlId and key of the form group
    label, // (Optional) The label to display above the select
    options = [], // (Optional) used to set the options that can be selected within the select box, if we are using an asyncRoute the options will be appended onto any returned using the async route
    defaultValue = [], // (optional) The default value of the select
    value, // current value of the select
    onChange, // function to run when the selected value(s) change
    isDisabled,
    asyncRoute, // (Optional) used to set the route for the select to pull its options from based on the search string
    asyncData,
    asyncMap,
    isClearable = false, // (Optional) Can the select item be cleared using the clear button (Will not be shown if false)
    isMulti = false, // (Optional) Can the user select more than one item at a time
    isCreatable = false, // (Optional) Can new select items be created within the select
    newValueValidation, // (Optional) Used to validate new values when creating within select
    isSortable = false,
    feedback, // A message to display under the select, typically used to display error messages
    style,
    menuPlacement = "auto", // how should the select menu be placed on the screen when opened see (react-select docs for options)
    isCopyable = false, // Do we want to display a button allow for the select contents to be easily copied to clipboard
    action, // (Optional) A single action to be displayed as an button next to the select [This may be phased out later]
    actions, // (Optional) A array of actions to be displayed as button next to the select
    className, // Classes passed from the parent
  } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */
  const [defaultMenuIsOpen, setDefaultMenuIsOpen] = useState(null);
  const [lastSearch, setLastSearch] = useState("");
  const [nextSearch, setNextSearch] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isFetchQueued, setIsFetchQueued] = useState(false);
  const [isCoolingDownAfterFetchingData, setIsCoolingDownAfterFetchingData] =
    useState(false);
  const [asyncOptions, setAsyncOptions] = useState(options);

  /* ---------------------------------- refs ---------------------------------- */
  // holds the ref for the current select
  const selectComponentRef = useRef();
  const selectRef = useRef();

  // use imperative handle allows the parent to access functions from this component
  useImperativeHandle(ref, () => ({
    loadOptions() {
      loadOptions();
    },
  }));

  /* --------------------------------- effects -------------------------------- */
  // When asyncRoute updates we should build the select component
  useEffect(() => {
    // Use Basic Select Component
    selectComponentRef.current = isCreatable ? CreatableSelect : BasicSelect;

    // next time the select re renders we should not have the menu open (unless changed elsewhere)
    setDefaultMenuIsOpen(false);

    // reload Options to update the excludedIds based on the new value
    if (asyncRoute) loadOptions(lastSearch);
  }, [asyncRoute]);

  // Listen for changes in is fetching data
  useEffect(() => {
    // if is fetching data is set to false and we have another fetch queued we should run that fetch otherwise do nothing
    if (!isFetchingData && isFetchQueued) {
      setIsFetchQueued(false);
      loadOptions(nextSearch);
    }
  }, [isFetchingData]);

  // If isCoolingDownAfterFetchingData is set to true wait a second and set isFetchingData to false
  useEffect(() => {
    if (isCoolingDownAfterFetchingData) {
      // Wait a second before next request
      setTimeout(() => {
        setIsFetchingData(false);
        setIsCoolingDownAfterFetchingData(false);
      }, 1000);
    }
  }, [isCoolingDownAfterFetchingData]);

  /* -------------------------------- functions ------------------------------- */
  // handles when the search changes in the input box (only used for Async selects)
  function onInputChange(inputValue, { action, prevInputValue }) {
    switch (action) {
      case "input-blur":
        return prevInputValue;
      default:
        loadOptions(inputValue);
        return inputValue;
    }
  }

  // Method used to load the options for async selects
  async function loadOptions(search) {
    // If we are already fetching data we should mark that once we are done fetching data we want to fetch data again
    if (isFetchingData) {
      // queue the fetch by setting the next search and chaning isFetchQueued to true
      setIsFetchQueued(true);
      setNextSearch(search);
      return asyncOptions;
    } else {
      // Update isFetchtingData to true to put a delay on additional fetches
      setIsFetchingData(true);

      // update last search so it can reused
      setLastSearch(search);
      setNextSearch(null);

      // get selected options (or options)
      const selectedOptions = value || defaultValue;
      let excludeIds = [];

      // if we have a selected option we should build an array of excluded ids
      if (selectedOptions) {
        if (Array.isArray(selectedOptions)) {
          // get excluded ids
          excludeIds = selectedOptions.map((selectedOption) => {
            const optionValue = selectedOption.value;
            return typeof optionValue == "object"
              ? optionValue.id
              : optionValue;
          });
        } else {
          excludeIds = [
            typeof selectedOptions == "object"
              ? selectedOptions.id
              : selectedOptions,
          ];
        }
      }

      // Get options for async select options
      return await APIClient.get(asyncRoute, {
        search,
        excludeIds,
        ...asyncData,
      })
        .then((response) => {
          // begin cool down before accepting another request
          setIsCoolingDownAfterFetchingData(true);

          const newOptions = [
            ...response.data.map(asyncMap),
            ...options.filter((option) => option.label.includes(search)),
          ];

          // User the async map function to get the data from the returned items adding any static options
          setAsyncOptions(newOptions);

          return newOptions;
        })
        .catch((error) => {
          // begin cool down before accepting another request
          setIsCoolingDownAfterFetchingData(true);

          console.error(error);
          toast.error(
            `Issue Loading ${name}`,
            `Failed to load available ${name}, please try again.`,
          );
          throw error;
        });
    }
  }

  // handles when a value is added or removed from the select
  function onSelectChange(data, element) {
    // run response based on action that caused change
    switch (element?.action) {
      case "select-option": // When a new option is selected from the options
        setDefaultMenuIsOpen(true);
        // pass data to onChange prop function
        if (onChange) onChange(data, element);
        break;
      case "create-option": // When a new option is created using the isCreatable option
        // if the options passes the new value validation then add it to the select otherwise return false
        if (!newValueValidation || newValueValidation(element.option.value)) {
          // pass data to onChange prop function
          if (onChange) onChange(data, element);
        } else if (isMulti) {
          // if it's a multi select
          // Remove the new item from the data array
          if (onChange)
            onChange(
              data.filter((item) => item.value != element.option.value),
              element,
            );
        } else {
          // if it's not a multi select
          // set the selected value to null
          if (onChange) onChange(null, element);
        }
        break;
      default:
        // pass data to onChange prop function
        if (onChange) onChange(data, element);
    }
  }

  // Calculates the width of a line based on the all the option widths between the last moved option and the current moving option as identified by the element parameter
  function transformCalcLineWidth(element) {
    // get the other options within the select
    const otherOptions = element?.parentNode?.children;

    // get the index of the option that is currently selected
    const optionIndex = otherOptions
      ? Array.from(otherOptions).findIndex((option) => option == element)
      : null;

    // total the width of all options between the last transformed option and selected option
    let lineWidth = 0;
    let lastOffsetTop = 0;
    for (
      let index = transformLineStartingOptionIndex;
      index < optionIndex;
      index++
    ) {
      // if this option does not have the same offsetTop as the last option
      if (lastOffsetTop != otherOptions[index].offsetTop) {
        // start the line width from the current option width as it is on a new line.
        lineWidth = otherOptions[index].offsetWidth;
        lastOffsetTop = otherOptions[index].offsetTop;
      } else {
        // else add the width to the last option
        lineWidth += otherOptions[index].offsetWidth;
      }
    }

    // when we calculate line width we are at the end of a line so we can update the transformLineStartingOptionIndex
    transformLineStartingOptionIndex = optionIndex;

    // Return the width of the line
    return lineWidth;
  }

  // custom logic for transformation during dragging of selected option (Used for orderable multiselect)
  function transformAdapter(dndTransform, attributes, element) {
    // get width of element
    const width = element?.offsetWidth || 0;

    // if the element is currently being dragged
    if (attributes && attributes["aria-pressed"]) {
      // update transform X offset to the width of the option being dragged
      transformXOffset = width;

      // reset transformLineStartingOptionIndex
      transformLineStartingOptionIndex = 0;

      // use default translation
      return {
        transform: CSS.Translate.toString(dndTransform),
      };
    } else {
      // determine if the option is moving down
      const movingDown = dndTransform?.y > 0;
      const movingUp = dndTransform?.y < 0;

      // if we are moving down transform y using the dndTransform
      let transformY = movingDown ? dndTransform?.y : 0;
      transformY = movingUp ? dndTransform?.y : transformY;

      // If we are moving up or down use the width of the last line - the width of the transformXOffset which represents the last item to be displaced
      let transformX = movingUp
        ? transformCalcLineWidth(element) - transformXOffset
        : // if we are moving down take the dnd transform x
          movingDown
          ? dndTransform?.x
          : // if dndTransform x is positive move the option to for the transformXOffset
            dndTransform?.x > 0
            ? transformXOffset
            : // if the dndTransform x is negative move the option for the negative transformXOffset
              dndTransform?.x < 0
              ? -transformXOffset
              : // else don't move or revert to default position
                0;

      // if an item has been moved down we should change th transformXOffset to the width of this item
      if (movingDown || movingUp) {
        // update transform X offset to the width of the option that is moving down
        transformXOffset = width;
      }

      // return styles to apply to option
      return { transform: `Translate(${transformX}px, ${transformY}px)` };
    }
  }

  // renders a single icon button to be placed next to the select
  function renderButton(action, key) {
    const buttonContent = (
      <button
        key={key}
        disabled={action?.disabled}
        className={
          darkMode
            ? "form-select-action-container_dark"
            : "form-select-action-container"
        }
        onClick={action.function}
      >
        <span
          className="tw-text-1xl tw-flex tw-items-center tw-justify-center"
          style={{ color: "#cccccc" }}
        >
          {action.icon}
        </span>
      </button>
    );

    // if the action has a tool tip add a tooltip to the button content
    if (action.tooltip) {
      return (
        <OverlayTrigger
          key={key}
          overlay={<Tooltip>{action?.tooltip}</Tooltip>}
        >
          {buttonContent}
        </OverlayTrigger>
      );
    } else {
      // otherwise just return button content
      return buttonContent;
    }
  }

  // - styles -
  const styles = {
    container: (styles) => ({
      ...styles,
      flex: 1,
    }),
    input: (styles) => ({
      ...styles,
      width: "100%",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: darkMode ? "#cccccc" : "#000000",
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 10000,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: darkMode ? "#cccccc" : "#000000",
    }),
    control: (styles, { data, isDisabled }) => ({
      ...styles,
      border: darkMode ? "solid 1px #6b7280" : "solid 1px #e2e2e2",
      borderRadius: 5,
      backgroundColor: darkMode
        ? isDisabled
          ? "#eee"
          : "#4b5563"
        : isDisabled
          ? "#eee"
          : "#fcfcfc",
      flex: 1,
      borderRight: action || actions ? "none" : undefined,
      borderRadius: action || actions ? "5px 0 0 5px" : "5px",
      height: isMulti ? null : "100%",
      minHeight: 45,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color =
        isMulti && data.value != null
          ? getColorFromValue(
              typeof data.value == "object" ? data.value.id : data.value,
            )
          : "#fcfcfc";
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? color
            : isFocused
              ? color
              : undefined,
        color: isDisabled
          ? "#ccc"
          : isFocused
            ? chroma.contrast(color, "white") > 2
              ? "white"
              : "black"
            : "black",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: isDisabled ? undefined : color,
        },
      };
    },
    multiValue: (styles, { data }) => ({
      ...styles,
      zIndex: "9999!important",
      backgroundColor:
        data.value != null
          ? getColorFromValue(
              typeof data.value == "object" ? data.value.id : data.value,
            )
          : "#fcfcfc",
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      paddingLeft: "3px", // default is padding left 6px so we override this to 3px
      color:
        chroma.contrast(
          data.value != null
            ? getColorFromValue(
                typeof data.value == "object" ? data.value.id : data.value,
              )
            : "#fcfcfc",
          "white",
        ) > 2
          ? "white"
          : "black",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color:
        chroma.contrast(
          data.value != null
            ? getColorFromValue(
                typeof data.value == "object" ? data.value.id : data.value,
              )
            : "#fcfcfc",
          "white",
        ) > 2
          ? "white"
          : "black",
      ":hover": {
        backgroundColor: "#BBB",
      },
    }),
  };

  /* --------------------------- pre jsx computation -------------------------- */
  // used to store current offset when dragging elements when isSortable is true
  let transformXOffset = 0;
  let transformLineStartingOptionIndex = 0;

  // Set initial props shared by all selects
  const selectProps = {
    name,
    isClearable,
    onChange: onSelectChange,
    value: value || defaultValue,
    defaultValue,
    isDisabled,
    isMulti,
    closeMenuOnSelect: !isMulti,
    styles,
    ref: selectRef,
    defaultMenuIsOpen,
    autoFocus: defaultMenuIsOpen,
    hideSelectedOptions: true,
    menuPlacement,
    ...(asyncRoute
      ? {
          // add props for async route
          cacheOptions: true,
          defaultOptions: true,
          onInputChange,
          options: asyncOptions,
        }
      : {
          // add non-async options
          options,
        }),
    // is sortable props
    isSortable,
    transformAdapter,
    components: {
      MultiValueContainer,
      MultiValueRemove,
    },
  };

  return (
    <Form.Group controlId={name} key={name} style={style} className={className}>
      {/* Show label if it is defined */}
      {label && (
        <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
          {label}
        </Form.Label>
      )}
      {/* Show Sortable select if it is defined else show normal select */}
      {selectComponentRef.current && (
        <Row>
          <Col xs={isCopyable ? 10 : 12}>
            {isSortable ? (
              <OrderableSelectsContainer
                items={value || defaultValue}
                // When the order changes set the value of the select
                onChange={(newValue, action) => {
                  selectRef.current.setValue(newValue, action);
                  selectRef.current.handleInputChange({
                    currentTarget: selectRef.current.controlRef,
                  });
                }}
              >
                <selectComponentRef.current
                  {...selectProps} // Spread defined props into component
                />
              </OrderableSelectsContainer>
            ) : (
              <div className="tw-flex tw-h-full tw-flex-grow">
                <selectComponentRef.current
                  {...selectProps} // Spread defined props into component
                />
                {actions && actions.map(renderButton)}
                {action && action.function && renderButton(action)}
              </div>
            )}
          </Col>
          {/* if the select is copyable add a button for copying to clipboard */}
          {isCopyable ? (
            <Col xs={2} style={{ paddingLeft: 0 }}>
              <button
                type="button"
                disabled={false}
                className={`tw-mx-2 tw-h-full tw-w-full ${darkMode ? "button-regular_dark" : "button-regular"}`}
                tooltop="Copy"
                onClick={() => {
                  if (
                    value?.label != null &&
                    value?.label != undefined &&
                    value?.label != ""
                  ) {
                    navigator.clipboard.writeText(value.label);
                    toast.success("Copied to clipboard!");
                  } else {
                    toast.error("Please select an option to copy");
                  }
                }}
              >
                <span>
                  <LuClipboard size="1.3em" />
                </span>
              </button>
            </Col>
          ) : null}
        </Row>
      )}
      <Form.Control.Feedback
        type="invalid"
        style={{ display: feedback ? "block" : "none" }}
      >
        {feedback}
      </Form.Control.Feedback>
    </Form.Group>
  );
});

// orderable select container
function OrderableSelectsContainer(props) {
  // destruct props passed from parent
  const { items = [], children, onChange } = props;

  /* -------------------------------- functions ------------------------------- */
  function handleDragEnd({ active, over }) {
    // get index of active item, active item value and over index
    const activeIndex = items.findIndex(
      (item) => item.value.id == active.id || item.value == active.id,
    );
    const activeItem = items[activeIndex];
    const overIndex = items.findIndex(
      (item) => item.value.id == over.id || item.value == over.id,
    );

    // create copy of items
    let newItems = items;

    // remove active item from array
    newItems.splice(activeIndex, 1);

    // add active item at new location
    newItems.splice(overIndex, 0, activeItem);

    // pass new items to parents on change method
    onChange(newItems, { action: "order-changed" });
  }

  /* --------------------------------- markup --------------------------------- */
  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={items.map((item) => item.value)}>
        {children}
      </SortableContext>
    </DndContext>
  );
}

// orderable select multi value
function MultiValueContainer(props) {
  // destruct props passed from parent
  const { data, children, selectProps } = props;

  // destruct select props
  const { isSortable = false, transformAdapter } = selectProps;

  /* ---------------------------------- refs ---------------------------------- */
  const ref = useRef();

  /* --------------------------- pre jsx computation -------------------------- */
  // get id of option
  const id = data.value.id || data.value;

  // get sortable properties to pass into draggable span if isSortable is true
  const {
    attributes,
    listeners,
    setNodeRef = null,
    transform,
    transition,
  } = isSortable && useSortable({ id });

  // get styles for drag and drop if isSortable
  const style = isSortable
    ? {
        ...transformAdapter(
          transform,
          attributes,
          ref?.current?.parentNode.parentNode,
        ),
        transition,
      }
    : {};

  /* --------------------------------- markup --------------------------------- */
  return (
    <span id={id} ref={setNodeRef || null} style={style}>
      <div style={selectProps.isDisabled ? { opacity: 0.5 } : {}}>
        <SelectComponents.MultiValueContainer {...props}>
          <span className="tw-flex" ref={ref}>
            {/* Add grip for sorting it select is sortable */}
            {isSortable ? (
              <span className="tw-flex hover:tw-bg-neutral-400">
                <LuGrip
                  {...listeners}
                  {...attributes}
                  className={`tw-mx-1 tw-h-auto tw-text-sm ${chroma.contrast(data.value != null ? getColorFromValue(typeof data.value == "object" ? data.value.id : data.value) : "#fcfcfc", "white") > 2 ? "tw-text-white" : "tw-text-black"}`}
                />
              </span>
            ) : (
              <span className={`tw-mr-1`} />
            )}
            {children}
          </span>
        </SelectComponents.MultiValueContainer>
      </div>
    </span>
  );
}

// orderable select multi value remove button
function MultiValueRemove(props) {
  return (
    <SelectComponents.MultiValueRemove {...props}>
      <LuX className="tw-text-sm" />
    </SelectComponents.MultiValueRemove>
  );
}
