// core imports
import { useState, useRef, useEffect } from "react";

/* ---------------------------- internal imports ---------------------------- */

import FormSelect from "../../components/forms/inputs/FormSelect";
import ViewManagerPopover from "./ViewManager.popover.jsx";
import AuthService from "../../services/AuthService";
import APIClient from "../../services/clients/APIClient";

/* ---------------------------- external imports ---------------------------- */
import { LuEye } from "react-icons/lu";

export default function ViewManagerSelect(props) {
  // destruct props passed from parent
  const {
    datatableKey, // the key/id of the datatable
    selectedView, // The id of the currently selected view
    initialViewId,
    onChange, // Callback function to run when the selected view is changed
    columns, // The column data for the datatable
    refresh, // Callback function to run when the view manager is closed
  } = props;

  /* --------------------------------- state --------------------------------- */
  const [showViewManager, setShowViewManager] = useState(false);

  /* ---------------------------------- refs ---------------------------------- */
  const selectRef = useRef();

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    // if there is not a selected view when the initial view is set get the view data
    if (!selectedView && initialViewId) {
      fetchViewData();
    }
  }, [initialViewId]);

  /* -------------------------------- functions ------------------------------- */
  // fetches the filter data by Making a GET request to the API to get the filter with the id of selectedFilterID
  function fetchViewData() {
    APIClient.get(`view/${initialViewId}`, {
      datatableKey,
      include: ["columns"],
    })
      .then(({ data }) => {
        // Set the filter state to the response data
        handleViewManagerChange(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handles hiding the view manager
  function onHideViewManager(selectedView) {
    // refresh options within the filter select
    selectRef.current.loadOptions();

    // Change show view manager state to false
    setShowViewManager(false);
  }

  // handles when the selected view is changed using the filter manager
  function handleViewManagerChange(selectedView) {
    if (selectedView) {
      // else if f we have a new view update the selected view
      onChange({
        label: selectedView.name,
        value: selectedView.id,
        data: selectedView,
      });
    } else {
      onChange(null);
    }

    // we have a refresh prop run it
    if (refresh) refresh();
  }

  return (
    <>
      <FormSelect
        ref={selectRef}
        name="view"
        value={selectedView}
        onChange={onChange}
        asyncRoute={`profile/${AuthService.currentUser.active_profile.id}/view`}
        asyncData={{ datatableKey, include: ["columns"] }}
        asyncMap={(view) => ({
          label: `${view.name}`,
          value: view.id,
          data: view,
        })}
        isClearable={true}
        className="tw-mr-3 tw-w-56"
        action={{
          function: () => setShowViewManager(true),
          icon: <LuEye />,
          tooltip: selectedView ? "Edit View" : "Create View",
        }}
      />
      <ViewManagerPopover
        visible={showViewManager}
        onHide={onHideViewManager}
        onChange={handleViewManagerChange}
        columns={columns}
        selectedView={selectedView}
        datatableKey={datatableKey}
      />
    </>
  );
}
