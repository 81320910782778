/* ------------------------------ core imports ------------------------------ */
import { useContext, useState } from "react";

/* ---------------------------- internal imports ---------------------------- */

import { DarkMode } from "../App";
import Modal from "../modals/Modal";

export default function DataRibbon({ data }) {
  /* --------------------------------- context -------------------------------- */
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */
  const [showViewAll, setShowViewAll] = useState(false);

  /* --------------------------------- markup --------------------------------- */
  return (
    <div
      className="tw-relative tw-flex tw-flex-row tw-overflow-auto tw-pe-32"
      style={{ marginTop: "-10px", paddingBottom: 20 }}
    >
      <div
        className={`${darkMode ? "tw-from-hue-700 tw-text-white" : "tw-from-white tw-text-hue-500"} tw-hover:tw-Translate-x-1 tw-hover:tw-bg-gradient-to-r tw-hover:tw-from-hue-100 tw-hover:tw-to-transparent tw-text-md tw-absolute tw-right-0 tw-flex tw-h-9 tw-w-64 tw-transform tw-cursor-pointer tw-items-center tw-justify-end tw-bg-gradient-to-l tw-to-transparent tw-font-bold`}
        onClick={() => setShowViewAll(true)}
      >
        All Metrics
      </div>
      {data.map((item, index) => (
        <div
          key={index}
          className="tw-mr-5 tw-mt-1 tw-flex tw-flex-col sm:tw-mt-0 sm:tw-flex-row sm:tw-flex-wrap sm:tw-space-x-6"
        >
          <div
            className={`
              ${darkMode ? "tw-text-hue-200" : "tw-text-hue-500"}
                tw-mt-2 tw-flex tw-w-max tw-items-center tw-text-sm tw-font-sans
            `}
          >
            {item.icon && (
              <item.icon
                className={
                  darkMode
                    ? "tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-hue-200"
                    : "tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-hue-400"
                }
                aria-hidden="true"
              />
            )}
            <span>{item.label}</span>
          </div>
        </div>
      ))}
      <Modal
        visible={showViewAll}
        onHide={() => setShowViewAll(false)}
        title="All Data"
        size="sm"
        actions={[{ label: "Close", onClick: () => setShowViewAll(false) }]}
      >
        {data.map((item, index) => (
          <div
            key={index}
            className={
              darkMode
                ? "tw-mt-2 tw-flex tw-w-max  tw-items-center tw-text-sm tw-text-hue-200"
                : "tw-mt-2 tw-flex tw-w-max tw-items-center tw-text-sm tw-text-hue-500"
            }
          >
            {item.icon && (
              <item.icon
                className={
                  darkMode
                    ? "tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-hue-200"
                    : "tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-hue-400"
                }
                aria-hidden="true"
              />
            )}
            <span>{item.label}</span>
          </div>
        ))}
      </Modal>
      {}
    </div>
  );
}
