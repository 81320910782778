import { useState, useCallback, useEffect } from "react";
import OpenAI from "openai";
import { useDropzone } from "react-dropzone";
import { LuUpload, LuX } from "react-icons/lu";
import toast from "react-hot-toast";

export default function Transcribe() {
  /* ------------------------------ instantiation ----------------------------- */
  // creating a new instance of the openai class
  const openai = new OpenAI({
    apiKey: process.env.MIX_OPENAI_API_KEY,
    // we're using the browser version of the API. env protected anyway
    dangerouslyAllowBrowser: true,
  });

  /* ---------------------------------- state --------------------------------- */
  //  where we keep the uploaded file
  const [uploadedFile, setUploadedFile] = useState(false);
  //   where we keep the transcription once it's created
  const [transcription, setTranscription] = useState("");
  const [script, setScript] = useState("");
  const [summariseSelected = false, setSummariseSelected] = useState(false);
  const [scriptSelected = false, setScriptSelected] = useState(false);
  const [summary, setSummary] = useState("");

  /* -------------------------------- functions -------------------------------- */

  // send the audio file to openai for transcription
  async function transcribeAudio() {
    const transcription = await toast.promise(
      openai.audio.transcriptions.create({
        file: uploadedFile,
        model: "whisper-1",
        language: "en",
      }),
      {
        loading: "Listening and transcribing audio",
        success: (response) => {
          setTranscription(response.text);
          return "Audio transcribed successfully";
        },
        error: (error) => {
          console.error(error);
          return `Failed to transcribe audio - ${error.data.message}`;
        },
      },
    );
  }

  // drag and drop functionality
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setUploadedFile(acceptedFiles[0]);
  }, []);

  //  getting hook props
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    if (!transcription || transcription === "" || !scriptSelected) return;

    const script = toast.promise(
      openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "user",
            content: `Using this transcription ${transcription}, please convert it into a script of the two users that were in the call, can we seperate each line with a space please? Also if there is an IVR or anything that repeats too much, you can just ignore that.`,
          },
        ],
      }),
      {
        loading: "Generating script",
        success: (response) => {
          console.log(response);
          setScript(response.choices[0].message.content);
          return "Script generated successfully";
        },
        error: (error) => {
          console.error(error);
          return `Failed to generate script - ${error.data.message}`;
        },
      },
    );
  }, [transcription]);

  useEffect(() => {
    if (!transcription || transcription === "" || !summariseSelected) return;

    const script = toast.promise(
      openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "user",
            content: `Using this transcription ${transcription}, please summarise the conversation into a few sentences.`,
          },
        ],
      }),
      {
        loading: "Generating summary",
        success: (response) => {
          console.log(response);
          setSummary(response.choices[0].message.content);
          return "Summary generated successfully";
        },
        error: (error) => {
          console.error(error);
          return `Failed to generate summary - ${error.data.message}`;
        },
      },
    );
  }, [transcription]);

  return (
    <div
      className={`mt-4 tw-flex tw-flex-col tw-items-center tw-justify-center`}
    >
      <div className=" tw-mb-5 tw-w-full tw-rounded-md tw-bg-hue-50 tw-p-8 tw-shadow-md md:tw-w-1/2">
        <div>
          <h1 className="tw-text-3xl tw-font-bold tw-tracking-tight">
            Audio Transcription, Scripting, and Summarisation
          </h1>
          <p className="tw-text-muted-foreground tw-mt-2">
            Upload an audio file and we'll transcribe the content, generate a
            script, and provide a summary.
          </p>
        </div>

        {/* draggable area */}
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {
            <>
              <label className="tw-mt-5 tw-text-sm tw-text-gray-600">
                Upload an audio file
              </label>
              <div
                className={`tw-flex tw-h-32 tw-items-center tw-justify-center tw-rounded-lg tw-border-2 tw-border-dashed tw-p-5 tw-text-center tw-transition tw-duration-500 tw-ease-in-out ${isDragActive ? "tw-bg-gray-100 " : " tw-bg-hue-50 "} `}
              >
                {uploadedFile ? (
                  <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-rounded-md tw-border tw-border-indigo-300 tw-bg-indigo-200 tw-p-7">
                    {uploadedFile.name}
                    <LuX
                      onClick={() => setUploadedFile(null)}
                      className="tw-cursor-pointer"
                    />
                  </div>
                ) : isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-7 tw-text-gray-500">
                    <LuUpload className="tw-text-muted-foreground tw-mb-2 tw-h-12 tw-w-12" />
                    <span>Drag a file here, or click to select a file</span>
                    <span className="tw-text-xs">
                      MP3, WAV, or FLAC up to 100MB
                    </span>
                  </div>
                )}
              </div>
            </>
          }
        </div>

        <div className="tw-my-2 tw-flex tw-gap-2">
          <input
            type="checkbox"
            onChange={() => setSummariseSelected(!summariseSelected)}
          />
          <label className="tw-text-sm tw-text-gray-600">Summarise</label>
          <input
            type="checkbox"
            onChange={() => setScriptSelected(!scriptSelected)}
          />
          <label className="tw-text-sm tw-text-gray-600">Script</label>
        </div>

        <button
          className="tw-mt-1 tw-w-full tw-cursor-pointer tw-rounded-md tw-bg-blue-500 tw-p-2 tw-text-center tw-text-white tw-shadow-md tw-transition tw-duration-500 tw-ease-in-out hover:tw-bg-blue-600"
          onClick={() => {
            transcribeAudio();
          }}
        >
          Transcribe
        </button>
      </div>
      <div className="tw-mb-5 tw-w-full tw-rounded-md tw-bg-hue-50 tw-p-8 tw-shadow-md md:tw-w-1/2">
        <div className="tw-mb-5 tw-flex tw-items-center">
          <h3>Transcription</h3>
        </div>
        <p className="tw-mb-5 ">
          The transcription of the audio file will appear here once the
          transcription process has been completed.
        </p>
        <div className="tw-w-full tw-rounded-md tw-bg-gray-200 tw-p-10">
          {transcription ? transcription : "No transcription available"}
        </div>
      </div>
      {scriptSelected && (
        <div className="tw-mb-10 tw-w-full tw-rounded-md tw-bg-hue-50 tw-p-8 tw-shadow-md md:tw-w-1/2">
          <div className="tw-mb-5 tw-flex tw-items-center">
            <h3>Script</h3>
          </div>
          <p className="tw-mb-5 ">
            The script of the audio file will appear here once the transcription
            process has been completed.
          </p>
          <div className="tw-w-full tw-rounded-md tw-bg-gray-200 tw-p-10">
            {script
              ? script.split("\n").map((line, index, array) => (
                  <span key={index}>
                    {line}
                    {index < array.length - 1 ? <br /> : null}
                  </span>
                ))
              : "No script available"}
          </div>
        </div>
      )}
      {summariseSelected && (
        <div className="tw-mb-10 tw-w-full tw-rounded-md tw-bg-hue-50 tw-p-8 tw-shadow-md md:tw-w-1/2">
          <div className="tw-mb-5 tw-flex tw-items-center">
            <h3>Summary</h3>
          </div>
          <p className="tw-mb-5 ">
            The summary of the audio file will appear here once the
            transcription process has been completed.
          </p>
          <div className="tw-w-full tw-rounded-md tw-bg-gray-200 tw-p-10">
            {summary ? summary : "No summary available"}
          </div>
        </div>
      )}
    </div>
  );
}
