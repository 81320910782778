export default function DynamicLayout({ data }) {
  return (
    <>
      <div
        className={`tw-flex tw-flex-col lg:tw-flex-row tw-gap-${data[0].gap}`}
      >
        {data[0].cols.map((item, index) => {
          return (
            <div
              key={index}
              className={`lg:tw-w-${item.width}/12 tw-w-full tw-min-w-80`}
            >
              <div className={`tw-flex tw-flex-col tw-gap-${data[0].gap}`}>
                {item.content}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
