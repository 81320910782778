// External Imports
import { Badge } from "react-bootstrap"; // React badge component

// Internal Imports
import { getColorFromValue } from "../services/AutomaticColourPicker"; // handles allocation of badge colors
import chroma from "chroma-js"; // Manages on the fly adjustments to colors

export default function BadgeGroup({ data, labelKey, colorIDKey, keyKey }) {
  return (
    <>
      {/* check to see if data exists */}
      {data &&
        data.length > 0 &&
        data.map((dataItem) => {
          // Use color attached to item else use color id to generate color
          const color = dataItem.color
            ? dataItem.color
            : colorIDKey
              ? getColorFromValue(dataItem[colorIDKey])
              : null;
          return (
            <Badge
              className="me-1"
              key={dataItem[keyKey]}
              bg={color ? null : "primary"}
              style={{
                backgroundColor: color,
                color: color
                  ? chroma.contrast(color, "white") > 2
                    ? "white"
                    : "black"
                  : null,
                fontWeight: 400,
                padding: "5px 10px",
                fontSize: 11,
                borderRadius: 50,
                fontFamily: "Outfit",
                textTransform: "capitalize",
              }}
            >
              {dataItem[labelKey]}
            </Badge>
          );
        })}
    </>
  );
}
