/* ------------------------------ core imports ------------------------------ */
import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";

/* ---------------------------- internal imports ---------------------------- */

import ButtonGroup from "../../../components/buttons/ButtonGroup";
import AuthService from "../../../services/AuthService";
import APIClient from "../../../services/clients/APIClient";
import SingleAvailability from "./elements/SingleAvailability";

/* ---------------------------- external imports ---------------------------- */
import { LuPlus } from "react-icons/lu";
import toast from "react-hot-toast";
import { Col, Row } from "react-bootstrap";

// - styles -
const styles = {
  ruleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 5,
    width: "100%",
  },
};

// view for displaying a filter user connections
export default forwardRef((props, ref) => {
  // destruct props passed from parent
  const { filter, isEditor = true } = props;

  /* --------------------------------- state --------------------------------- */
  // user roles is an array that stores each role flor the user
  const [users, setUsers] = useState([]);
  // an array of all available users that could be added to the user roles
  const [profileUsers, setProfileUsers] = useState([]);
  // Holds the id of the next new availability rule
  const [nextNewAvailabilityId, setNextNewAvailabilityId] = useState(0);

  /* ---------------------------------- refs ---------------------------------- */
  // instantiate ref array to hold the availability refs
  const availabilityRefs = useRef([]);

  /* --------------------------------- effects -------------------------------- */
  // upon mounting, fetch the users in the profile
  useEffect(() => {
    fetchUsers();
  }, []);

  // when the potentially added users change, we want to render the user roles to be pre-populated
  useEffect(() => {
    let nextKey = nextNewAvailabilityId;

    // if filter exists update user from the filter
    if (filter) {
      setUsers(
        filter.users.map((user) => {
          // apply key to user
          user.key = nextKey;

          // Increment user so it is unique
          nextKey++;
          return user;
        }),
      );
    } else {
      // Else set users to empty array
      setUsers([]);

      // Add authed user
      addAuthedUser();
    }

    // Store the new key as a state for later use
    setNextNewAvailabilityId(nextKey);
  }, [filter]);

  // use imperative handle allows the parent to access functions from this component
  useImperativeHandle(ref, () => ({
    gAvailability,
  }));

  /* -------------------------------- functions ------------------------------- */
  // gets the rules from children as array of objects ready for backend submission
  function gAvailability() {
    // For each rules ref
    return availabilityRefs.current.map((availabilityRef) => {
      if (availabilityRef) {
        const values = availabilityRef.form().values;

        // Build rule object ready for backend submission
        if (values.user_id) {
          return {
            id: values.user_id.value,
            role_id: values.role_id?.value || 1,
          };
        }
      }
    });
  }

  // a function that fetches all users in the active profile
  async function fetchUsers() {
    await APIClient.get(
      `profile/${AuthService.currentUser.active_profile.id}/users`,
    )
      .then((response) => {
        setProfileUsers(response.data);
      })
      .catch((error) => {
        toast.error("There was an error fetching the users in this profile.");
      });
  }

  // Adds the authed user to the users list
  function addAuthedUser(role_id = 2) {
    setUsers([
      ...users,
      {
        ...AuthService.currentUser, // authed user details
        key: AuthService.currentUser.id, // key should be current user's id
        pivot: {
          role_id, // set role id
        },
      },
    ]);

    // Increment user so it is unique
    setNextNewAvailabilityId(nextNewAvailabilityId + 1);
  }

  // Adds a new user / availability rule to users list
  function nUser() {
    // add a new user to the filter
    setUsers([
      ...users,
      {
        // apply key to user
        key: nextNewAvailabilityId,
      },
    ]);

    // Increment user so it is unique
    setNextNewAvailabilityId(nextNewAvailabilityId + 1);
  }

  // Removes a user from our list of users
  function removeUser(keyToRemove) {
    setUsers(users.filter((user) => user.key != keyToRemove));
  }

  return (
    <>
      {users.length == 0 && (
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: 20,
            }}
          >
            <h4 style={{ color: "#3b3b3b", fontWeight: 600, marginTop: 20 }}>
              No Users
            </h4>
            <p style={{ color: "#3b3b3b" }}>Tip: Add a rule to get started</p>
          </div>
        </div>
      )}

      {users.length != 0 && (
        <div className="tw-flex tw-pb-1">
          <div className="tw-flex tw-w-full tw-items-center">
            <span className="00tw-px-2 tw-w-full tw-font-sans tw-text-sm tw-font-bold">
              User
            </span>
            <span className="tw-w-full tw-px-2 tw-font-sans tw-text-sm tw-font-bold">
              Role
            </span>
          </div>
          <div className="tw-min-w-12">
            <span className="tw-font-sans tw-text-sm tw-font-bold">Action</span>
          </div>
        </div>
      )}
      <Row>
        {users.map((user) => (
          <SingleAvailability
            disabled={!isEditor}
            key={user.key}
            user={user}
            profileUsers={profileUsers}
            removeUser={() => {
              removeUser(user.key);
            }}
            ref={(element) => (availabilityRefs.current[user.key] = element)}
          />
        ))}
      </Row>
      <div style={styles.ruleContainer}>
        <ButtonGroup
          actions={[
            {
              disabled: !isEditor,
              tooltip: "New User",
              icon: <LuPlus />,
              variant: "secondary",
              onClick: nUser,
              label: "New User",
              style: { marginTop: 20, marginBottom: 20 },
            },
          ]}
        />
      </div>
    </>
  );
});
