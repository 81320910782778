/* ---------------------------- internal imports ---------------------------- */

import NotFound from "../../lottie/not-found.json";
import ButtonGroup from "../components/buttons/ButtonGroup";

/* ---------------------------- external imports ---------------------------- */
import Lottie from "lottie-react";
import toast from "react-hot-toast";
import { LuRefreshCw, LuClipboardCopy, LuMailWarning } from "react-icons/lu";

// MyAccount.js
export default function ErrorFallback(props) {
  // destruct props passed from parent
  const {
    error, // The JS error object to display to the user
  } = props;

  return (
    <div className="tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center tw-bg-hue-100">
      <Lottie style={{ width: 300 }} animationData={NotFound} />
      <h1 className="tw-mb-4 tw-font-sans tw-text-6xl tw-font-bold">Uh oh!</h1>
      <p className="tw-mb-6 tw-w-1/2 tw-text-center tw-font-sans tw-text-lg">
        It looks like something has gone wrong! If you come across this message,
        we'd appreciate you submitting this as feedback, ideally with the
        following error message attached:
      </p>
      <div className="tw-max-w-screen-lg tw-rounded-md tw-bg-white tw-p-3 tw-text-center tw-shadow-md">
        <code>{error.message}</code>
      </div>
      <div className="tw-mt-7">
        <ButtonGroup
          shrinkAt={"sm"}
          actions={[
            {
              icon: LuRefreshCw,
              label: "Reload the page",
              onClick: () => window.location.reload(),
            },
            {
              icon: LuClipboardCopy,
              label: "Copy to Clipboard",
              onClick: () => {
                navigator.clipboard.writeText(error.message);
                toast.success("Copied to clipboard!");
              },
            },
            {
              icon: LuMailWarning,
              label: "Report this error",
              onClick: () =>
                window.open(
                  "https://forms.clickup.com/36163422/f/12fkuy-5288/TYBOTP7PU7EGSNCX4T",
                  "_blank",
                ),
            },
          ]}
        />
      </div>
    </div>
  );
}
