/* ------------------------------ core imports ------------------------------ */
import axios from "axios";
import CookieService from "./CookieService";
import APIClient from "./clients/APIClient";
import { isNull } from "lodash";
import User from "../models/User";

//If we change the expires at on the frontend remember remember to change on the backend token storage as well (AuthServiceProvider.php)
const NormalExpiresAt = 60 * 12; // short term login when user does not select Stay Signed in is 12 hours
const StaySignedInExpiresAt = 60 * 24 * 365; // Long term login when user selects Stay Signed in is a year

const includeProfiles = process.env.MIX_FEATURE_ORGANISATION_PROFILES == "true";

class AuthService {
  static currentUser = null;
  static userUpdateCallbacks = {};

  // Checks if the user is authenticated
  async isAuthenticated() {
    // Get current authed user
    const response = await APIClient.get("authed-user", {
      // Define which connections to the user we want to be included in the return data
      // If we are including profiles include profile data
      include: [
        "roles",
        "permissions",
        ...(includeProfiles ? ["profiles", "profileInvites"] : []),
      ],
      includeImages: [
        ...(includeProfiles ? ["profiles", "profileInvites"] : []),
      ], // Define which images we want to be included in the return
    });

    // Save current user info
    this.setCurrentUser(response);

    // Return response data
    return !isNull(response.data);
  }

  // Sets the current users active profile either based on their selected active profile or the first one in the list of connected profiles
  setCurrentUser(userData) {
    this.currentUser = new User(userData);

    // Run any callbacks provided
    Object.values(AuthService.userUpdateCallbacks).forEach((callback) => {
      callback(this.currentUser);
    });
  }

  // adds a new ref to the list of refs to re-render when the user updates key is used to avoid duplicates
  addUpdateUserCallback(key, callback) {
    // Add callback for given key (overwrites old callback if it exists)
    AuthService.userUpdateCallbacks[key] = callback;
  }

  // Updates the users active profile on the backend then updates the users details based on the change
  async changeActiveProfile(active_profile_id) {
    await APIClient.patch("authed-user", { active_profile_id });
    return this.isAuthenticated();
  }

  // Updates the users dark mode status stored on the backend
  async changeDarkMode(dark_mode) {
    await APIClient.patch("authed-user", { dark_mode });
    return this.isAuthenticated();
  }

  // Updates the users sider open status stored on the backend
  async changeSiderOpen(sider_open) {
    await APIClient.patch("authed-user", { sider_open });
    return this.isAuthenticated();
  }

  // Refreshes the user's authentication
  async refresh() {
    // currently we just use the isAuthenticated but this may change later
    return this.isAuthenticated();
  }

  // Logs the user out of the system by removing their access_token
  async logout() {
    try {
      const response = await APIClient.post("logout", {});
      CookieService.remove("access_token");
      AuthService.currentUser = null;
      window.location.replace("/login");
      return response.data;
    } catch (error) {
      console.error("Error", error);
      return false;
    }
  }

  // returns true or false if the user has the permission
  checkPermission(permission) {
    // Check if the current user has the correct permission
    return this.currentUser.checkPermission(permission);
  }

  // Uses the current user data to create initials
  getCurrentUsersInitials() {
    if (AuthService.currentUser != null) {
      return this.currentUser.getInitials();
    } else {
      return "";
    }
  }

  // resets a users password using password reset token
  async doPasswordResetReturn(credentials) {
    try {
      const response = await axios.post(
        "/api/forgot-password-return",
        credentials,
      );
      return response.data;
    } catch (error) {
      console.error("Error", error.response);
      return false;
    }
  }

  // verifies the a users email using a token and thier user ID
  async verifyEmail(id, credentials) {
    try {
      const response = await axios.post("/api/email/verify/" + id, credentials);
      return response;
    } catch (error) {
      console.error("Error", error.response);
      return false;
    }
  }

  storeAccessToken(accessToken, expiresAt = null) {
    // Get cookie expiry date
    if (expiresAt != null) expiresAt = new Date(expiresAt);

    // Set access token to new token (lasts until expiry date)
    CookieService.set("access_token", accessToken, {
      path: "/",
      expires: expiresAt,
    });

    // Attach new access token to Axios
    this.attachAuthTokenToAxios();

    return true;
  }

  // attaches the current access_token to Axios
  async attachAuthTokenToAxios(token = null) {
    // If no token is found attempt to get it from cookie storage
    if (token == null) token = CookieService.get("access_token");

    // if we have a token attach it to axios
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  }
}

export default new AuthService();
