/* ------------------------------ core imports ------------------------------ */
import { useState, useEffect, useContext } from "react";
import { evaluate } from "mathjs";
import { MentionsInput, Mention } from "react-mentions";

/* ---------------------------- external imports ---------------------------- */
import { Form } from "react-bootstrap";

/* ---------------------------- internal imports ---------------------------- */

import { sanitiseObject } from "../../../services/data-formatting/objects";
import { DarkMode } from "../../App";

// Basic Form Template including validation
export default function FormulaBuilder(props) {
  // Destruct props object into constants
  const {
    name,
    label,
    type,
    placeholder,
    value,
    onChange,
    onBlur,
    isInvalid,
    feedback,
    disabled,
    options,
  } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */
  const [formulaVariableOptions, setFormulaVariableOptions] = useState([]);
  const [formulaVariables, setFormulaVariables] = useState([]);
  const [formulaFeedbackMessage, setFormulaFeedbackMessage] = useState(null);
  const [formula, setFormula] = useState(""); // Current formula string (Not the same as formula shown in input)

  /* --------------------------------- effects -------------------------------- */
  // Update variables available within the formula
  useEffect(() => {
    if (options) {
      setFormulaVariableOptions(
        options.map((options) => options.replace(/ /g, "")),
      );
      setFormulaVariables(
        options.map((option) => `_${option.replace(/ /g, "")}_`),
      );
    } else {
      setFormulaVariableOptions([]);
      setFormulaVariables([]);
    }
  }, [options]);

  /* -------------------------------- functions ------------------------------- */
  // handles when a the value is changed within the input
  function onFormulaChange(e, newValue, newPlainTextValue) {
    // Get formula from on change event
    setFormula(newValue.trim());

    // If on change has been set then run on change passing in formula
    if (onChange) onChange(newValue, e);

    // If we have a formula feedback message we should attempt to update it
    if (formulaFeedbackMessage) {
      // Update feedback message after evaluation formula
      setFormulaFeedbackMessage(evaluateFormula(newValue));
    }
  }

  // Handles when the user stops editing the input
  function onFormulaBlur(e) {
    // Evaluate current formula updating feedback message
    setFormulaFeedbackMessage(evaluateFormula(formula));

    // Run parents onBlur if it was passed in
    if (onBlur) onBlur(e);
  }

  function evaluateFormula(formula) {
    if (!Array.isArray(formulaVariables)) {
      throw "formulaVariables must be an array";
    } else {
      // Create formula variables with values as required for evaluate()
      let formulaVariablesWithValues = {};
      formulaVariables.forEach((formulaVariable) => {
        // Set formula variable to 1 to allow evaluate to function
        formulaVariablesWithValues[formulaVariable] = 1;
      });

      try {
        evaluate(formula, formulaVariablesWithValues);
        return null;
      } catch (error) {
        return `Invalid Formula: ${error.message}.`;
      }
    }
  }

  /* --------------------------- pre jsx computation -------------------------- */
  // sanitise props for the form control by removing any excluded keys
  const sanitisedProps = sanitiseObject({ ...props }, [
    "initialValue",
    "colSize",
    "isInvalid",
  ]);

  // - jsx -
  return (
    <Form.Group controlId={name} key={name}>
      {label && (
        <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
          {label}
        </Form.Label>
      )}
      <MentionsInput
        {...sanitisedProps}
        onChange={onFormulaChange}
        onBlur={onFormulaBlur}
        className={`mentions ${formulaFeedbackMessage || isInvalid ? " is-invalid" : ""}`}
        singleLine={true}
        allowSuggestionsAboveCursor={true}
      >
        <Mention
          trigger={/(?:^|)(\(([^\s\(]*))$/} // Use ( to trigger variable
          markup={`___display___`}
          data={formulaVariableOptions.map((formulaVariable, key) => {
            return { id: key, display: formulaVariable };
          })}
          displayTransform={(id, display) => `(${display})`}
        />
      </MentionsInput>

      <Form.Control.Feedback
        type="invalid"
        style={{
          display: `${formulaFeedbackMessage || isInvalid ? "block" : "none"}`,
        }}
      >
        {formulaFeedbackMessage || feedback}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
