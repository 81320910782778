// Internal Imports
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import APIClient from "../../../services/clients/APIClient";

// Launches a modal that can be used to change the selected users password
export default function RemoveUserModal({ visible, onHide, onSuccess, user }) {
  // Removes a user from the system based on the current selected user
  function deleteUser() {
    APIClient.delete(`user/${user.id}`).then(() => {
      onSuccess();
      onHide();
    });
  }

  return (
    <ConfirmationModal
      title={`Remove ${user.forename || ""}?`}
      message={`Are you sure you want to remove ${user.forename || ""} ${user.surname || ""} from the system?`}
      actions={[
        { label: "Cancel", variant: "secondary", onClick: onHide },
        { label: "Confirm", variant: "danger", onClick: () => deleteUser() },
      ]}
      visible={visible}
      onHide={onHide}
    />
  );
}
