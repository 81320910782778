/* ---------------------------- external imports ---------------------------- */
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import styled from "styled-components";

/* ---------------------------- internal imports ---------------------------- */

import FormSelect from "../forms/inputs/FormSelect";

// - styles components -
export const Container = styled.div`
  border-radius: 5px;
  background: #0d6efd;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  margin-right: 0.5rem;
`;

export default function OngoingRefresh(props) {
  // destruct props passed from parent
  const { autoRefreshRate, setAutoRefreshRate } = props;

  // styles
  const styles = {
    select: {
      backgroundColor: "transparent",
      color: "white",
      border: "none",
      outline: "none",
      fontSize: "16px",
      cursor: "pointer",
      fontFamily: "Outfit, sans-serif",
      fontWeight: 300,
    },
  };

  // when the form is changed, update the refresh state
  const handleChange = ({ value }) => {
    if (value == false) {
      // update refresh to false
      setAutoRefreshRate(false);

      // launch toast to let the user know auto refresh is disabled
      toast.success(`Auto Refresh Disabled`);
    } else {
      // update refresh to selected value
      setAutoRefreshRate(value);

      // launch toast to let the user know auto refresh is disabled
      toast.success(`Auto Refresh set to ${value} seconds`);
    }
  };

  return (
    <FormSelect
      name="auto-refresh"
      defaultValue={
        autoRefreshRate
          ? { value: autoRefreshRate, label: `${autoRefreshRate} Seconds` }
          : { value: false, label: "Disabled" }
      }
      style={styles.select}
      onChange={handleChange}
      aria-label="Refresh rate"
      className="tw-mr-3"
      options={[
        { value: false, label: "Disabled" },
        { value: "10", label: "10 Seconds" },
        { value: "30", label: "30 Seconds" },
        { value: "60", label: "60 Seconds" },
      ]}
    />
  );
}
