import { useContext, useState, useRef, useEffect } from "react";
import { DarkMode } from "../../components/App";
import * as Yup from "yup";
// import { Form } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import Form from "../../components/forms/Form";

export default function Caller() {
  const { darkMode } = useContext(DarkMode);

  const headers = {
    Authorization:
      "sk-wy28jd4ddkbo7cfuc3ewkbbsp35cwffoa8e015iyy3tro4bla7toj0cn8z6c3w5s69",
  };

  const [phoneNumber, setPhoneNumber] = useState();
  const [model, setModel] = useState("enhanced");
  const [transferNumber, setTransferNumber] = useState("01913371830");
  const [maxDuration, setMaxDuration] = useState(12);
  const [pathway, setPathway] = useState();

  // default voice should be a710fd26-0ed7-48e8-86b3-0d4e52d4f500
  // I prefer this one 13843c96-ab9e-4938-baf3-ad53fcee541d

  // 07549051979

  const data = {
    // phone_number: phoneNumber,
    // from: null,
    // model: model,
    language: "en",
    voice: "13843c96-ab9e-4938-baf3-ad53fcee541d",
    // voice_settings: {},
    local_dialing: true,
    // max_duration: maxDuration,
    answered_by_enabled: true,
    wait_for_greeting: true,
    record: true,
    amd: false,
    interruption_threshold: 110,
    timezone: "BST",
    temperature: 0.8,
    // transfer_list: { transferNumber },
    // metadata: {},
    // pronunciation_guide: [],
    start_time: null,
    // request_data: {},
    // webhook: null,
    // pathway_id: activePathway,
  };

  const formRef = useRef();

  function fetchPathways() {
    axios
      .get("https://api.bland.ai/v1/convo_pathway", { headers })
      .then((response) => {
        console.log(response.data);
        setPathway(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchPathways();
  }, []);

  return (
    <div
      className={`mt-4 tw-flex tw-flex-col tw-items-center tw-justify-center`}
    >
      <div className="tw-mb105 tw-mb-20 tw-w-full tw-rounded-md tw-bg-hue-50 tw-p-8 tw-shadow-md md:tw-w-1/2">
        <div className="tw-mb-5 tw-flex tw-items-center">
          <h3>Spark Caller</h3>
          <span className="tw--mt-2 tw-ml-2 tw-rounded-md tw-bg-yellow-500 tw-px-2 tw-py-1 tw-text-sm tw-text-black">
            Experimental
          </span>
        </div>
        <p className="tw-mb-5">
          Welcome to Spark AI, please enter the phone number and task you would
          like to perform. Please note that pressing the call button will
          initiate a call to the provided phone number using AI, and this
          process will not be cancellable.
        </p>

        <Form
          id="call-form"
          ref={formRef}
          onSubmit={(dt) => {
            //  override the phone_number from the dt object and convert it to a string
            const mergedData = { ...data, ...dt };
            console.log(mergedData);
            axios.post("https://api.bland.ai/v1/calls", mergedData, {
              headers,
            });
          }}
          customHeaders={headers}
          inputData={[
            {
              name: "phone_number",
              label: "Phone Number to Call",
              type: "text",
              initialValue: phoneNumber,
              validation: Yup.number().required("Phone number is required"),
            },
            {
              name: "pathway_id",
              label: "Pathway",
              type: "select",
              options: pathway
                ? pathway.map((p) => {
                    return { value: p.id, label: p.name };
                  })
                : [],
            },
            {
              name: "task",
              label: "Task",
              type: "text",
              elementstyle: { height: "200px" },
              initialValue:
                "You are Alex, representing Unify Agency, a company dedicated to connecting businesses with top-tier digital agencies. Unify Agency partners with a diverse range of digital agencies that specialize in various services including web development, digital marketing, search engine optimization (SEO), and more. Your objective is to engage potential customers and get them interested in leveraging the expertise and services of Unify Agency's business partners. You aim to highlight the benefits of working with these specialized digital agencies and how they can help businesses enhance their online presence, improve their digital strategies, and achieve their business goals. During the call, focus on understanding the potential customer's current digital needs and challenges. Use this information to tailor your pitch, emphasising how Unify Agency's partners can provide customised solutions that align with their specific requirements. Your goal is to set up a follow-up meeting or a consultation with one of Unify Agency's partner agencies to discuss potential collaborations in more detail.",
              subtype: "textarea",
              validation: Yup.string().required("Task is required"),
            },
            {
              name: "model",
              label: "Model",
              type: "select",
              options: [
                { value: "base", label: "Base" },
                { value: "enhanced", label: "Enhanced" },
                { value: "turbo", label: "Turbo" },
              ],
              initialValue: { value: "enhanced", label: "Enhanced" },
              validation: Yup.mixed().required("Model is required"),
            },
            {
              name: "transfer_phone_number",
              label: "Transfer Number",
              type: "text",
              initialValue: transferNumber,
              validation: Yup.string()
                .required("Transfer number is required")
                .max(255),
            },
            {
              name: "max_duration",
              label: "Max Duration (mins)",
              type: "number",
              initialValue: maxDuration,
              validation: Yup.number()
                .required("Max duration is required")
                .max(255),
            },
          ]}
          onSuccess={(response) => {
            console.log(response);
            toast.success(`Call Initiated - ${response.data.message}`);
          }}
          onError={(error) => {
            console.log(error);
            toast.error(`Call Failed - ${error.message}`);
          }}
        />

        <button
          className="tw-mt-5 tw-w-full tw-cursor-pointer tw-rounded-md tw-bg-blue-500 tw-p-2 tw-text-center tw-text-white tw-shadow-md tw-transition tw-duration-500 tw-ease-in-out hover:tw-bg-blue-600"
          onClick={(event) => {
            event.preventDefault();
            formRef.current.form().submitForm();
          }}
        >
          Call Customer
        </button>
      </div>
    </div>
  );
}
