import { useMemo } from "react";

import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Image,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import APIClient from "../services/clients/APIClient";

// Dashboard.js
export default function EmailVerifyNotice() {
  // Ger parameters from URL
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [email, setEmail] = useState("");
  const [messageVisible, setMessageVisible] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [messageType, setMessageType] = useState("error");

  useEffect(() => {
    if (params.get("email") !== null) {
      setEmail(params.get("email"));
    }
  }, []);

  const handleChange = async (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setEmail(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    APIClient.post("email/verify/resend", { email })
      .then((response) => {
        setMessageVisible(true);
        setMessageText("Verification Email Sent, Please check your inbox");
        setMessageType("success");
      })
      .catch((error) => {
        if (error.response.data != null && error.response.data.errors != null) {
          setMessageVisible(true);
          setMessageText(error.response.data.errors[0]);
          setMessageType("danger");
        } else if (error.response.data.message != null) {
          setMessageVisible(true);
          setMessageText(error.response.data.message);
          setMessageType("danger");
        } else {
          setMessageVisible(true);
          setMessageText("Failed to Send Verification Email");
          setMessageType("danger");
        }
      });
  };

  return (
    <>
      <div className="unAuthBackground">
        <div className="unAuthUtilityContainer">
          <Container className="p-5">
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2 className="pb-3 text-center">Verify your email</h2>
                <Form style={{ textAlign: "center", width: 500 }}>
                  <Form.Text>
                    You need to verify your email before you can access the
                    system. You can resend the verification email by pressing
                    below below. Make sure to check your junk folder!
                  </Form.Text>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      className="mt-4 mb-2"
                      type="email"
                      name="username"
                      placeholder="Enter email"
                      onChange={handleChange}
                      value={email}
                    />
                  </Form.Group>
                  <div className="text-end"></div>
                  {messageVisible ? (
                    <Alert
                      dismissible
                      variant={messageType}
                      onClose={() => {
                        setMessageVisible(false);
                      }}
                    >
                      {messageText}
                    </Alert>
                  ) : null}
                  <Button
                    type="button"
                    className="btn w-100 mt-3 themeColour"
                    onClick={handleSubmit}
                  >
                    Send email
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
