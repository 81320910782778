/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

//external imports
import "react-step-progress-bar/styles.css";

// Internal Imports
// import { FocusSafeZone, FocusContainer, FocusHolder } from '../../styles/focus.style'
import { DarkMode } from "../components/App";

// Houses the core structure for most pages in the system
export default function FocusStructure({ children }) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <div className={darkMode ? "focus-safe-zone_dark" : "focus-safe-zone"}>
      <div className={darkMode ? "focus-container_dark" : "focus-container"}>
        <div className={darkMode ? "focus-holder_dark" : "focus-holder"}>
          {children}
        </div>
      </div>
    </div>
  );
}
