/* ------------------------------ core imports ------------------------------ */
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

/* ---------------------------- internal imports ---------------------------- */

import AuthService from "../../services/AuthService";
import ButtonGroup from "../../components/buttons/ButtonGroup";
import {
  InvitationAnnouncement,
  InvitationParagraph,
} from "../../../styles/ProfileDetail.style";
import ProfileWidget from "../../components/widgets/ProfileWidget";
import APIClient from "../../services/clients/APIClient";

/* ---------------------------- external imports ---------------------------- */
import { Container, Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";

export default function ProfileInvite() {
  /* --------------------------------- state --------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [profileInvite, setProfileInvite] = useState(null);
  const [profilePicture, _setProfilePicture] = useState(null);

  /* --------------------------------- effects -------------------------------- */
  // Runs when the id changed taken from the URL (runs on page load)
  useEffect(() => {
    // Get Profile Data
    fetchProfileInvite();
  }, [id]);

  useEffect(() => {
    if (profileInvite) setProfilePicture(profileInvite.picture); // set profile picture from profile invite if not null
  }, [profileInvite]);

  useEffect(() => {
    // If the page is done loading and there is no profile invite the user should not be here
    if (!isLoading && !profileInvite) {
      // if the user is not connected to the profile and is not invited to the profile navigate them away from the page
      navigate("/profile-not-found");
    }
  }, [isLoading]);

  /* -------------------------------- functions ------------------------------- */
  // Sets the profile invite state using the current users profile invites along with the ID from the URL
  function fetchProfileInvite() {
    setProfileInvite(
      AuthService.currentUser.profile_invites.find(
        (profile) => profile.id == id,
      ),
    );
    setIsLoading(false); // Once Profile invite is set we are finished Loading
  }

  // Uses the passed in profile to create a profile picture that can be used within an img component/element
  function setProfilePicture(profilePicture) {
    if (profilePicture) {
      if (profilePicture.size) {
        // if a profile has a size property, it is a file
        // converting to base64
        const reader = new FileReader();
        reader.readAsDataURL(profilePicture);
        reader.onloadend = () => {
          _setProfilePicture(reader.result);
        };
      } else {
        // if a profile does not have a size property, it is a base64 string
        // checking to see if the profile picture object has loaded in yet
        if (Object.keys(profilePicture).length != 0) {
          //profile picture has loaded in, displaying to UI
          _setProfilePicture(profilePicture);
        }
      }
    } else {
      // If we have no picture set the profile picture to null
      _setProfilePicture(null);
    }
  }

  // Sends a request to the backend to accept the profile invitation, then the profile data is refreshed
  async function acceptProfileInvite() {
    // Submit request using toast to show response
    await APIClient.patch(`profile/${id}/accept`)
      .then(async (response) => {
        toast.success(`Profile invitation accepted - ${response.message}`);

        // update active profile to the new profile they have joined
        await AuthService.changeActiveProfile(id);

        // if the user manages the new profile go to profile detail
        if (AuthService.currentUser.checkPermission("manage active profile")) {
          navigate(`/profile/${id}`);
        } else {
          // else go to dashboard
          navigate("/");
        }
      })
      .catch(async (error) => {
        toast.error(
          `Failed to accept profile invitation - ${error.data.message}`,
        );

        // Refresh user auth
        await AuthService.isAuthenticated();
      });

    // fetch updated profile
    fetchProfile();
  }

  // Sends a request to the backend to decline and remove a profile invitation.
  async function declineProfileInvite() {
    // Submit request using toast to show response
    await APIClient.delete(`profile/${id}/invite`)
      .then((response) => {
        toast.success(`Profile invites declined - ${response.message}`);
        navigate("/");
      })
      .catch((error) => {
        toast.error(
          `Failed to accept profile invitation - ${error.data.message}`,
        );
      });

    // Refresh user auth
    await AuthService.isAuthenticated();

    // fetch updated profile invite
    fetchProfileInvite();
  }

  /* --------------------------- pre jsx computation -------------------------- */
  const { id } = useParams(); // get the profile id from the url
  const navigate = useNavigate();

  /* --------------------------------- markup --------------------------------- */

  if (isLoading) {
    return <h1>Loading...</h1>;
  } else if (profileInvite) {
    return (
      <ProfileWidget image={profilePicture}>
        <span className="tw-w-full tw-px-4 tw-text-center">
          <InvitationAnnouncement>
            You have an invite to join {profileInvite.name}
          </InvitationAnnouncement>
          <InvitationParagraph>
            If you wish to join of {profileInvite.name} you can accept the
            request below. You may also decline if you think this is likely a
            mistake.
          </InvitationParagraph>
        </span>
        <ButtonGroup
          className="tw-content-center"
          actions={[
            {
              label: "Accept",
              key: "accept",
              onClick: acceptProfileInvite,
              size: "lg",
              className: "tw-text-xl tw-mx-4",
            },
            {
              variant: "danger",
              label: "Decline",
              key: "decline",
              onClick: declineProfileInvite,
              size: "lg",
              className: "tw-text-xl tw-mx-4",
            },
          ]}
        />
      </ProfileWidget>
    );
  } else {
    return (
      <div className="unAuthUtilityContainer mx-5">
        <Container className="p-5">
          <Row className="justify-content-md-center">
            <Col>
              <h2 className="pb-2 text-center">Profile Not Found</h2>
              <p className="pb-3 text-center">
                You do not have permission to see the profile you are are trying
                to access or it may have been removed. If you need access to a
                profile you are currently unable to see please contact a profile
                manager.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
