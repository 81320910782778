/* ---------------------------- internal imports ---------------------------- */

import Form from "../../components/forms/Form";
import AuthService from "../../services/AuthService";

/* ---------------------------- external imports ---------------------------- */
import * as Yup from "yup";
import toast from "react-hot-toast";

// Description of what the component does
export default function Component(props) {
  // destruct props passed from parent
  const {
    formRef, // react ref to be used for the form
    initialUser, // initial user data.
    hash, // The hash received from the email verification email
    expires,
    signature,
  } = props;

  /* -------------------------------- functions ------------------------------- */
  // Runs when the form submit is successful
  function onSuccess(data) {
    // launch success toast
    toast.success("Logged in, welcome to Spark ⚡️");

    AuthService.storeAccessToken(data.access_token, data.token_expires_at);
  }

  /* --------------------------- pre jsx computation -------------------------- */

  /* --------------------------------- markup --------------------------------- */
  return (
    <>
      <p>
        Please fill out the form below, we need to have this information to give
        the best online experience.
      </p>
      <Form
        style={{ margin: "auto", paddingTop: 20 }}
        submitRoute={`on-boarding/register/${initialUser.id}`}
        submitMethod="post"
        submitData={{ hash, expires, signature }}
        onSuccess={onSuccess}
        ref={formRef}
        id="basic-form"
        inputData={[
          {
            name: "forename",
            label: "Forename",
            initialValue: initialUser?.forename || "",
            placeholder: "Enter Forename",
            type: "text",
            validation: Yup.string().required("Forename is required").max(255),
          },
          {
            name: "surname",
            label: "Surname",
            initialValue: initialUser?.surname || "",
            placeholder: "Enter Surname",
            type: "text",
            validation: Yup.string().required("Surname is required").max(255),
          },
          {
            name: "password",
            label: "Password",
            placeholder: "Enter Password",
            type: "password",
            validation: Yup.string()
              .required("Password is required")
              .min(8, "Password must be at least 8 characters")
              .max(255),
          },
          {
            name: "password_confirmation",
            label: "Confirm Password",
            placeholder: "Confirm Password",
            type: "password",
            validation: Yup.string()
              .required("Password confirmation is required")
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .max(255),
          },
        ]}
      />
    </>
  );
}
