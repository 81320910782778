// External Imports
import chroma from "chroma-js";

// Internal Imports
import { getFirstLetters } from "../services/data-formatting/strings";
import { getColorFromValue } from "../services/AutomaticColourPicker";

// Component used to create a profile picture for a given user and size
export default function ProfilePicture({ forename, surname, id }) {
  // setting the color to the id colour equvilient from a function we have passed into this component
  const color = getColorFromValue(id.values);

  return (
    <div
      className="data-table-profile-picture-container-small "
      style={{ backgroundColor: color }}
    >
      <span
        className="data-table-profile-picture-text-small"
        style={{
          color: chroma.contrast(color, "white") > 2 ? "white" : "black",
        }}
      >
        {getFirstLetters(forename.values + " " + surname.values)}
      </span>
    </div>
  );
}
