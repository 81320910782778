import Client from "./Client";

// managed client interactions with our bespoke backend api
export default class APIClient extends Client {
  static APIURL = "/api/";

  // runs a get request using axios
  static async get(route, data = {}, headers = {}) {
    return await super
      .get(`${this.APIURL}${route}`, data, headers)
      .catch(this.errorHandler);
  }

  //runs a get request using axios and downloads the response
  static async download(route, fileName, data) {
    return super
      .download(`${this.APIURL}${route}`, fileName, data)
      .catch(this.errorHandler);
  }

  // runs a post request using axios
  static async post(route, data, asFormData = false, headers = {}) {
    return super
      .post(`${this.APIURL}${route}`, data, asFormData, headers)
      .catch(this.errorHandler);
  }

  // runs a delete request using axios
  static async delete(route, data, headers = {}) {
    return super
      .delete(`${this.APIURL}${route}`, data, headers)
      .catch(this.errorHandler);
  }

  // runs a patch request using axios
  static async patch(route, data, asFormData = false, headers = {}) {
    return super
      .patch(`${this.APIURL}${route}`, data, asFormData, headers)
      .catch(this.errorHandler);
  }

  // handles errors from Client.js
  static errorHandler(error) {
    console.log({ error });
    switch (error.status) {
      case 401: // Unauthorized
        // check if the data contains a redirect url
        if (error.data.redirect != null) {
          if (!APIClient.matchesCurrentRoute(error.data.redirect))
            window.location.replace(error.data.redirect);
        } else {
          if (!APIClient.matchesCurrentRoute("/login"))
            window.location.replace("/login");
        }

        // throw error to be handled by caller
        throw error;
      case 403: // Forbidden
        // check if the data contains a redirect url
        if (error.data.redirect != null) {
          if (!APIClient.matchesCurrentRoute(error.data.redirect))
            window.location.replace(error.data.redirect);
        }
        // else return the error
        throw error;
      default:
        // throw error to be handled by caller
        throw error;
    }
  }

  // Checks if the passed in value matches the current route on the site
  static matchesCurrentRoute(route) {
    return route == window.location.pathname;
  }
}
