/* ------------------------------ core imports ------------------------------ */
import { useContext, useState, Fragment, useEffect } from "react";

/* ---------------------------- internal imports ---------------------------- */

import ButtonGroup from "../buttons/ButtonGroup";
import { DarkMode } from "../App";

/* ---------------------------- external imports ---------------------------- */
import { Dialog, Transition } from "@headlessui/react";
import { LuX } from "react-icons/lu";

// Basic Modal Template using Bootstrap modal
export default function Modal(props) {
  // destruct props passed from parent
  const {
    visible = false,
    onHide,
    title,
    children,
    actions,
    onEnter,
    size = "2xl",
    fullscreen,
  } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- effects -------------------------------- */
  // this effect waits for the modal to be visible and then adds the overflow-y-auto class to the modal - this is to prevent the page from automatically scrolling down as the data popul;ates the modal
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        let elements = document.querySelectorAll(
          ".spark-modal_light, .spark-modal_dark",
        );
        elements.forEach((element) => {
          element.classList.add("tw-overflow-y-auto");
        });
      }, 100);
    }
  }, [visible]);

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="tw-relative tw-z-10" onClose={onHide}>
        {/* overlay */}
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-hue-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        {/* outer modal holder */}
        <div
          className={`${darkMode ? "spark-modal_dark" : "spark-modal_light"}`}
        >
          {/* inner modal holder */}
          <div className="inner">
            {/* handle the transition animations */}
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-Translate-y-4 sm:tw-Translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-Translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-Translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-Translate-y-4 sm:tw-Translate-y-0 sm:tw-scale-95"
            >
              {/* modal content */}
              <Dialog.Panel
                className={`${fullscreen ? `tw-max-w-screen tw-h-screen` : `${size ? `tw-max-w-${size}` : `tw-max-w-sm`} tw-my-10`} panel`}
              >
                <div
                  className={`${fullscreen ? "tw-overflow-y-auto" : ""} body`}
                >
                  <div className="sm:tw-flex sm:tw-items-center">
                    <div className="body-inner">
                      {/* title */}
                      <Dialog.Title as="h2" className="dialog-title">
                        {title}
                        <LuX className="close-icon" onClick={onHide} />
                      </Dialog.Title>
                      <div className="tw-mt-2">{children}</div>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <ButtonGroup actions={actions} shrinkAt="md" />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
