/* ------------------------------ core imports ------------------------------ */
import { useEffect, useContext } from "react";

// Internal Imports
import Sider from "../components/Sider";
import Header from "../components/Header";
import { DarkMode } from "../../js/components/App";
import { LuBug, LuSmile } from "react-icons/lu";

// Houses the core structure for most pages in the system
export default function CoreStructure({ children, title, subtitle }) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  useEffect(() => {
    document.title = title + " | " + "Spark Portal";
  }, [title]);

  return (
    <div className="tw-flex tw-h-screen tw-w-full" style={{ overflow: "auto" }}>
      {/* Loading the Sider */}
      <Sider darkMode={darkMode} />

      {/* The feedback buttons */}
      <a
        className="feedback-container tw-bottom-14 tw-z-50 tw-bg-primary_accent"
        target="_blank"
        href="https://forms.clickup.com/36163422/f/12fkuy-5428/9ZZ1FG3AF62T4UZWRJ"
      >
        <div className="feedback-icon-holder">
          <LuSmile color="#565656" />
        </div>
        <span className="feedback-text">Suggest a change</span>
      </a>

      <a
        className="feedback-container tw-bottom-3 tw-z-50 tw-bg-secondary_accent"
        target="_blank"
        href="https://forms.clickup.com/36163422/f/12fkuy-5288/TYBOTP7PU7EGSNCX4T?What%20Installation%20did%20you%20find%20this%20issue%20on%3F=xxxxx"
      >
        <div className="feedback-icon-holder">
          <LuBug color="#565656" />
        </div>
        <span className="feedback-text">Report an issue</span>
      </a>

      <section
        id="main-content"
        className={`tw-h-full tw-w-full tw-overflow-auto ${darkMode ? " tw-bg-hue-700 tw-text-white" : " tw-bg-white"}`}
      >
        {/* Header container - holds structure of titles with the profile manager */}
        <div
          className={`${darkMode ? "tw-border-hue-950 tw-bg-hue-800" : "tw-border-hue-200 tw-bg-hue-100"} tw-flex tw-items-center tw-justify-between tw-border-b tw-px-6 tw-py-4`}
        >
          {/* Title container */}
          <h1>{title}</h1>
          <Header />
        </div>
        <div className="tw-relative tw-w-full tw-p-6">{children}</div>
      </section>
    </div>
  );
}
