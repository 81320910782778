/* ------------------------------ core imports ------------------------------ */
import { useState } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs

// Internal Imports
import Modal from "../../../components/modals/Modal";
import Form from "../../../components/forms/Form";
import LoadingSpinner from "../../../components/LoadingSpinner";
import APIClient from "../../../services/clients/APIClient";

export default function ViewExistingProfileModal({
  onHide,
  visible,
  onSuccess: pOnSuccess,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    "https://via.placeholder.com/150",
  );

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      title={"Profile Viewer"}
      actions={[
        {
          label: "Save Changes",
          variant: "primary",
          key: "save",
          type: "submit",
          form: "add-profile   ",
          disabled: isSubmitting,
        },
        {
          label: "Remove Profile",
          variant: "danger",
          key: "save",
          type: "submit",
          form: "add-profile   ",
          disabled: isSubmitting,
        },
      ]}
    >
      <LoadingSpinner isActive={isLoading} />
      {/* a placeholder image */}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignSelf: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          className="text-center w-50"
          src={profilePicture}
          alt="profile image"
          style={{ maxHeight: 300, objectFit: "contain" }}
        />
      </div>

      <Form
        submitRoute="user"
        submitMethod="post"
        // onSuccess={onSuccess}
        // setIsSubmitting={setIsSubmitting}
        id="add-user"
        inputData={[
          {
            name: "name",
            label: "Name",
            placeholder: "Enter name",
            type: "text",
            validation: Yup.string().required("A name is required").max(255),
          },
          {
            name: "image",
            label: "image",
            type: "image",
            onChange: setProfilePicture,
          },
        ]}
      />
    </Modal>
  );
}
