/* ------------------------------ core imports ------------------------------ */
import { useState } from "react";

/* ---------------------------- external imports ---------------------------- */
import * as Yup from "yup"; // Used to define the validation for our inputs

// Internal Imports
import Modal from "../../../components/modals/Modal";
import Form from "../../../components/forms/Form";
import LoadingSpinner from "../../../components/LoadingSpinner";
import placeholder from "../../../../img/placeholder.png";

export default function AddNewProfileModal({ onHide, visible, onSuccess }) {
  /* --------------------------------- state --------------------------------- */
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [profilePicture, setProfilePicture] = useState(placeholder);

  /* -------------------------------- functions ------------------------------- */
  function onEnter() {
    // change profile picture back to placeholder
    setProfilePicture(placeholder);
  }

  // this function converts the inputted file to base64
  function parseImage(data) {
    // if the data is a file we can update the image
    if (data instanceof File) {
      // convert the image to base64
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
    } else {
      // else convert the image to the placeholder
      setProfilePicture(placeholder);
    }
  }

  /* --------------------------------- markup --------------------------------- */
  return (
    <Modal
      visible={visible}
      onHide={onHide}
      onEnter={onEnter}
      title={"Add a new profile"}
      actions={[
        {
          label: "Add Profile",
          variant: "primary",
          key: "save",
          type: "submit",
          form: "add-profile",
          disabled: isSubmitting,
        },
      ]}
    >
      <LoadingSpinner isActive={isLoading} />

      <div
        style={{
          width: "100%",
          display: "flex",
          alignSelf: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          id="profile-image"
          className="text-center w-50"
          src={profilePicture}
          alt="profile image"
          style={{ maxHeight: 300, objectFit: "contain" }}
        />
      </div>

      <Form
        submitRoute="profile"
        submitMethod="post"
        onSuccess={onSuccess}
        setIsSubmitting={setIsSubmitting}
        id="add-profile"
        resetOnSubmit={false}
        inputData={[
          {
            name: "name",
            label: "Name",
            placeholder: "Enter name",
            type: "text",
            validation: Yup.string().required("A name is required").max(255),
          },
          {
            name: "picture",
            label: "Picture",
            type: "image",
            limit: 1,
            onChange: parseImage,
          },
        ]}
      />
    </Modal>
  );
}
