/* ------------------------------ core imports ------------------------------ */
import { useEffect, useRef } from "react";

/* ---------------------------- external imports ---------------------------- */
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";

// Basic Form Template including validation
export default function FormCheckbox(props) {
  // destruct props passed from parent
  const {
    name,
    id = name, // (Optional) ID of the checkbox input id defaults to name if one is not selected
    label,
    tooltip,
    value,
    onChange,
    onBlur,
    isInvalid,
    feedback,
    style,
    isDisabled,
  } = props;

  /* ---------------------------------- refs ---------------------------------- */
  const inputRef = useRef();

  /* --------------------------------- effects -------------------------------- */
  // when value changes update checkbox checked status
  useEffect(() => {
    inputRef.current.checked = value || false;
  }, [value]);

  // - jsx -
  // define checkbox component
  const checkBox = (
    <Form.Check
      ref={inputRef}
      type="checkbox"
      key={name}
      name={name}
      id={id}
      defaultChecked={value}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      isInvalid={isInvalid}
      feedback={feedback}
      feedbackType="invalid"
      style={style}
      disabled={isDisabled}
    />
  );

  // add tooltip to checkbox if tooltip prop specified by parent
  if (tooltip) {
    return (
      <OverlayTrigger
        key={`${name}-overlay-trigger`}
        placement="right"
        overlay={<Tooltip id={`${name}-tooltip`}>{tooltip}</Tooltip>}
      >
        {checkBox}
      </OverlayTrigger>
    );
  } else {
    return checkBox;
  }
}
