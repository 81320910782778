/* ------------------------------ core imports ------------------------------ */
import { forwardRef, useRef, useContext } from "react";

/* ---------------------------- internal imports ---------------------------- */

import ButtonGroup from "../../../../components/buttons/ButtonGroup";
import Form from "../../../../components/forms/Form";
import { DarkMode } from "../../../../components/App";

/* ---------------------------- external imports ---------------------------- */
import { LuX } from "react-icons/lu";

// a single filter availability row
export default forwardRef((props, ref) => {
  const { user, profileUsers, removeUser, disabled } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* -------------------------------- functions ------------------------------- */
  // format the role from an id to a human readable string
  function roleFormatter(role) {
    switch (role) {
      case 1:
        return "Reader";
      case 2:
        return "Editor";
    }
  }

  return (
    <div
      className={`${darkMode ? "tw-border-hue-600 tw-bg-hue-700" : "tw-border-hue-200 tw-bg-white"} tw-mb-1 tw-flex tw-justify-between tw-border tw-p-1 tw-transition-all tw-duration-200 hover:tw-shadow-sm`}
    >
      <div className="tw-w-full">
        <Form
          style={{ width: "100%" }}
          ref={ref}
          useCols={false}
          disabled={disabled}
          gap={0}
          inputData={[
            [
              {
                name: "user_id",
                type: "select",
                placeholder: "Select a user",
                initialValue: user.id
                  ? {
                      value: user.id,
                      label: user.forename + " " + user.surname,
                    }
                  : null,
                colStyle: { marginBottom: 0, padding: 5 },
                options: profileUsers.map((user) => {
                  return {
                    value: user.id,
                    label: user.forename + " " + user.surname,
                  };
                }),
              },
              {
                name: "role_id",
                type: "select",
                initialValue:
                  user && user.pivot
                    ? {
                        value: user.pivot.role_id,
                        label: roleFormatter(user.pivot.role_id),
                      }
                    : null,
                colStyle: { marginBottom: 0, padding: 5 },
                options: [
                  {
                    value: 1,
                    label: "Reader",
                  },
                  {
                    value: 2,
                    label: "Editor",
                  },
                ],
              },
            ],
          ]}
        />
      </div>
      {/* Button to add additional users / roles */}
      <div className="tw-flex tw-items-center tw-p-3">
        <button
          onClick={removeUser}
          className="tw-rounded-full tw-bg-red-600 tw-p-1 hover:tw-bg-red-700"
        >
          <LuX className="tw-text-white" style={{ height: 15, width: 15 }} />
        </button>
      </div>
    </div>
  );
});
