/* ------------------------------ core imports ------------------------------ */
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// External Imports
import { LuUserPlus, LuEye, LuTrash } from "react-icons/lu";

// Internal Imports
import AddNewUserModal from "./elements/AddNewUserModal";
import Datatable from "../../components/tables/Datatable";
import BadgeGroup from "../../components/BadgeGroup";
import { formatDateForDatatable } from "../../services/data-formatting/dates";
import { truncate } from "../../services/data-formatting/strings";
import { truncateArray } from "../../services/data-formatting/arrays";
import RemoveUserModal from "../user-detail/elements/RemoveUserModal";
import DTPFP from "../../components/DT_Pfp";
import DataTableWidget from "../../components/widgets/DataTableWidget";

// The main component of the user manager pages used to manage users within the system
export default function UserManager() {
  // setup navigation
  let navigate = useNavigate();

  const [isNewUserVisible, setIsNewUserVisible] = useState(false);
  const [isDeleteUserVisible, setIsDeleteUserVisible] = useState(false);
  const [userToRemove, setUserToRemove] = useState({});
  const dataTableRef = useRef();

  // launches the confirmation modal for removing a user
  function launchDeleteModal(data) {
    setUserToRemove(data.original);
    setIsDeleteUserVisible(true);
  }

  return (
    <>
      <Datatable
        ref={dataTableRef}
        hasExport={true}
        hasRefresh={true}
        columns={[
          {
            header: "Picture",
            accessorKey: "picture",
            enableSorting: false,
            size: 28,
            cell: ({ row }) => {
              return (
                <DTPFP
                  forename={{ values: row.original.forename }}
                  surname={{ values: row.original.surname }}
                  id={{ values: row.original.id }}
                />
              );
            },
          },
          {
            header: "#",
            accessorKey: "id",
            size: 22,
          },
          {
            header: "Forename",
            accessorKey: "forename",
            cell: ({ getValue }) => truncate(getValue(), 15),
          },
          {
            header: "Surname",
            accessorKey: "surname",
            cell: ({ getValue }) => truncate(getValue(), 15),
          },
          {
            header: "Email",
            accessorKey: "email",
            cell: ({ getValue }) => truncate(getValue(), 40),
          },
          {
            header: "Roles",
            accessorKey: "roles",
            enableSorting: false,
            cell: ({ getValue }) => {
              return (
                <BadgeGroup
                  data={truncateArray(
                    getValue(),
                    30,
                    (item) => item.name.length,
                    true,
                    { id: 0, name: "...", color: "#888" },
                  )}
                  labelKey="name"
                  colorIDKey="id"
                  keyKey="id"
                />
              );
            },
          },
          {
            header: "Last Active",
            accessorKey: "last_active_at",
            cell: ({ getValue }) => formatDateForDatatable(getValue()),
          },
          {
            header: "Email Verified",
            accessorKey: "email_verified_at",
            cell: ({ getValue }) => formatDateForDatatable(getValue()),
          },
          {
            header: "Registered",
            accessorKey: "created_at",
            cell: ({ getValue }) => formatDateForDatatable(getValue()),
          },
        ]}
        route="user"
        requestData={{
          include: ["roles"],
          includeCount: [],
          includeBlocked: 1,
        }}
        headerActions={[
          {
            label: "Create User",
            icon: <LuUserPlus />,
            type: "headerButton",
            key: "add-user",
            onClick: () => setIsNewUserVisible(true),
            variant: "secondary",
          },
        ]}
        isDropdown={true}
        rowActions={[
          {
            variant: "secondary",
            label: "View",
            key: "save",
            icon: <LuEye />,
            onClick: (data) => navigate(`/user-manager/${data.original.id}`),
          },
          {
            variant: "danger",
            label: "Remove",
            key: "delete",
            icon: <LuTrash />,
            onClick: (data) => launchDeleteModal(data),
            type: "dropdown",
          },
        ]}
      />
      {/* A modal that allows users to be added */}
      <AddNewUserModal
        visible={isNewUserVisible}
        onHide={() => setIsNewUserVisible(false)}
        onSuccess={() => {
          dataTableRef.current.refresh();
        }}
      />
      <RemoveUserModal
        visible={isDeleteUserVisible}
        onHide={() => setIsDeleteUserVisible(false)}
        onSuccess={() => {
          dataTableRef.current.refresh();
        }}
        user={userToRemove}
      />
    </>
  );
}
