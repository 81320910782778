import { useLocation } from "react-router-dom";

// gets the params from the url returning as object
export default function useParams() {
  // get search (params as string) from useLocation
  const { search } = useLocation();

  // use search (params as string) to create a params object that can be queried
  const searchParams = new URLSearchParams(search);

  // object to hold params
  const params = {};

  // create object using search params
  for (const [key, value] of searchParams) {
    params[key] = value;
  }

  // sort params to return as object
  return params;
}
