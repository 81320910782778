/* ------------------------------ core imports ------------------------------ */
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  useState,
} from "react";

/* ---------------------------- internal imports ---------------------------- */

import RulesGroup from "./RulesGroup";

// default export
export default forwardRef((props, ref) => {
  // destruct props passed from parent
  const {
    initialRules,
    isEditor = true,
    fieldOptions, // The fields that can be used for filtering with the datatable
  } = props;

  /* --------------------------------- state --------------------------------- */
  // and rule to be displayed in list
  const [initialAndRules, setInitialAndRules] = useState([]);
  const [initialOrRules, setInitialOrRules] = useState([]);

  /* ---------------------------------- refs ---------------------------------- */
  // Ref for the and rules group
  const andRulesRef = useRef();
  // Ref for the or rules group
  const orRulesRef = useRef();

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    if (initialRules) {
      setInitialAndRules(
        initialRules?.filter((rule) => rule.conjunction_id == 1),
      );
      setInitialOrRules(
        initialRules?.filter((rule) => rule.conjunction_id == 2),
      );
    } else {
      setInitialAndRules(null);
      setInitialOrRules(null);
    }
  }, [initialRules]);

  // use imperative handle allows the parent to access functions from this component
  useImperativeHandle(ref, () => ({
    gRules() {
      // Return all and rules and or rules as one array
      return [
        ...andRulesRef.current.gRules(1),
        ...orRulesRef.current.gRules(2),
      ];
    },
  }));
  /* --------------------------------- markup --------------------------------- */
  return (
    <>
      <div className="tw-px-2 tw-py-6">
        <h6 className="tw-m-0 tw-font-sans tw-text-2xl tw-font-bold">
          AND Rules
        </h6>
        <p className="tw-font-sans">All of these rules must be true.</p>
      </div>
      <div>
        <RulesGroup
          ref={andRulesRef}
          isEditor={isEditor}
          initialRules={initialAndRules}
          fieldOptions={fieldOptions}
        />
      </div>
      <hr className="tw-text-hue-400" />
      <div className="tw-px-2 tw-py-6">
        <h6 className="tw-m-0 tw-font-sans tw-text-2xl tw-font-bold">
          OR Rules
        </h6>
        <p className="tw-font-sans">
          At least one of these rules must be true.
        </p>
      </div>
      <div>
        <RulesGroup
          ref={orRulesRef}
          isEditor={isEditor}
          initialRules={initialOrRules}
          fieldOptions={fieldOptions}
        />
      </div>
    </>
  );
});
