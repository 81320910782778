/* ------------------------------ core imports ------------------------------ */
import { useContext, useEffect, forwardRef } from "react";

// - internal imports-
import { DarkMode } from "../App";

/* ---------------------------- external imports ---------------------------- */
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { LuChevronDown } from "react-icons/lu";
import { CSVLink } from "react-csv";
import { LuFileDown } from "react-icons/lu";

export default function DropdownButton(props) {
  // destruct props passed from parent
  const {
    label,
    className, // custom classes
    labelClass, // custom classes for the label
    data = null, // data to either be passed into the onClick method or downloaded from the csvlink button
    style, // custom styles
    disabled, // disabled the dropdown
    icon: initialIcon, // the icon to be displayed on the button can be a component function or a component
    tooltip, // tooltip to be displayed on the button
    actions, // (Optional) actions to be included with the dropdown button
    type, // (Optional) the type of button, csvlink or button
  } = props;

  // if the icon is a function then use it to construct a react component
  const icon = typeof initialIcon == "function" ? initialIcon() : initialIcon;

  /* --------------------------------- context -------------------------------- */
  const { darkMode } = useContext(DarkMode);

  /* -------------------------------- functions ------------------------------- */
  // capture the action menu scroll event and pass it to the main content
  function handleActionMenuScroll(event) {
    // get main content
    const mainContent = document.getElementById("main-content");

    // scroll main content using the delta from the scroll event
    mainContent?.scrollBy(event.deltaX, event.deltaY);
  }

  /* ------------------------ Define custom UI elements ----------------------- */
  //custom toggle UI
  const CustomToggle = forwardRef(({ children, onClick }, ref) => {
    // renters the toggle component
    function renderToggle() {
      return (
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          className="tw-flex tw-items-center tw-justify-center tw-text-inherit"
          style={style}
        >
          {icon ? <span> {icon} </span> : ""}
          {children}
          <div
            className={darkMode ? "chevron-selector_dark" : "chevron-selector"}
          >
            <LuChevronDown
              size="20"
              style={{ color: darkMode ? "white" : "black", margin: "auto" }}
            />
          </div>
        </a>
      );
    }

    // add tooltip if we need one else return the toggle
    return tooltip ? (
      <OverlayTrigger
        placement={tooltip.placement}
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        {renderToggle()}
      </OverlayTrigger>
    ) : (
      renderToggle()
    );
  });

  // custom menu UI
  const CustomMenu = forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">{children}</ul>
        </div>
      );
    },
  );

  // renders a dropdown item for the dropdown button
  const DropdownContent = (props) => {
    // destruct props
    const {
      type,
      icon = <LuFileDown />,
      label,
      data,
      filename,
      disabled,
    } = props;

    switch (type?.toLowerCase()) {
      case "csvlink":
      case "csv":
        return (
          <CSVLink
            className={"tw-flex tw-items-center tw-text-inherit"}
            separator={","}
            data={data}
            filename={filename}
            disabled={disabled}
          >
            <span className="pe-2">{icon}</span>
            <span> {label ? label : ""} </span>
          </CSVLink>
        );
        break;
      default:
        return (
          <div className={"tw-flex tw-items-center"}>
            <span className="pe-2">{icon}</span>
            {label}
          </div>
        );
        break;
    }
  };

  /* ------------------------------- return jsx ------------------------------- */
  return (
    <Dropdown
      className={`tw-flex tw-items-center tw-justify-center ${className || ""}`}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        variant="secondary"
        id="dropdown-basic"
        style={{ height: "100%" }}
        disabled={disabled}
      >
        <span className={labelClass}> {label ? label : ""} </span>
      </Dropdown.Toggle>
      <div
        onWheel={handleActionMenuScroll}
        style={{ zIndex: 9999, position: "fixed", inset: "0 0 auto auto" }}
      >
        <Dropdown.Menu as={CustomMenu}>
          {actions.length == 0 ? (
            <Dropdown.Item disabled>
              <div className={"tw-flex tw-items-center"}>{"No Actions"}</div>
            </Dropdown.Item>
          ) : (
            actions.map((action, index) => (
              <Dropdown.Item
                {...action}
                as="button"
                key={index}
                onClick={() => (action?.onClick ? action?.onClick(data) : "")}
              >
                <DropdownContent {...action} />
              </Dropdown.Item>
            ))
          )}
        </Dropdown.Menu>
      </div>
    </Dropdown>
  );
}
