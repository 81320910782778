/* ------------------------------ core imports ------------------------------ */
import { useRef } from "react";

// Description of what the component does
export default function Component() {
  /* --------------------------------- state --------------------------------- */

  /* --------------------------------- effects -------------------------------- */

  /* -------------------------------- functions ------------------------------- */

  /* --------------------------- pre jsx computation -------------------------- */

  /* --------------------------------- markup --------------------------------- */
  return <>Thanks for signing up! You can now login to your account.</>;
}
