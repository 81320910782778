// External Imports
import { MenuItem as RpsMenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import AuthService from "../../services/AuthService";

export default function MenuItem(props) {
  const { label, icon, to, permissions = [] } = props;
  let isVisible = true;
  permissions.forEach((permission) => {
    // If the user does not have the permission then the menu item should not be visible
    if (!AuthService.checkPermission(permission)) {
      isVisible = false;
    }
  });

  if (isVisible) {
    return (
      <RpsMenuItem
        component={
          // if to exists
          to && <Link to={to} />
        }
        {...props}
      >
        <span>{label} </span>
      </RpsMenuItem>
    );
  } else {
    return null;
  }
}
