/* ------------------------------ core imports ------------------------------ */
import { useContext, useEffect, forwardRef } from "react";

// - internal imports-
import { DarkMode } from "../App";

/* ---------------------------- external imports ---------------------------- */
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { LuFileDown } from "react-icons/lu";
import { CSVLink } from "react-csv";
import DropdownButton from "./DropdownButton";

// Component used to define the different types of buttons we can have in a button group
export default function ButtonGroupButton(props) {
  // destruct props passed from parent
  const {
    label,
    className, // custom classes
    type, // the type of button, csvlink or button
    data = null, // data to either be passed into the onClick method or downloaded from the csvlink button
    style, // custom styles
    variant,
    onClick,
    disabled,
    component,
    children,
    form,
    icon: initialIcon, // the icon to be displayed on the button can be a component function or a component
    tooltip,
    filename = null, // The filename of the downloaded file when using csvlink
    shrinkAt = "lg", // The breakpoint at which the button will shrink (2xl, xl, lg, md, sm or xs)
    actions, // (Optional) actions to be included with the dropdown button
  } = props;

  // if the icon is a function then use it to construct a react component
  const icon = typeof initialIcon == "function" ? initialIcon() : initialIcon;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- effects -------------------------------- */
  // validate shrink at prop when it changes
  useEffect(() => {
    // check that the shrinkAt prop is valid
    switch (shrinkAt) {
      case "2xl":
      case "xl":
      case "lg":
      case "md":
      case "sm":
      case "xs":
        break;
      default:
        throw new Error(
          "Invalid breakpoint provided for shrinkAt prop. Valid breakpoints are: 2xl, xl, lg, md, sm, xs",
        );
    }
  }, [shrinkAt]);

  /* -------------------------------- functions ------------------------------- */
  // - jsx -
  // Initialize a variable to hold the classes for the button
  let classes;

  // Switch case to handle different button variants
  // Depending on the variant, different classes are assigned
  // If dark mode is enabled, additional classes are added
  switch (variant) {
    case "warning":
      // If variant is 'warning', assign warning classes
      classes = `${className} button-warning`;
      break;
    case "danger":
      // If variant is 'danger', assign danger classes
      classes = `${className} button-danger`;
      break;
    case "link":
      // If variant is 'link', assign link classes
      classes = `${className} ${darkMode ? "button-link_dark" : "button-link"}`;
      break;
    case "outlined":
      // If variant is 'outlined', assign outlined classes
      classes = `${className} ${darkMode ? "button-outlined_dark" : "button-outlined"}`;
      break;
    case "primary":
      // If variant is 'primary', assign primary classes
      classes = `${className} button-primary`;
      break;
    case "secondary":
      // If variant is 'secondary', assign secondary classes
      classes = `${className} button-secondary`;
      break;
    case "return":
      // If variant is 'return', assign return classes
      classes = `${className} ${darkMode ? "button-return_dark" : "button-return"}`;
      break;
    default:
      // If no variant is provided, assign default classes
      classes = `${className} ${darkMode ? "button-regular_dark" : "button-regular"}`;
      break;
  }

  // create the class for the label to hide at specified breakpoint
  const labelClass = `${icon || type?.toLowerCase() == "csvlink" ? `tw-hidden ${shrinkAt}:tw-block ${label ? `${shrinkAt}:tw-pl-1` : ``}` : ``}`;

  // Switch case to handle different button types
  // Depending on the type, different components are returned
  switch (type?.toLowerCase()) {
    case "dropdown":
      // Return dropdown button with props spread into it
      return (
        <DropdownButton
          {...props}
          labelClass={labelClass}
          className={classes}
        />
      );
      break;
    case "component":
      // If type is 'component', return the component prop directly
      return component;
    case "csvlink":
    case "csv":
      // If type is 'csvlink', return a CSVLink component with the appropriate props
      // The CSVLink component allows the user to download a CSV file
      return (
        <CSVLink
          className={classes}
          style={style}
          separator={","}
          data={data}
          filename={filename}
          disabled={disabled}
          onClick={onClick ? () => onClick() : null}
        >
          {/* Icon for the CSVLink */}
          <span>
            {" "}
            <LuFileDown />{" "}
          </span>
          <span className={labelClass}> {label ? label : ""} </span>
          {/* Label for the CSVLink */}
          {children ? children : ""}
          {/* Any children components are rendered here */}
        </CSVLink>
      );
    default:
      // log warning if no icon is provided
      !initialIcon &&
        type?.toLowerCase() != "csvlink" &&
        console.warn(
          `No icon provided for button (${label}), this may cause issues on smaller screens`,
        );

      // If no type is provided or the type is not 'component' or 'csvlink', a regular button or a button with a tooltip is returned
      return (
        // If a tooltip is provided, an OverlayTrigger component is used to display the tooltip
        (tooltip && (
          <OverlayTrigger
            placement={tooltip.placement}
            overlay={<Tooltip>{tooltip}</Tooltip>}
          >
            <button
              style={style}
              type={type}
              form={form}
              disabled={disabled}
              variant={variant}
              className={classes}
              onClick={onClick ? () => onClick(data) : null}
            >
              {icon ? <span> {icon} </span> : ""}
              {/* Icon for the button */}
              <span className={labelClass}> {label ? label : ""} </span>
              {/* Label for the button */}
              {children ? children : ""}
              {/* Any children components are rendered here */}
            </button>
          </OverlayTrigger>
        )) || (
          // If no tooltip is provided, a regular button is returned
          <button
            style={style}
            type={type}
            form={form}
            disabled={disabled}
            variant={variant}
            className={classes}
            onClick={onClick ? () => onClick(data) : null}
          >
            {/* Icon for the button */}
            {icon ? <span>{icon}</span> : ""}
            {/* Label for the button */}
            <span className={labelClass}> {label ? label : ""} </span>
            {/* Any children components are rendered here */}
            {children ? children : ""}
          </button>
        )
      );
  }
}
