// External Imports
import toast from "react-hot-toast";

// Internal Imports
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import APIClient from "../../../services/clients/APIClient";

// Launches a modal that can be used to change the selected users password
export default function RemoveProfileModal({
  visible,
  onHide,
  onSuccess,
  profile,
}) {
  // Removes a user from the system based on the current selected user
  function deleteUser() {
    APIClient.delete(`profile/${profile.id}`)
      .then(() => {
        toast.success("User removed successfully");
        onSuccess();
        onHide();
      })
      .catch((error) => {
        toast.error(`Failed to remove profile - ${error}`);
      });
  }

  return (
    <ConfirmationModal
      title={`Remove ${profile.name || ""}?`}
      message={`Are you sure you want to remove ${profile.name || ""}?`}
      actions={[
        { label: "Cancel", variant: "secondary", onClick: onHide },
        { label: "Confirm", variant: "danger", onClick: () => deleteUser() },
      ]}
      visible={visible}
      onHide={onHide}
    />
  );
}
