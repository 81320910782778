/* ---------------------------- external imports ---------------------------- */
import toast from "react-hot-toast";

// Internal Imports
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import APIClient from "../../../services/clients/APIClient";

// Launches a modal that can be used to change the selected users password
export default function RemoveFilter({ visible, onHide, onSuccess, filter }) {
  // Removes a user from the system based on the current selected user
  function deleteUser() {
    APIClient.delete(`filter/${filter.id}`)
      .then(() => {
        toast.success("Filter removed successfully");
        onSuccess();
        onHide();
      })
      .catch((error) => {
        toast.error(`Failed to remove filter - ${error}`);
      });
  }

  return (
    <ConfirmationModal
      title={`Remove ${filter?.name || ""}?`}
      message={`Are you sure you want to remove ${filter?.name || ""}?`}
      actions={[
        { label: "Cancel", variant: "secondary", onClick: onHide },
        { label: "Confirm", variant: "danger", onClick: () => deleteUser() },
      ]}
      visible={visible}
      onHide={onHide}
    />
  );
}
