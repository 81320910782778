/* ------------------------------ core imports ------------------------------ */
import { useEffect, useState } from "react";

/* ---------------------------- external imports ---------------------------- */
import { Alert } from "react-bootstrap";

// Description of what the component does
export default function Component(props) {
  // destruct props passed from parent
  const { status = "validating" } = props;

  /* --------------------------------- state --------------------------------- */
  const [emailVerificationContent, setEmailVerificationContent] = useState(
    "Validating email address",
  );
  const [statusVariant, setStatusVariant] = useState("warning");

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    //Define UI text elements based on validation status
    switch (status.toLowerCase()) {
      case "validating":
        setEmailVerificationContent("Validating your email address.");
        setStatusVariant("warning");
        break;
      case "validated":
        setEmailVerificationContent(
          "We've validated your email address, please press next to continue.",
        );
        setStatusVariant("success");
        break;
      default:
        setEmailVerificationContent(
          "Failed to verify email, please try again or contact an administrator.",
        );
        setStatusVariant("danger");
        break;
    }
  }, [status]);

  /* -------------------------------- functions ------------------------------- */

  // - styles -
  const styles = {
    container: { display: "flex", flexDirection: "column", minHeight: 100 },
    continueMessage: { fontSize: 20, fontWeight: "500" },
  };

  /* --------------------------- pre jsx computation -------------------------- */

  /* --------------------------------- markup --------------------------------- */
  return (
    <div style={styles.container}>
      <p>
        Welcome to Spark, we're excited to have you on board! We just have a few
        things to ask you before you get started.
      </p>
      <Alert variant={statusVariant} style={{ marginTop: 20 }}>
        {emailVerificationContent}
      </Alert>
    </div>
  );
}
