/* ------------------------------ core imports ------------------------------ */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useContext,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";

/* ---------------------------- external imports ---------------------------- */
import { Container } from "react-bootstrap";
import toast from "react-hot-toast";
import { VscArrowDown, VscArrowUp } from "react-icons/vsc";
import {
  useReactTable,
  getPaginationRowModel,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

/* ---------------------------- internal imports ---------------------------- */

import { isFunction } from "lodash";
import APIClient from "../../services/clients/APIClient";
import ButtonGroup from "../buttons/ButtonGroup";
import LoadingSpinner from "../LoadingSpinner";
import DatatableActionHeader from "./DatatableActionHeader";
import { DarkMode } from "../App";
import useParams from "../../services/useParams";
import DatatablePagination from "./DatatablePagination";
import DatatableRowActions from "./DatatableRowActions";

const timeBetweenRequest = 1000; // minimum time in milliseconds between requests

export default forwardRef((props, ref) => {
  // setup navigation so we can use JS to move views
  const navigate = useNavigate();

  // - context -
  const { darkMode } = useContext(DarkMode);

  // get params from url
  const urlParams = useParams();

  // destruct props passed from parent
  const {
    id, // An id for the table used to Uniquely identify it
    title, // (Optional) Header text that will appear directly above the table.
    subTitle, // (Optional) Subtitle text that will appear just below the title.
    description, // (Optional) Description text that will appear just below the subtitle.
    description_colour = "yellow", // (Optional) The background colour of the description box.
    route, // route used to fetch async data
    requestData = {}, // (Optional) Request data passed from parent component
    autoRefresh = false, // (Optional) should the datatable refresh on a timer
    hasExport = false, // (Optional) should the datatable header have an export button that allows users to download a CSV version of DT.
    hasRefresh = true, // (Optional) should the datatable header have a refresh button that fetches the data from the backend again.
    hasViewManager = false, // (Optional) should the datatable header have a view manager
    hasFilterManager = false, // (Optional) should the datatable header have a filter manager
    storeStateInURL = false, // (Optional) should the datatable store the state in the URL so it can be saved when the page reloads
    hasPagination = true, // (Optional) should the datatable be paginated
    hasColumnResizing = true, // (Optional) should the datatable have column resizing
    initialSortBy = urlParams.sortBy
      ? decodeSortBy(urlParams.sortBy)
      : [{ id: "created_at", desc: true }], // (Optional) Array defining the initial sorting state of the datatable
    initialPage = urlParams.page ? urlParams.page : 1, // (Optional) The initial page to load into the datatable
    initialPageSize = urlParams.pageSize ? urlParams.pageSize : 20, // (Optional) The initial amount of records to show per page
    initialSearch = urlParams.search ? urlParams.search : "", // (Optional) The initial search string
    initialFilter = urlParams.filter, // (Optional) The initial filter id to load into the datatable
    initialView = urlParams.view, // (Optional) The initial view id to load into the datatable
    columns: initialColumns, // An array of objects that contains the column information
    data: staticData, // (Optional) An array of objects to display within the datatable when route is not set (Static Data)
    headerActions = [], // (Optional) additional to be displayed in the header
    headerActionsShrinkAt, // (Optional) The breakpoint at which the buttons will shrink in the header (2xl, xl, lg, md, sm or xs)
    isDropdown, // (Optional) should the actions area be displayed as a dropdown
    rowActions, // the actions to show for each row in the actions column
    onStateUpdate, // (Optional) a function to be ran when state updates
    initialAutoRefreshRate = 30, // (Optional) The initial auto refresh rate (Only used if autoRefresh = true)
    onFetchData, // (Optional) a function to set the raw data in the parent component
    lengthOptions = [
      { label: "Show 20 ", value: 20 },
      { label: "Show 50 ", value: 50 },
      { label: "Show 100 ", value: 100 },
      { label: "Show 200 ", value: 200 },
    ], // (optional) an option to set the datatable page length, accepts an array of objects.
  } = props;

  /* --------------------------------- state --------------------------------- */
  // The data displayed in the datatable
  const [search, setSearch] = useState(initialSearch); // The search string used to filter the data
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAutoRefreshing, setIsAutoRefreshing] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isFetchQueued, setIsFetchQueued] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [lastFetchAt, setLastFetchAt] = useState(0);
  const [controlledPageCount, setPageCount] = useState(0);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [recordsFiltered, setRecordsFiltered] = useState(0);
  const [columns, setColumns] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  const [include, setInclude] = useState([]);
  const [includeCount, setIncludeCount] = useState([]);
  const [isFetchingDefaultFilter, setIsFetchingDefaultFilter] =
    useState(hasFilterManager); // defaults to false unless we have a filter manager
  const [isFetchingDefaultView, setIsFetchingDefaultView] = useState(
    hasViewManager && initialView,
  ); // defaults to false unless we have a view manager and an initial view
  const [isFetchingColumnData, setIsFetchingColumnData] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [lastRequestData, setLastRequestData] = useState({});
  // Stores the body height of the last time the table rendered with data
  //const [bodyHeight, setBodyHeight] = useState(0);

  /* ---------------------------------- refs ---------------------------------- */
  // the id of the last fetch
  const fetchIdRef = useRef(0);

  // The id of the last timeout
  const timeoutIdRef = useRef(0);

  // Stores the body height of the last time the table rendered with data
  const bodyHeightRef = useRef(0);

  // - memos -
  // create initialColumnsEffectKey to track changes in initialColumns so we only activate the use effect when it has actually updated
  const initialColumnsEffectKey = useMemo(() => {
    return initialColumns?.map(({ accessorKey }) => accessorKey).join(",");
  }, [initialColumns]);

  // create rowActionsEffectKey to track changes in rowActions so we only activate the use effect when it has actually updated
  const rowActionsEffectKey = useMemo(() => {
    // if rowActions is an array create key else return rowActions
    return Array.isArray(rowActions)
      ? rowActions?.map(({ key }) => key).join(",")
      : rowActions?.toString();
  }, [rowActions]);

  // We use the the ref on top of the state to allow the value to be updated instantly rarther than waiting for the next render
  // This means more requests cannot get through before the state is changed.
  const isFetchingDataRef = useRef(false);

  // If the parent did not pass a ref create one
  if (!ref) ref = useRef();

  // Setup Datatable
  const table = useReactTable({
    data,
    columns: columns || [],
    // Set initial state using default values that can be overridden or added to by the initialState prop
    initialState: {
      pagination: {
        pageSize: initialPageSize,
        pageIndex: initialPage - 1,
      },
      search,
    },
    state: {
      sorting: prepSortByForReactTable(),
    },
    manualPagination: true,
    pageCount: controlledPageCount,
    enableSorting: true,
    manualSorting: true,
    enableMultiSort: true,
    enableColumnResizing: hasColumnResizing,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: handleSortByChange,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // get table state date
  const {
    canPreviousPage,
    canNextPage,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
  } = table;

  // get currently shown rows from table
  const rows = table.getRowModel().rows;

  // get the header groups from table
  const headerGroups = table.getHeaderGroups();

  // get state values from table
  const {
    pagination: { pageIndex, pageSize },
  } = table.getState();

  /* --------------------------------- effects -------------------------------- */
  // Setup in row actions for datatable
  useEffect(() => {
    buildColumns();
  }, [
    initialColumnsEffectKey,
    rowActionsEffectKey,
    selectedFilter,
    selectedView,
    isFetchingDefaultFilter,
  ]);

  // When any of the state values change send them to the parent and/or update the url with state
  useEffect(() => {
    // run state update callback
    if (onStateUpdate) {
      onStateUpdate({
        pageIndex,
        pageSize,
        sortBy,
        search,
        staticData,
        route,
        filter: selectedFilter?.value,
        view: selectedView,
      });
    }

    // if we want to store the state in the url append url string to the organisations page in the url
    if (storeStateInURL) {
      navigate(`${window.location.pathname}?${buildDatatableStateString()}`);
    }
  }, [
    pageIndex,
    pageSize,
    sortBy,
    search,
    staticData,
    route,
    selectedView,
    selectedFilter,
  ]);

  useEffect(() => {
    if(onFetchData) onFetchData(data);
  }, [data]);

  // when the columns change update fetch new data without updating the state
  useEffect(() => {
    fetchData();
  }, [columns, pageIndex, search, sortBy, pageSize]);

  // Listen for changes in is fetching data
  useEffect(() => {
    // if is fetching data is set to false and we have another fetch queued we should run that fetch otherwise do nothing
    if (
      !isFetchingData &&
      !isFetchingDefaultFilter &&
      !isFetchingDefaultView &&
      isFetchQueued
    ) {
      setIsFetchQueued(false);
      fetchData();
    }
  }, [isFetchingData]);

  useEffect(() => {
    // if page count is less than page index go to page 0 and we are not loading
    if (controlledPageCount <= pageIndex && !isLoading) setPageIndex(0);
  }, [controlledPageCount, pageIndex, isLoading]);

  // when the page updates build new export data if export is enabled
  useEffect(() => {
    // if export is enabled
    if (hasExport) {
      // Rebuild export data with new data
      setExportData(
        table.getRowModel().rows.map((row) => {
          let csvRow = {};
          row.getVisibleCells().forEach((cell) => {
            switch (cell.column.id) {
              case "actions":
                break;
              default:
                // use the renderer function to render the value
                const renderedValue = cell.column.columnDef.cell(cell);
                const header = cell.column.columnDef.header;

                // check the type of the rendered value and format it accordingly
                switch (typeof renderedValue) {
                  case "object":
                    // Stringify objects
                    //csvRow[key] = value ? "`"+JSON.stringify(value)+"`" : "";
                    csvRow[header] = renderedValue ? "Not Supported" : "";
                    break;
                  default:
                    // only update the value if we don't already have a valid value
                    if (
                      csvRow[header] == undefined ||
                      csvRow[header] == "" ||
                      csvRow[header] == "Not Supported"
                    )
                      csvRow[header] = renderedValue;
                }
            }
          });
          return csvRow;
        }),
      );
    }
  }, [rows]);

  // Attaches the fetchData to the ref so parents can refresh the datatable
  useImperativeHandle(ref, () => ({
    refresh: fetchData,
    search,
    sortBy,
    pageIndex,
    pageSize,
    buildDatatableStateString,
    data,
    rows,
    columns,
  }));

  /* -------------------------------- functions ------------------------------- */
  // loads the datatable with new data
  async function fetchData(type) {
    // if type is auto-refresh then set is auto refreshing to true else false
    setIsAutoRefreshing(type === "auto-refresh");
    const currentTime = new Date().getTime();

    // if the current time is less than the last fetch time plus the time between requests then don't fetch data
    if (currentTime < timeBetweenRequest + lastFetchAt) {
      // Wait for the amount of time left until the timeout is over
      timeout(timeBetweenRequest - (currentTime - lastFetchAt));
    } else {
      // don't fetch data if the datatable is still loading
      if (columns && !isFetchingDefaultFilter && !isFetchingDefaultView) {
        // If we are already fetching data we should mark that once we are done fetching data we want to fetch data again
        if (isFetchingDataRef.current) {
          setIsFetchQueued(true);
        } else {
          setIsLoading(true);
          setIsFetchingData(true);
          // store current time in last fetch at (as milliseconds since epoch)
          setLastFetchAt(new Date().getTime());
          isFetchingDataRef.current = true;

          // Give this fetch an ID
          const fetchId = ++fetchIdRef.current;

          if (route) {
            fetchDataAsync(fetchId);
          } else if (staticData) {
            fetchDataStatic(fetchId);
          } else {
            throw "Datatable Component needs either route or data to be set";
          }

          setIsFetchingColumnData(false);
        }
      }
    }
  }

  // fetches the datatable data from a static array of data
  async function fetchDataStatic(fetchId) {
    // instantiate filtered data.
    let filteredData;

    // if we have a search string filter data
    if (search != "" && search != null) {
      filteredData = staticData.filter((dataRow) => {
        let foundMatch = false;
        Object.values(dataRow).forEach((dataItem) => {
          if (dataItem.toLowerCase().includes(search.toLowerCase())) {
            return (foundMatch = true);
          }
        });
        return foundMatch;
      });
    } else {
      filteredData = staticData;
    }

    // if we have a sort by then sort by
    if (sortBy) {
      filteredData = filteredData.sort((a, b) => {
        let index = 0;

        // while we still have sort rules and we have not ordered the values
        while (sortBy[index]) {
          // get current sort by rule
          const currentSortBy = sortBy[index];

          // convert any numeric values to numbers
          const aVal = Number(a[currentSortBy.id]) || a[currentSortBy.id];
          const bVal = Number(b[currentSortBy.id]) || b[currentSortBy.id];

          // compare values and return result of sort
          if (aVal > bVal) {
            return currentSortBy.desc ? -1 : 1;
          } else if (aVal < bVal) {
            return currentSortBy.desc ? 1 : -1;
          }

          // increment index to move to next sort if it exists
          index++;
        }

        // if the values are the same over all the sorts then make no change
        return 0;
      });
    }

    // get a single page worth of data
    setData(
      filteredData.slice(pageSize * pageIndex, pageSize * (pageIndex + 1)),
    );
    setPageCount(Math.ceil(filteredData.length / pageSize));
    setRecordsTotal(staticData.length);
    setRecordsFiltered(filteredData.length);
    setIsLoading(false);
    setIsAutoRefreshing(false);

    // If this was the last fetch then update data
    if (fetchId === fetchIdRef.current) {
      setIsFetchingData(false);
      isFetchingDataRef.current = false;
    }
  }

  // fetches the datatable data from the backend
  async function fetchDataAsync(fetchId) {
    // Create Submit data object
    const compiledRequestData = {
      after: pageSize * pageIndex,
      limit: pageSize,
      orderBy: sortBy,
      filterID: selectedFilter?.value,
      viewID: selectedView?.value,
      ...requestData, // add request data passed from parent component,
      include,
      includeCount,
    };

    // Add search string to submitData
    if (search != "" && search != null) compiledRequestData.search = search;

    // reset error message
    setErrorMessage(null);

    // Save a copy of the last request data
    setLastRequestData(compiledRequestData);

    console.log("fetching data", compiledRequestData, selectedFilter);

    // fetch data from backend
    return APIClient.get(route, compiledRequestData)
      .then((response) => {
        // If this was the last fetch then update data
        if (fetchId === fetchIdRef.current) {
          setData(response?.data || []);
          setPageCount(Math.ceil((response.recordsFiltered || 0) / pageSize));
          setRecordsTotal(response.recordsTotal);
          setRecordsFiltered(response.recordsFiltered);
          setIsLoading(false);
          setIsAutoRefreshing(false);
          setIsFetchingData(false);
          isFetchingDataRef.current = false;
        }
      })
      .catch((error) => {
        toast.error(`Datatable Failed to Load Data: ${error.data.message}`);
        setErrorMessage(error.data.message);
        // In case of error don't set data but still change states
        // If this was the last fetch then update data
        if (fetchId === fetchIdRef.current) {
          setIsFetchingData(false);
          isFetchingDataRef.current = false;
        }
      });
  }

  // handles when the request has been timed out (waits for the delay and then tries again)
  function timeout(delay) {
    // Give this timeout an ID
    const timeoutId = ++timeoutIdRef.current;

    // set is timed out to true
    setIsTimedOut(true);

    return new Promise((res) => setTimeout(res, delay)).then(() => {
      // if this is the most recent timeout then fetch data
      if (timeoutIdRef.current === timeoutId) {
        // fetch data
        fetchData();

        // set is timed out to false
        setIsTimedOut(false);
      }
    });
  }

  // handles when the search box value is changed
  function onSearch(value) {
    setPageIndex(0); // reset page index on new search
    setSearch(value); // update search string
  }

  // builds url data string from the current datatableState
  function buildDatatableStateString() {
    // build state as key value pairs
    const state = [
      { key: "page", value: pageIndex + 1 },
      { key: "search", value: encodeURIComponent(search) },
      { key: "pageSize", value: pageSize },
      { key: "view", value: selectedView?.value },
      // format sortBy into a string
      {
        key: "sortBy",
        value: sortBy?.map(({ id, desc }) => `${id}-${desc ? "desc" : "asc"}`),
      },
      { key: "filter", value: selectedFilter?.value },
    ];

    let urlString = "";

    // for each datatableState item add append the value to the URL string
    state.forEach(({ key, value }) => {
      if (value != null) {
        if (urlString == "") {
          urlString += `${key}=${value}`;
        } else {
          urlString += `&${key}=${value}`;
        }
      }
    });
    return urlString;
  }

  // receives an order string and transforms it into the order by structure the backend expects
  function decodeSortBy(order) {
    let sortByArray = [];

    if (order) {
      const orders = order.split(",");
      orders.forEach((order) => {
        // split order string into array of order parts
        let orderParts = order.split("-");

        // construct order object and add to sortByArray
        sortByArray.push({ id: orderParts[0], desc: orderParts[1] == "desc" });
      });
    }

    // return sortByArray
    return sortByArray;
  }

  // handles when the sort by is being changed by the datatable
  function handleSortByChange(getSortBy) {
    // get correct accessorKey by using the sorting key from the sort
    setSortBy(
      getSortBy(prepSortByForReactTable())?.map((sortingRule) => {
        return {
          id:
            columns.find((column) => column.sortingKey === sortingRule.id)
              ?.accessorKey || sortingRule.id,
          desc: sortingRule.desc,
        };
      }),
    );
  }

  // prepares and returns the sortBy array for the react table
  function prepSortByForReactTable() {
    return sortBy.map(({ id, desc }) => {
      return { id: id.replace(".", "_"), desc };
    });
  }

  // builds the column data for the datatable and sets the columns state
  function buildColumns() {
    // initialize variable to hold how the actions area will render
    let cellRender;

    // Set how the action column cells should render based on if the actions menu is a dropdown
    if (isDropdown) {
      cellRender = ({ row }) => {
        // Define row actions by either running the row action function or taking the static row actions
        return (
          <DatatableRowActions
            label=""
            actions={isFunction(rowActions) ? rowActions(row) : rowActions}
            data={row}
          />
        );
      };
    } else {
      cellRender = ({ row }) => {
        // Define row actions by either running the row action function or taking the static row actions
        return (
          <Container>
            <ButtonGroup
              actions={isFunction(rowActions) ? rowActions(row) : rowActions}
              data={row}
              useRowsAndCols={true}
              buttonStyle={{ width: "100%" }}
            />
          </Container>
        );
      };
    }

    // filter selected filter values to just the fields
    const filterFields = selectedFilter?.values
      ? selectedFilter.values
          .filter((value) => value.type_id == 1)
          .map(({ value }) => value)
      : [];

    let includedColumns;

    if (hasViewManager) {
      // if we have a selected view
      if (selectedView) {
        // Mark that the datatable is no longer fetching the default view
        setIsFetchingDefaultView(false);
      }

      // build included columns based on the selected view
      includedColumns = initialColumns
        .map((col) => {
          // get the columns matching view column if one exists
          const matchingViewColumns = selectedView?.data?.columns?.find(
            (visibleCol) => visibleCol.accessor === col.accessorKey,
          );

          // return col data with show and order properties
          return {
            ...col,
            show: selectedView
              ? matchingViewColumns
                ? true
                : false
              : col.default,
            order: matchingViewColumns ? matchingViewColumns.order : null,
          };
        })
        //filtering to just the cols to show
        .filter((col) => col.show)
        // sort by the order property
        .sort((a, b) => a.order - b.order);
    } else {
      includedColumns = initialColumns;
    }

    // if we have a filter manager create list of includes that are required for the filter manager but do not need to be shown in the table
    const filterIncludes = hasFilterManager
      ? initialColumns.filter((column) => {
          // if we are already including the column then we can ignore it
          if (includedColumns.includes(column)) {
            return false;
            // else if we require the column for a filter then make sure it's included in the request
          } else if (filterFields.includes(column.accessorKey)) {
            return true;
          } else {
            return false;
          }
        })
      : [];

    const sortIncludes = initialColumns.filter((column) => {
      // if we find that the column is included in a sort then we should include it in the request
      return sortBy.find((value) => value.id === column.accessorKey);
    });

    // begin building include and includeCount using data within columns
    let newInclude = requestData.include || [];
    let newIncludeCount = requestData.includeCount || [];

    // loop through columns and filter includes to build include and includeCount lists to send to the backend
    [...includedColumns, ...filterIncludes, ...sortIncludes].forEach(
      (column) => {
        // if the column has an include and the include is not already in the newInclude array
        if (column.include) {
          if (Array.isArray(column.include)) {
            column.include.forEach((include) => {
              if (!newInclude.includes(include)) newInclude.push(include);
            });
          } else {
            if (!newInclude.includes(column.include))
              newInclude.push(column.include);
          }
        }

        // if the column has an includeCount and the includeCount is not already in the includeCount array
        if (column.includeCount) {
          if (Array.isArray(column.includeCount)) {
            column.includeCount.forEach((includeCount) => {
              if (!newIncludeCount.includes(includeCount))
                newIncludeCount.push(includeCount);
            });
          } else {
            if (!newIncludeCount.includes(column.includeCount))
              newIncludeCount.push(column.includeCount);
          }
        }
      },
    );

    // set include and includeCount
    setInclude(newInclude);
    setIncludeCount(newIncludeCount);

    // for each column in the included columns
    includedColumns.forEach((column) => {
      // if the column has a sorting key use it otherwise use the accessor key replacing . with _
      column.sortingKey = column.accessorKey?.replace(".", "_");
    });

    // set is fetching column data to true to stop the datatable from fetching data while we are updating the columns
    setIsFetchingColumnData(true);

    // store new column value attaching thr row actions column if there are any row actions
    setColumns([
      ...includedColumns,
      // Add column for the actions row
      ...(rowActions
        ? [
            {
              id: "actions",
              header: "Actions",
              accessorKey: "actions",
              enableSorting: false,
              cell: cellRender,
              size: 28,
            },
          ]
        : []),
    ]);
  }

  function renderTableBody() {
    if (
      (isLoading || isTimedOut || isFetchingColumnData) &&
      !isAutoRefreshing
    ) {
      // update body height
      const bodyHeight = Math.max(
        document.getElementById(`${id}-body`)?.offsetHeight - 50 || 0,
        200,
      );

      // return the loading spinner with the hight set to the previous body height
      return (
        <LoadingSpinner
          isActive={true}
          type="table"
          style={{ height: bodyHeight }}
        />
      );
    } else {
      // return new body content
      return rows.length == 0 ? (
        <tr role="row">
          <td
            role="cell"
            colSpan={columns.length}
            style={{ textAlign: "center" }}
            className="tw-p-3"
          >
            No data to show.
            {errorMessage == null ? "" : ` ${errorMessage}`}
          </td>
        </tr>
      ) : (
        rows.map(renderRow)
      );
    }
  }

  // renders a single row returning jsx for the row
  function renderRow(row) {
    //  get row styles either from the rowStyles function or row styles as an object
    const rowStyle =
      props.rowStyle instanceof Function ? props.rowStyle(row) : props.rowStyle;
    return (
      <tr key={row.id}>
        {row.getVisibleCells().map((cell) => {
          return (
            <td
              key={cell.id}
              style={{
                width: cell.column.getSize(),
                ...rowStyle,
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    );
  }

  // - jsx -
  return (
    <>
      {/* <div className={"widget-title-container"}>
        {title && <h4>{title}</h4>}
        {subTitle && (
          <span
            className={
              darkMode ? "widget-subtitle_dark" : "widget-subtitle_light"
            }
          >
            {subTitle}
          </span>
        )}
      </div> */}
      <div
        className={`datatable ${darkMode ? "datatable_dark" : "datatable_light"}`}
      >
        {description && (
          // If we have a description create the description box (with optional custom colour)
          <div
            className={`${`tw-bg-${description_colour}-300`} description-box`}
          >
            <span className="tw-text-sm">{description}</span>
          </div>
        )}
        <DatatableActionHeader
          id={id}
          refreshDatatable={fetchData}
          actions={headerActions}
          onSearch={onSearch}
          initialValue={search}
          actionsShrinkAt={headerActionsShrinkAt}
          hasViewManager={hasViewManager}
          hasFilterManager={hasFilterManager}
          hasExport={hasExport}
          exportData={exportData}
          hasRefresh={hasRefresh}
          autoRefresh={autoRefresh}
          initialView={initialView}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          initialFilterId={initialFilter}
          setSelectedFilter={(selectedFilterOption) => {
            // if we are fetching default filter
            if (isFetchingDefaultFilter) {
              // set that we are finished fetching default filter
              setIsFetchingDefaultFilter(false);
            }

            // update selected filter option
            setSelectedFilter(selectedFilterOption);
          }}
          columns={initialColumns}
          initialAutoRefreshRate={initialAutoRefreshRate}
          route={route}
          requestData={lastRequestData}
        />
        <div className="tw-overflow-auto">
          <table>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{ position: "relative", width: header.getSize() }}
                    >
                      <span style={{ float: "left" }}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </span>
                      <span style={{ float: "right" }}>
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() == "asc" ? (
                            <VscArrowUp />
                          ) : (
                            <VscArrowDown />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`}
                        ></div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody id={`${id}-body`} style={{ position: "relative" }}>
              {/* Render the body of the table (Includes data rows or loading spinner) */}
              {renderTableBody()}
              <tr>
                <td className="datatable-footer" colSpan="10000">
                  Showing {pageIndex * pageSize}
                  {" to "}
                  {pageIndex * pageSize + rows.length}
                  {" of "}
                  {recordsFiltered == recordsTotal
                    ? recordsFiltered
                    : `${recordsFiltered} filtered from ${recordsTotal}`}
                  {" results"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {hasPagination ? (
        <DatatablePagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageCount={controlledPageCount}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={setPageIndex}
          setPageSize={setPageSize}
          lengthOptions={lengthOptions}
        />
      ) : (
        <p></p>
      )}
    </>
  );
});
