/* ------------------------------ core imports ------------------------------ */
import { forwardRef, useRef, useState, useContext } from "react";

/* ---------------------------- internal imports ---------------------------- */

import Form from "../../../../components/forms/Form";
import { DarkMode } from "../../../../components/App";

/* ---------------------------- external imports ---------------------------- */
import { LuX } from "react-icons/lu";

// The options that can be used in the value type dropdown
const selectValueTypeOptions = [
  { value: 1, label: "Field" },
  { value: 2, label: "Text" }, // static text
  { value: 3, label: "Date" }, // static date
];

// The options that can be used in the operator dropdown
const selectOperatorOptions = [
  { value: 1, label: "=" },
  { value: 2, label: ">" },
  { value: 3, label: ">=" },
  { value: 4, label: "<" },
  { value: 5, label: "<=" },
  { value: 6, label: "Contains" },
  { value: 7, label: "Doesn't Contain" },
  { value: 8, label: "Not Equal To" },
];

// options for the special/other values dropdown
const specialValueOptions = [
  { label: "Current Date", value: "current_date.date" },
  { label: "Current Date Start", value: "current_date.start" },
  { label: "Current Date End", value: "current_date.end" },
  { label: "Current User's ID", value: "current_user.id" },
  { label: "Current User's Forename", value: "current_user.forename" },
  { label: "Current User's Surname", value: "current_user.surname" },
  { label: "Current User's Created At", value: "current_user.created_at" },
];

// a single rule
export default forwardRef((props, ref) => {
  // destruct props passed from parent
  let {
    initialRule,
    onRemove,
    disabled,
    fieldOptions, // The fieldOptions that can be used for filtering with the datatable
  } = props;

  const [selectedTypes, setSelectedTypes] = useState({});

  // use ref passed in from parent as form ref or create new form ref
  ref = ref ? ref : useRef();

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* -------------------------------- functions ------------------------------- */
  // handles when the remove rule button is clicked
  function handleRemoveRule() {
    // run parent on remove
    onRemove();
  }

  // gets the options for the field dropdown
  function getFieldOptions() {
    return [...fieldOptions, ...specialValueOptions];
  }

  // Get value type option based on value of the option
  function getValueTypeOptionByValue(value) {
    return selectValueTypeOptions.find((option) => option.value == value);
  }

  // Get value type option based on value of the option
  function getOperatorOptionByValue(value) {
    return selectOperatorOptions.find((option) => option.value == value);
  }

  // handles when a value type is changed
  function onValueTypeChange(data, element) {
    // make a copy of selected types object
    let newSelectedTypes = { ...selectedTypes };

    // update value for current element
    newSelectedTypes[element.name] = data.value;

    setSelectedTypes(newSelectedTypes);
  }

  // builds a single value input based on current matching value type based on index parameter
  function buildValueInput(key, value, initialTypeID) {
    // get selected type id, if it does not exist use initial type id
    const selectedTypeID = selectedTypes[`type_${key}`] || initialTypeID;

    switch (selectedTypeID) {
      case 1: // field
        return {
          name: `value_${key}`,
          initialValue: value
            ? getFieldOptions().find((option) => value == option.value) || {
                label: value,
                value: value,
              }
            : null,
          type: "select",
          isCreatable: true,
          options: getFieldOptions(),
          colStyle: { marginBottom: 0, padding: 5 },
          elementStyle: { marginBottom: 0 },
        };
      case 3: // date
        return {
          name: `value_${key}`,
          placeholder: "value",
          type: "date",
          initialValue: value,
          colStyle: { marginBottom: 0, padding: 5 },
          elementStyle: { marginBottom: 0 },
        };
      default: // value
        return {
          name: `value_${key}`,
          placeholder: "value",
          type: "text",
          initialValue: value,
          colStyle: { marginBottom: 0, padding: 5 },
          elementStyle: { marginBottom: 0 },
          className: "tw-h-full",
        };
    }
  }

  /* --------------------------------- markup --------------------------------- */
  return (
    <div
      className={`${darkMode ? "tw-border-hue-600 tw-bg-hue-700" : "tw-border-hue-200 tw-bg-white"} tw-mb-1 tw-flex tw-justify-between tw-border tw-p-1 tw-transition-all tw-duration-200 hover:tw-shadow-sm`}
    >
      <div className="tw-w-full">
        <Form
          id="rule"
          ref={ref}
          useCols={false}
          disabled={disabled}
          gap={0}
          inputData={[
            [
              {
                name: "type_1",
                type: "select",
                initialValue: getValueTypeOptionByValue(
                  initialRule.values[0].type_id,
                ),
                onChange: onValueTypeChange,
                options: selectValueTypeOptions,
                colStyle: { marginBottom: 0, padding: 5 },
                style: { marginBottom: 0, height: 35 },
              },
              buildValueInput(
                1,
                initialRule.values[0].value,
                initialRule.values[0].type_id,
              ),
              {
                name: "operator",
                type: "select",
                initialValue: getOperatorOptionByValue(initialRule.operator_id),
                options: selectOperatorOptions,
                colStyle: { marginBottom: 0, padding: 5 },
                style: { marginBottom: 0, height: 35 },
              },
              {
                name: "type_2",
                type: "select",
                initialValue: getValueTypeOptionByValue(
                  initialRule.values[1].type_id,
                ),
                onChange: onValueTypeChange,
                options: selectValueTypeOptions,
                colStyle: { marginBottom: 0, padding: 5 },
                style: { marginBottom: 0, height: 35 },
              },
              buildValueInput(
                2,
                initialRule.values[1].value,
                initialRule.values[1].type_id,
              ),
            ],
          ]}
        />
      </div>

      <div className="tw-flex tw-items-center tw-p-3">
        <button
          onClick={handleRemoveRule}
          className="tw-rounded-full tw-bg-red-600 tw-p-1 hover:tw-bg-red-700"
        >
          <LuX className="tw-text-white" style={{ height: 15, width: 15 }} />
        </button>
      </div>
    </div>
  );
});
