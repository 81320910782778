import { useContext } from "react";

//internal imports
import ButtonGroup from "../buttons/ButtonGroup";
import { DarkMode } from "../App";

//external imports
import { Row } from "react-bootstrap";

// A collections of actions encased in a widget
export default function ActionWidget({ actions, title = "Actions" }) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <div
      className={`view-container ${darkMode ? "view-container_dark" : "view-container_light"}`}
    >
      <span className={darkMode ? "title-font_dark" : "title-font"}>
        {title}
      </span>
      <Row className="mt-3">
        <ButtonGroup actions={actions} />
      </Row>
    </div>
  );
}
