/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";
import { Link } from "react-router-dom";

/* ---------------------------- internal imports ---------------------------- */

import SparkLogo from "../../../img/SparkLogo.png";
import SparkLogoWhite from "../../../img/SparkLogo-White.png";
import { DarkMode } from "../App";

// A header for the sidebar to contain the app logo
export default function SidebarHeader(props) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  if (!props.collapsed) {
    // Wide sidebar
    return (
      <Link to="/">
        <div
          className={`tw-flex tw-max-h-40 tw-content-center tw-items-center tw-border-b ${darkMode ? "tw-border-neutral-950" : " tw-border-neutral-300"}`}
        >
          <img
            style={{
              height: "100%",
              padding: 50,
              width: "100%",
              minHeight: "150px",
            }}
            src={darkMode ? SparkLogoWhite : SparkLogo}
          />
        </div>
      </Link>
    );
  } else {
    // Thin sidebar
    return (
      <Link to="/">
        <div className="tw-flex tw-content-center tw-items-center tw-align-middle">
          <img
            className="tw-w-full tw-px-2 tw-pb-2  tw-pt-4"
            src={darkMode ? SparkLogoWhite : SparkLogo}
          />
        </div>
      </Link>
    );
  }
}
