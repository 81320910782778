// Model class holds the methods that can be accessed by any models that inherit this class
export default class Model {
  // Builds to model using core data
  constructor(data, formatter = null) {
    for (const [key, value] of Object.entries(data)) {
      // If we have a formatter use that to set value
      if (formatter) {
        this[key] = formatter(key, value);
      } else {
        // else set don't use formatter
        this[key] = value;
      }
    }
  }
}
