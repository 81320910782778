/* ------------------------------ core imports ------------------------------ */
import {
  useState,
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { Form, Row } from "react-bootstrap";

/* ---------------------------- external imports ---------------------------- */
import * as Yup from "yup"; // Used to define the validation for our inputs
import { Formik } from "formik"; // Used to apply the form validation to our form
import toast from "react-hot-toast";

// Internal Imports
import APIClient from "../../services/clients/APIClient";
import LoadingSpinner from "../LoadingSpinner";
import FormSelect from "./inputs/FormSelect";
import FormCheckbox from "./inputs/FormCheckbox";
import FormInput from "./inputs/FormInput";
import FormulaBuilder from "./inputs/FormulaBuilder";
import FormulaResult from "./inputs/FormulaResult";
import FormFileUpload from "./inputs/FormFileUpload";
import FormDatePicker from "./inputs/FormDatePicker";
import FormTimePicker from "./inputs/FormTimePicker";
import FormDateTimePicker from "./inputs/FormDateTimePicker";
import FormDateRangePicker from "./inputs/FormDateRangePicker";
import FormTimeRangePicker from "./inputs/FormTimeRangePicker";
import FormDateTimeRangePicker from "./inputs/FormDateTimeRangePicker";
import { formatDateForBackend } from "../../services/data-formatting/dates";
import FormRadio from "./inputs/FormRadio";

export default forwardRef((props, ref) => {
  // deconstruct props object into constants
  const {
    id,
    inputData,
    setIsSubmitting,
    submitRoute, // (Optional) The route to use when submitting the data (only used if a onSubmit method is not specified)
    onSubmit, // (Optional) The method to run when the form is submitted
    submitData, // additional data to pass to the submit request
    onSuccess, // method to run after a successful async form submission
    submitMethod,
    resetOnSubmit = true, // (Optional) Should the form automatically reset when it is submitted successfully
    className,
    disabled, // should the form be disabled (uneditable)
    successMessage = "Form Submitted", // (Optional) Custom toast success message
    submittingMessage = "Submitting Form", // (Optional) Custom toast message for when the form is submitting
    style,
    onFormUpdate, // runs when any form input updates
    submitOnChange = false, // (Optional) should the form auto submit when a value changes?
    overwriteOnInputUpdate = false, // (Optional) should the initial values overwrite current form values when the parent updates the inputData
    useCols = false,
    gap = 3,
  } = props;

  /* --------------------------------- state --------------------------------- */
  const [initialValues, setInitialValues] = useState(null);
  const [validationRules, setValidationRules] = useState(null);
  const [containsFile, setContainsFile] = useState(false);

  /* ---------------------------------- refs ---------------------------------- */
  // setup formula refs used to update formula inputs when needed
  const formulaRefs = useRef({});

  const formRef = useRef();

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    let newValues = formRef.current?.values || {};
    let newValidationRules = [];

    // Iterate though inputData to build our form;
    inputData.flat(Infinity).forEach((inputDataItem) => {
      // if the input data has an initial value add it to our initial values array
      if (
        typeof inputDataItem?.initialValue !== "undefined" &&
        (overwriteOnInputUpdate || newValues[inputDataItem.name] == null)
      )
        newValues[inputDataItem.name] = inputDataItem.initialValue;

      // if input has validation rules add them to the validationRules object
      if (inputDataItem?.validation)
        newValidationRules[inputDataItem.name] = inputDataItem.validation;
      if (
        inputDataItem.type?.toLowerCase() === "file" ||
        inputDataItem.type?.toLowerCase() === "image"
      )
        setContainsFile(true);
    });

    // update initial values and validation rules
    setInitialValues(newValues);
    setValidationRules(newValidationRules);
  }, [inputData]);

  // use imperative handle allows the parent to access functions from this component
  useImperativeHandle(ref, () => ({
    refreshFormula() {
      // add refresh formula method to form
      // evaluate each formula
      for (let key in formulaRefs.current) {
        formulaRefs?.current[key]?.evaluateFormula();
      }
    },
    form() {
      return formRef.current;
    },
    from: formRef.current,
    formValues: formRef.current?.values,
  }));

  // Handles when the form is submitted including functionality for parent specific submitting method and a generic option for submitting to backend
  async function onFormSubmit(formData, { resetForm, setErrors }) {
    // If we have a setIsSubmitting function run it with the new value
    if (typeof setIsSubmitting == "function") setIsSubmitting(true);

    // Format the data so it is ready to be sent to the backend
    const data = formatDataForBackend(formData);

    // if we don't have a submit route then we let the parent handle the submit with a onSubmit method
    if (!submitRoute) {
      if (onSubmit) {
        await onSubmit(data).catch((error) => {
          console.error(error);
          if (typeof error?.data?.errors == "object") {
            // set backend errors to be displayed on the form
            setErrors(error.data.errors);
          } else if (error?.data?.message) {
            toast.error(`Request Failed ${error.data.message}`);
          }

          // If we have a setIsSubmitting function run it with the new value
          if (typeof setIsSubmitting == "function") setIsSubmitting(false);

          throw error;
        });

        if (resetOnSubmit) resetForm();

        // If we have a setIsSubmitting function run it with the new value
        if (typeof setIsSubmitting == "function") setIsSubmitting(false);

        return false;
      } else {
        throw "Either submitRoute or onSubmit must be provided to Form Component";
      }
    } else {
      // else if a submit route is specified then we can handle the submit within this component
      // get the method to be used to submit the data to the backend from the APIClient
      let method;
      switch (submitMethod) {
        case "patch":
          method = (route, data) =>
            APIClient.patch(route, { ...data, ...submitData }, containsFile);
          break;
        default: // post
          method = (route, data) =>
            APIClient.post(route, { ...data, ...submitData }, containsFile);
      }

      // submit data to backend using selected method
      const response = await toast.promise(method(submitRoute, data), {
        loading: submittingMessage,
        success: (response) => {
          // Run on Success Method
          if (onSuccess) onSuccess(response, data);

          if (resetOnSubmit) resetForm();

          // If we have a setIsSubmitting function run it with the new value
          if (typeof setIsSubmitting == "function") setIsSubmitting(false);

          return successMessage;
        },
        error: (err) => {
          console.error(err);
          // if we have a list of errors set them in the form
          if (typeof err.data.errors == "object") {
            // set backend errors to be displayed on the form
            setErrors(err.data.errors);
          }

          // If we have a setIsSubmitting function run it with the new value
          if (typeof setIsSubmitting == "function") setIsSubmitting(false);

          return `Form Submission Failed: ${err.data.message}`;
        },
      });

      return response;
    }
  }

  // Loops through each data item and formats it for the backend
  function formatDataForBackend(formData) {
    let formattedData = []; // array to hold form formatted data

    // Loop though each entry in formData object
    for (const [formDataKey, formDataItem] of Object.entries(formData)) {
      // Search the input data for the input that matches the key of the
      const matchingInput = inputData
        .flat(Infinity)
        .find((input) => input.name == formDataKey);

      // If matching input is found use it's type for format data
      if (matchingInput) {
        switch (matchingInput.type) {
          case "checkbox":
          case "toggle":
            // Convert true or false to 1 or 0
            formattedData[formDataKey] = formDataItem ? 1 : 0;
            break;
          case "select":
            // if the select input is a multi select we need to loop though the input as an array and build a new array with just the values (disregarding the labels)
            if (matchingInput.isMulti) {
              // use map to pull the value from each selected option
              formattedData[formDataKey] = formDataItem.map(
                (selectedOption) => {
                  return selectedOption?.value;
                },
              );
            } else {
              // Else we only expect one item so we just need to get the value (disregarding the label)
              formattedData[formDataKey] = formDataItem?.value || null;
            }
            break;
          case "file":
          case "image":
            // if the file(s) have been changed then upload to backend
            if (formDataItem && formDataItem != matchingInput.initialValue) {
              let filesToUpload = [];
              let filesToKeep = [];

              if (Array.isArray(formDataItem)) {
                formDataItem.forEach((file) => {
                  if (file instanceof File) {
                    filesToUpload.push(file);
                  } else if (file?.file instanceof File) {
                    filesToUpload.push(file.file);
                  } else {
                    filesToKeep.push(file?.origin);
                  }
                });

                formattedData[formDataKey] = filesToUpload;
                formattedData[formDataKey + "_to_keep"] = filesToKeep;
              } else {
                formattedData[formDataKey] = formDataItem;
              }
            }
            break;
          case "datetimerange":
            // if the date time range input has a value then we need to format it for the backend
            if (formDataItem) {
              // if the data is an array with two items then we have a range, this must be broken up into two separate values
              if (Array.isArray(formDataItem) && formDataItem.length == 2) {
                // format the date time range into two separate values
                formattedData[formDataKey + "Start"] = formatDateForBackend(
                  formDataItem[0],
                );
                formattedData[formDataKey + "End"] = formatDateForBackend(
                  formDataItem[1],
                );
              }
            }
            break;
          case "date":
            if(formDataItem){
              formattedData[formDataKey] = formatDateForBackend(formDataItem);
            }
            break;
          default:
            // All other form input types require no formatting
            formattedData[formDataKey] = formDataItem;
        }
      } 
      // If the formDataItem does not have a matching input then we ignore it
    }

    // Return formatted data
    return formattedData;
  }

  function recursiveRenderInput(
    inputDataItem,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    touched,
    isValid,
    errors,
    isSubmitting,
    submitCount,
    setFieldValue,
    colIndex = 0, // new parameter for column index
  ) {
    if (Array.isArray(inputDataItem)) {
      return inputDataItem.map((data, index) => {
        return (
          <div
            className={`tw-flex tw-w-full tw-gap-${gap} ${
              // if we are using both columns then alternate between flex-row and flex-col
              useCols == "both"
                ? `${colIndex % 2 == 0 ? "tw-flex-col" : "tw-flex-row"}`
                : `${useCols ? "tw-flex-col" : "tw-flex-row"}`
            }`}
          >
            {/* use colIndex to assign a CSS class */}
            {recursiveRenderInput(
              data,
              handleSubmit,
              handleChange,
              handleBlur,
              setValues,
              values,
              touched,
              isValid,
              errors,
              isSubmitting,
              submitCount,
              setFieldValue,
              colIndex + 1,
            )}
          </div>
        );
      });
    } else {
      return (
        <div
          className={`tw-mb-3 tw-w-full ${inputDataItem.isVisible == false ? "tw-hidden" : ""}`}
          style={inputDataItem.colStyle}
          key={inputDataItem.name}
        >
          {renderInput(
            inputDataItem,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            touched,
            submitCount,
            isSubmitting,
            errors,
          )}
        </div>
      );
    }
  }
  // renders a single input component based on a single inputData
  function renderInput(
    inputData,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    submitCount,
    isSubmitting,
    errors,
  ) {
    // if the input is visible then render it
    switch (inputData.type.toLowerCase()) {
      case "checkbox":
      case "toggle":
        return (
          <FormCheckbox
            id={`${id}-${inputData.name}`}
            {...inputData} // Spread other options to pass to component
            // if tooltip is set then pass it to the component
            tooltip={inputData.tooltip}
            style={inputData.elementStyle}
            value={values[inputData.name]}
            onChange={(event) => {
              // Update form value
              setFieldValue(inputData.name, event.target.checked);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(event.target.checked);
            }}
            onBlur={handleBlur}
            isInvalid={
              !disabled &&
              (touched[inputData.name] || submitCount > 0) &&
              errors[inputData.name]
            }
            feedback={errors[inputData.name]}
            isDisabled={isSubmitting || disabled}
          />
        );
      case "image":
        return (
          <FormFileUpload
            buttonLabel={`Upload Image${inputData.limit != 1 ? "s" : ""}`}
            accept=".png,.jpg,.jpeg,.webp,.gif"
            {...inputData} // Spread other options to pass to component (Overwrites above options)
            value={values[inputData.name]}
            type="file"
            label={inputData.label}
            onChange={(file) => {
              // pass event to formic to handle data
              setFieldValue(inputData.name, file);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(file);
            }}
            feedback={errors[inputData.name]}
            onBlur={handleBlur}
            isValid={
              !disabled && touched[inputData.name] && !errors[inputData.name]
            }
            isInvalid={
              !disabled &&
              (touched[inputData.name] || submitCount > 0) &&
              errors[inputData.name]
            }
            isDisabled={isSubmitting || disabled}
          />
        );
      case "file":
        return (
          <FormFileUpload
            {...inputData} // Spread other options to pass to component (Overwrites above options)
            value={values[inputData.name]}
            type="file"
            label={inputData.label}
            style={inputData.elementStyle}
            onChange={(file) => {
              // pass event to formic to handle data
              setFieldValue(inputData.name, file);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(file);
            }}
            feedback={errors[inputData.name]}
            onBlur={handleBlur}
            isValid={
              !disabled && touched[inputData.name] && !errors[inputData.name]
            }
            isInvalid={
              !disabled &&
              (touched[inputData.name] || submitCount > 0) &&
              errors[inputData.name]
            }
            isDisabled={isSubmitting || disabled}
          />
        );
      case "select":
        return (
          <FormSelect
            {...inputData} // Spread other options to pass to component
            value={values[inputData.name]}
            onChange={(data, element) => {
              // pass event to formic to handle data
              setFieldValue(inputData.name, data);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
            isDisabled={isSubmitting || disabled}
            feedback={errors[inputData.name]}
            style={inputData.elementStyle}
            onBlur={handleBlur}
            isValid={
              !disabled && touched[inputData.name] && !errors[inputData.name]
            }
            isInvalid={
              !disabled &&
              (touched[inputData.name] || submitCount > 0) &&
              errors[inputData.name]
            }
          />
        );
      case "date":
        return (
          <FormDatePicker
            {...inputData} // Spread other options to pass to component
            value={values[inputData.name]}
            isDisabled={isSubmitting || disabled}
            feedback={errors[inputData.name]}
            onChange={(data, element) => {
              setFieldValue(inputData.name, data);

              // @matthew - please check this implementation - I removed your date parser as it was causing issues with the date picker, it appears that the date picker is already formatting the date correctly.
              // if this works that we should also move it to spark.

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
          />
        );
      case "time":
        return (
          <FormTimePicker
            {...inputData} // Spread other options to pass to component
            value={values[inputData.name]}
            isDisabled={isSubmitting || disabled}
            onChange={(data, element) => {
              setFieldValue(inputData.name, data);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
          />
        );
      case "datetime":
        return (
          <FormDateTimePicker
            {...inputData} // Spread other options to pass to component
            value={values[inputData.name]}
            isDisabled={isSubmitting || disabled}
            onChange={(data, element) => {
              setFieldValue(inputData.name, data);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
          />
        );
      case "daterange":
        return (
          <FormDateRangePicker
            {...inputData} // Spread other options to pass to component
            value={values[inputData.name]}
            isDisabled={isSubmitting || disabled}
            onChange={(data, element) => {
              setFieldValue(inputData.name, data);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
          />
        );
      case "timerange":
        return (
          <FormTimeRangePicker
            {...inputData} // Spread other options to pass to component
            value={values[inputData.name]}
            isDisabled={isSubmitting || disabled}
            onChange={(data, element) => {
              setFieldValue(inputData.name, data);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
          />
        );
      case "datetimerange":
        return (
          <FormDateTimeRangePicker
            {...inputData} // Spread other options to pass to component
            value={values[inputData.name]}
            isDisabled={isSubmitting || disabled}
            onChange={(data, element) => {
              setFieldValue(inputData.name, data);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
            onBlur={handleBlur}
            isValid={
              !disabled && touched[inputData.name] && !errors[inputData.name]
            }
            isInvalid={
              !disabled &&
              (touched[inputData.name] || submitCount > 0) &&
              errors[inputData.name]
            }
            feedback={errors[inputData.name]}
          />
        );
      case "formula-builder":
        return (
          <FormulaBuilder
            {...inputData} // Spread other options to pass to component
            value={values[inputData.name]}
            onChange={(data, element) => {
              setFieldValue(inputData.name, data);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
            onBlur={handleBlur}
            isDisabled={isSubmitting || disabled}
            isInvalid={
              !disabled &&
              (touched[inputData.name] || submitCount > 0) &&
              errors[inputData.name]
            }
            feedback={errors[inputData.name]}
          />
        );
      case "formula-result":
        return (
          <FormulaResult
            {...inputData} // Spread other options to pass to component
            ref={(element) => (formulaRefs.current[inputData.name] = element)}
            onChange={(data) => {
              setFieldValue(inputData.name, data);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
            onBlur={handleBlur}
            isValid={
              !disabled && touched[inputData.name] && !errors[inputData.name]
            }
            isInvalid={
              !disabled &&
              (touched[inputData.name] || submitCount > 0) &&
              errors[inputData.name]
            }
            feedback={errors[inputData.name]}
          />
        );
      case "radio":
        return (
          <FormRadio
            id={`${id}-${inputData.name}`}
            {...inputData} // Spread other options to pass to component
            value={values[inputData.name]}
            label={inputData.label}
            options={inputData.options}
            isDisabled={isSubmitting || disabled}
            onChange={(data, element) => {
              // pass event to formic to handle data
              setFieldValue(inputData.name, data.target.defaultValue);

              // run custom on change (if it exists)
              if (inputData.onChange) inputData.onChange(data, element);
            }}
          />
        );
      default:
        // get matching value from values array
        const value = values[inputData.name];
        return (
          <FormInput
            {...inputData} // Spread other options to pass to component
            value={value?.value ? value.value : value}
            style={inputData.elementstyle}
            subtype={inputData.subtype}
            onChange={(event) => {
              handleChange(event);
              // run custom on change (if it exists)
              if (inputData.onChange)
                inputData.onChange(event.target.value, event.target);
            }}
            onBlur={handleBlur}
            isValid={
              !disabled && touched[inputData.name] && !errors[inputData.name]
            }
            isInvalid={
              !disabled &&
              (touched[inputData.name] || submitCount > 0) &&
              errors[inputData.name]
            }
            isDisabled={isSubmitting || disabled}
            feedback={errors[inputData.name]}
            metric={inputData.metric}
          />
        );
    }
  }

  /* --------------------------- pre jsx computation -------------------------- */

  // Return our Formik form with validation and the inputs based on the data within inputData
  return initialValues ? (
    <Formik
      enableReinitialize={true}
      validationSchema={Yup.object().shape(validationRules)}
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      innerRef={formRef}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        submitCount,
        setFieldValue,
      }) => {
        // when the form values change
        useEffect(() => {
          // if there are any values
          if (Object.keys(values).length != 0) {
            // run parent on form update (if it exists)
            if (onFormUpdate) onFormUpdate(values);

            // if we submit on change handle submit
            if (submitOnChange) handleSubmit();
          }
        }, [values]);
        return (
          <Form
            id={id}
            noValidate
            onSubmit={handleSubmit}
            className={className || ""}
            style={{ ...style }}
          >
            <fieldset
              className={`${useCols && "tw-flex tw-flex-row tw-gap-7"}`}
            >
              {recursiveRenderInput(
                inputData,
                handleSubmit,
                handleChange,
                handleBlur,
                setValues,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                submitCount,
                setFieldValue,
              )}
            </fieldset>
            {isSubmitting ? <LoadingSpinner /> : null}
          </Form>
        );
      }}
    </Formik>
  ) : (
    <LoadingSpinner />
  );
});
