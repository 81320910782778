// index page, this is the entry point for the react app
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.jsx";

export default function Home() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

if (document.getElementById("app")) {
  const domNode = document.getElementById("app");
  const root = ReactDOM.createRoot(domNode);
  root.render(<Home />);
}
