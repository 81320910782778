/* ------------------------------ core imports ------------------------------ */
import { useEffect } from "react";

//external imports
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

export default function StepBar({ steps, progress, backgroundLineColour }) {
  useEffect(() => {}, [steps]);

  if (steps) {
    return (
      <ProgressBar
        hasStepZero={false}
        percent={(progress / steps.length) * 100}
        filledBackground={backgroundLineColour}
      >
        {/* foreach steps in steps, create a new step */}
        {steps.map((step, index) => {
          return (
            <Step key={index} transition="scale">
              {({ accomplished }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {/* if the step.icon is a function run it else display it */}
                  {typeof step.icon === "function" ? step.icon() : step.icon}
                </div>
              )}
            </Step>
          );
        })}
      </ProgressBar>
    );
  } else {
    return (
      <div className="text-center">
        No steps provided - please check your configuration
      </div>
    );
  }
}
