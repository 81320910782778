import { useState, useEffect } from "react";

// Internal Imports
import Modal from "../../../components/modals/Modal";
import UserProfileDetailsForm from "./UserProfileDetailForm";
import DataBadge from "../../../components/data-display/DataBadge";
import { formatDateForDatatable } from "../../../services/data-formatting/dates";
import RemoveUserFromProfileModal from "./RemoveUserFromProfileModal";
import AuthService from "../../../services/AuthService";
import FormWidget from "../../../components/widgets/FormWidget";

// structure
import ProfileWidget from "../../../components/widgets/ProfileWidget";
import DynamicLayout from "../../../components/layouts/DynamicLayout";

// The main component of the user manager pages used to manage users within the system
export default function UserProfileDetail({
  visible,
  user,
  profile,
  onHide,
  onUpdateUser,
  setVisibility,
}) {
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  // When user or profile updates update canEdit
  useEffect(() => {
    if (user != null && user.pivot != null && profile != null) {
      setCanEdit(
        user.pivot.role_id <= profile.current_user_role_id ||
          AuthService.checkPermission("manage profiles"),
      );
    } else {
      // If any data is missing default to can't edit
      setCanEdit(false);
    }
  }, [user, profile]);

  // Handles when a user starts removing a user
  function handleBeginRemovingUser() {
    // hide user profile detail modal
    setVisibility(false);

    // Show deleted user modal
    setShowDeleteUserModal(true);
  }

  // Handles when the user canceled removing a user
  function onCancelRemovingUser() {
    // hide user profile detail modal
    setVisibility(true);

    // Show deleted user modal
    setShowDeleteUserModal(false);
  }

  // Handles what we do after removing a user from this modal
  function onRemoveUser() {
    // run parents on update user method
    onUpdateUser();

    // Close Modal
    onHide();
  }

  if (user) {
    return (
      <>
        <Modal
          visible={visible}
          size="xl"
          onHide={onHide}
          title="User Detail"
          size="4xl"
          actions={
            canEdit
              ? [
                  {
                    label: "Remove User",
                    variant: "danger",
                    key: "remove",
                    type: "submit",
                    onClick: handleBeginRemovingUser,
                  },
                ]
              : []
          }
        >
          <DynamicLayout
            data={[
              {
                gap: 5,
                cols: [
                  {
                    width: 4,
                    content: (
                      <ProfileWidget user={user} padding={10}>
                        <DataBadge
                          label="ID"
                          value={user.id}
                          tooltipPosition="top"
                        />
                        <DataBadge
                          label="Last Active"
                          value={formatDateForDatatable(user.last_active_at)}
                          tooltipPosition="top"
                        />
                        <DataBadge
                          label="Email Verified"
                          value={formatDateForDatatable(user.email_verified_at)}
                          tooltipPosition="top"
                        />
                        <DataBadge
                          label="Registered"
                          value={formatDateForDatatable(user.created_at)}
                          tooltipPosition="top"
                        />
                        <DataBadge
                          label="Accepted"
                          value={formatDateForDatatable(user.pivot.accepted_at)}
                          tooltipPosition="top"
                        />
                        <DataBadge
                          label="Invited At"
                          value={formatDateForDatatable(user.pivot.created_at)}
                          tooltipPosition="top"
                        />
                      </ProfileWidget>
                    ),
                  },
                  {
                    width: 8,
                    content: (
                      <div className="tw-p-7">
                        <UserProfileDetailsForm
                          canEdit={canEdit}
                          user={user}
                          onSuccess={onUpdateUser}
                        />
                      </div>
                    ),
                  },
                ],
              },
            ]}
          />
        </Modal>
        {canEdit && (
          <RemoveUserFromProfileModal
            user={user}
            profile={profile}
            visible={showDeleteUserModal}
            onHide={onCancelRemovingUser}
            onSuccess={onRemoveUser}
          />
        )}
      </>
    );
  } else {
    return null;
  }
}
