/* ------------------------------ core imports ------------------------------ */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs
import { Button, Container, Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";

// Internal Imports
import Form from "../components/forms/Form";
import APIClient from "../services/clients/APIClient";
import useParams from "../services/useParams";
import Spark from "../../img/SparkLogo.png";
import Linen from "../../img/black-linen.png";

// PasswordReset.js
export default function PasswordReset() {
  // get navigator from react-router-dom
  const navigate = useNavigate();

  // get params from url
  const { token, email } = useParams();

  /* --------------------------------- state --------------------------------- */
  const [isSubmitting, setIsSubmitting] = useState(false); // stores errors for the backend

  // - styles -
  const styles = {
    background: {
      background: "rgb(119 119 119)",
      backgroundRepeat: "repeat",
      backgroundImage: `url(${Linen})`,
    },
  };

  // Runs when the form submits
  const onSubmit = async (data) => {
    data.token = token;
    data.email = email;
    await APIClient.post("forgot-password-return", data)
      .then((response) => {
        // launch success toast
        toast.success(
          "Your password has been reset, please login to the system with your new password.",
        );

        // Navigate to login
        navigate("/login");

        return response;
      })
      .catch((error) => {
        // handle errors with email or toast (other errors are handled by the form)
        if (error.data) {
          if (error.data.errors.email) {
            toast.error(
              "Request failed" +
                error.data.errors.email[0] +
                " Sending another password reset email may fix this.",
            );
          } else if (error.data.errors.token) {
            toast.error(
              "Request failed" +
                error.data.errors.token[0] +
                " Sending another password reset email may fix this.",
            );
          }
        }

        return error;
      });
  };

  return (
    <>
      <div
        className="tw-flex tw-h-screen tw-items-start tw-justify-center sm:tw-items-center "
        style={styles.background}
      >
        {/* white container box */}
        <div className="tw-m-5 tw-max-w-full tw-rounded-lg tw-bg-white tw-p-10 tw-shadow-md sm:tw-max-w-md sm:tw-p-14 md:tw-m-0">
          {/* spark logo */}
          <div className="tw-mb-5 tw-flex tw-justify-center">
            <img src={Spark} alt="Bolt Logo" className="tw-h-auto tw-w-1/2" />
          </div>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <h2 className="tw-mb-3 tw-text-center tw-font-sans tw-text-2xl">
                Password Reset
              </h2>
              <p className="tw-text-md tw-mb-5 tw-text-center tw-font-sans">
                Please enter your new password below.
              </p>
              <Form
                submitMethod="post"
                //onSubmit={onSubmit}
                setIsSubmitting={setIsSubmitting}
                submitRoute={"forgot-password-return"}
                successMessage={
                  "Your password has been reset, please login to the system with your new password."
                }
                submitData={{ token, email }}
                onSuccess={() => navigate("/login")} // Navigate to login page on success
                id="reset-password"
                className="pt-3"
                inputData={[
                  {
                    name: "password",
                    label: "New password",
                    placeholder: "Enter your new password",
                    type: "password",
                    validation: Yup.string()
                      .min(10, "New password must be at least 10 characters")
                      .required("New password is required")
                      .matches(
                        new RegExp(process.env.MIX_PASSWORD_REGEX),
                        "The new password must contain one uppercase and lowercase character as well as one number and special character",
                      ),
                  },
                  {
                    name: "password_confirmation",
                    label: "Confirm password",
                    placeholder: "Confirm your new password",
                    type: "password",
                    validation: Yup.string()
                      .required("Confirm password is required")
                      .oneOf(
                        [Yup.ref("password"), null],
                        "New password and confirm password must match",
                      ),
                  },
                ]}
              />
              <button
                className="button-regular tw-mt-2 tw-w-full tw-text-center"
                type="submit"
                form="reset-password"
                disabled={isSubmitting}
              >
                Set new password
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
