/* ------------------------------ core imports ------------------------------ */
import { useState, useEffect, useContext } from "react";

/* ---------------------------- internal imports ---------------------------- */

import AuthService from "../services/AuthService";
import MenuItem from "./navigation/MenuItem";
import SubMenu from "./navigation/SubMenu";
import SidebarFooter from "./sider-layouts/SiderFooter";
import SidebarHeader from "./sider-layouts/SiderHeader";
import ExampleSubMenu from "./navigation/ExampleSubMenu";
import { DarkMode } from "./App";
import SiderContent from "./widgets/SiderContent";

// - external imports -
import { Sidebar, Menu, menuClasses } from "react-pro-sidebar";
import { LuUser, LuBuilding2, LuCpu, LuBot } from "react-icons/lu";

export default function Sider(props) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */
  const [user, setUser] = useState(AuthService.currentUser);
  const [collapsed, setCollapsed] = useState(
    !AuthService.currentUser.sider_open,
  );
  const includeExamplesPages = process.env.MIX_INCLUDE_EXAMPLE_PAGES == "true";

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    // Attach callback to Auth Service so the current user updates when the auth service updates
    AuthService.addUpdateUserCallback("sider", setUser);
  }, []);

  // - function -
  // toggles the sidebar open status
  function toggleSidebar() {
    setCollapsed(!collapsed);
    collapsed
      ? AuthService.changeSiderOpen(true)
      : AuthService.changeSiderOpen(false);
  }

  // if the screen size goes below 500px, collapse the sidebar
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 700) {
        setCollapsed(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // - styles -
  const themes = {
    true: {
      sidebar: {
        backgroundColor: "#1e293b",
        color: "white",
      },
      menu: {
        icon: "white",
        hover: {
          backgroundColor: "#00458b",
          color: "#b6c8d9",
        },
      },
    },
    false: {
      sidebar: {
        backgroundColor: "#f1f5f9",
        color: "#607489",
      },
      menu: {
        icon: "white",
        hover: {
          backgroundColor: "#f3f4f6",
        },
      },
    },
  };

  // stateFull styles
  const menuItemStyles = {
    root: {
      fontSize: "15px",
      fontWeight: 400,
    },
    icon: {
      color: darkMode ? "white" : "#1e293b",
      fontSize: "20px",
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    button: ({ level, active, disabled }) => {
      if (level === 0)
        return {
          color: darkMode ? "white" : "black",
          "&:hover": {
            backgroundColor: darkMode ? "#374151" : "#e2e8f0",
            color: darkMode ? " white" : "#374151",
          },
        };
      else if (level === 1)
        return {
          color: darkMode ? "white" : "black",
          backgroundColor: darkMode ? "#374151" : "#e2e8f0",
          "&:hover": {
            backgroundColor: darkMode ? "#546074" : "#d1d9e4",
            color: darkMode ? "white" : "#1e293b",
          },
        };
      else
        return {
          color: darkMode ? "white" : "black",
          backgroundColor: darkMode ? "#546074" : "#d1d9e4",
          "&:hover": {
            backgroundColor: darkMode ? "#5c687d" : "#c8d0db",
            color: darkMode ? "white" : "#1e293b",
          },
        };
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  /* --------------------------- pre jsx computation -------------------------- */
  // defines if the profile pages should be included
  const includeProfilePages =
    process.env.MIX_FEATURE_ORGANISATION_PROFILES == "true";

  /* --------------------------------- markup --------------------------------- */
  return (
    // container
    <div className="tw-flex">
      {/* sidebar config */}
      <Sidebar
        collapsed={collapsed}
        rootStyles={{
          backgroundColor: themes[darkMode].sidebar.backgroundColor,
          color: themes[darkMode].sidebar.color,
        }}
      >
        <div
          className={`${darkMode ? "" : "tw-border"} tw-flex tw-h-full tw-flex-col tw-justify-between`}
        >
          <div>
            <SidebarHeader collapsed={collapsed} />

            <Menu menuItemStyles={menuItemStyles} className="tw-pt-5">
              <SubMenu
                label="System"
                icon={<LuCpu />}
                permissions={["see admin navigation"]}
                rootStyles={{
                  ["." + menuClasses.subMenuContent]: {
                    backgroundColor: themes[darkMode].menu.subMenuContent,
                  },
                }}
              >
                <MenuItem label="Developer Menu" to="/developer-menu" />
                <MenuItem
                  label="User Manager"
                  to="/user-manager"
                  permissions={["view users"]}
                />

                {includeProfilePages ? (
                  <MenuItem
                    label="Profile Manager"
                    to="/profiles"
                    permissions={["view profiles"]}
                  />
                ) : null}
              </SubMenu>

              {/* If the user has an active profile */}
              <SubMenu
                permissions={["manage active profile"]}
                label={user?.active_profile?.name}
                icon={<LuBuilding2 />}
                rootStyles={{
                  ["." + menuClasses.subMenuContent]: {
                    backgroundColor: themes[darkMode].menu.subMenuContent,
                  },
                }}
              >
                <MenuItem
                  label="Profile Detail"
                  to={`/profile/${user?.active_profile?.id}`}
                />
              </SubMenu>

              <SubMenu
                label="User"
                icon={<LuUser />}
                rootStyles={{
                  ["." + menuClasses.subMenuContent]: {
                    backgroundColor: themes[darkMode].menu.subMenuContent,
                  },
                }}
              >
                <MenuItem label="Dashboard" to="/" />
                <MenuItem label="My Account" to="/account" />
              </SubMenu>

              <SubMenu
                label="AI Apps"
                icon={<LuBot />}
                rootStyles={{
                  ["." + menuClasses.subMenuContent]: {
                    backgroundColor: themes[darkMode].menu.subMenuContent,
                  },
                }}
              >
                <MenuItem label="Dialler" to="/caller" />
                <MenuItem label="Transcribe" to="/transcribe" />
              </SubMenu>
              {/* Loads example options SubMenu */}
              {includeExamplesPages ? <ExampleSubMenu themes={themes} /> : null}
            </Menu>
          </div>
          {/* <SiderContent
            title="Welcome to Spark"
            body="This is a demo application for the Spark Framework, a Laravel and React based application framework."
            collapsed={collapsed}
          /> */}
        </div>
        <SidebarFooter collapsed={collapsed} toggleSidebar={toggleSidebar} />
      </Sidebar>
    </div>
  );
}
