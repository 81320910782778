import axios from "axios";
import toast from "react-hot-toast";

// used to make requests to an api using axios
// will handle and display any errors
export default class Client {
  // runs a get request using axios
  static async get(route, data = {}, headers = {}) {
    // get data from current route
    const response = await axios
      .get(route, { params: data, headers })
      .catch(this.axiosErrorHandler);
    // Return response data if response is not null
    if (response == null) return null;
    else return response.data;
  }

  //runs a get request using axios and downloads the response
  static async download(route, fileName, data, headers = {}) {
    // get data from current route
    const response = await axios({
      url: route,
      params: data,
      method: "GET",
      responseType: "blob",
      headers,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch(this.axiosErrorHandler);
    // Return response data if response is not null
    return response.data;
  }

  // runs a post request using axios
  static async post(route, data, asFormData = false, headers = {}) {
    if (asFormData) {
      const formData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
        if (value instanceof File) {
          formData.append(key, value, value.name);
        }
      });
      data = formData;
    }

    // get data from current route
    const response = await axios
      .post(route, data, { headers })
      .catch(this.axiosErrorHandler);
    return response.data;
  }

  // runs a delete request using axios
  static async delete(route, data, headers = {}) {
    // run axios delete request
    const response = await axios
      .delete(route, { headers }, data)
      .catch(this.axiosErrorHandler);

    // Return response data if response is not null
    return response.data;
  }

  // runs a patch request using axios
  static async patch(route, data, asFormData = false, headers = {}) {
    if (asFormData) {
      const formData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        if (value instanceof File) {
          // if file append as file
          formData.append(key, value, value.name);
        } else if (value instanceof Array) {
          // if array append each value in array as array item
          // if the array is empty send empty array
          if (value.length == 0) {
            formData.append(key, value);
          } else {
            // else append each item in the array as separate data item
            value.forEach((singleValue) => {
              formData.append(`${key}[]`, singleValue);
            });
          }
        } else {
          // append as simple variable
          formData.append(key, value);
        }
      });

      formData.append("_method", "PATCH");
      const response = await axios
        .post(route, formData, { headers })
        .catch(this.axiosErrorHandler);
      return response.data;
    } else {
      // get data from current route
      const response = await axios
        .patch(route, data, { headers })
        .catch(this.axiosErrorHandler);
      return response.data;
    }
  }

  static axiosErrorHandler(error) {
    if (
      typeof error.response != "undefined" &&
      typeof error.response.status != "undefined"
    ) {
      switch (error.response.status) {
        case 429: // Too Many Requests
          toast.error(
            "To many requests have been made in a short time, please take a short break and try again",
          );
          throw error.response;
        case 500:
          // Customise error and return
          error.response.data.message = `500: ${error.response.data.message} - Please refresh the page and try again.`;
          throw error.response;
        default:
          // throw error to be handled by caller
          throw error.response;
      }
    } else if (typeof error.message != "undefined") {
      // Print Error to console
      console.error(error);

      // Launch toast for user to see
      toast.error(`Request Failed - ${error.message}`);

      throw error;
    } else {
      // Print Error to console
      console.error(error);

      // Launch toast for user to see
      toast.error(`Request Failed - Unknown Issue`);

      throw error;
    }
  }

  // handles when a unauthorized response is received
  static async unauthorized(error) {
    // throw error response
    throw error;
  }
}
