import { useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";

/* ----------------------------- internal import ---------------------------- */
import StepBarWidget from "./StepBarWidget";
import { LuX } from "react-icons/lu";
import { DarkMode } from "../App";

/* ---------------------------- external imports ---------------------------- */
import { useNavigate } from "react-router-dom";

export default function StepAssistant(props) {
  // destruct props passed from parent
  const {
    steps, // An array of objects containing the step content to be shown as the user progresses
    leaveWarning = false, // should the user receive a warning if they attempt to close the page
    leaveWarningMessage = "Are you sure you want to finish?", // the message to be displayed when a user attempts to leave the page prematurely
    finishRoute, // The route the user should be redirect to upon completing all steps
    stepCounter, // The current step the user should be on (If null we will use defaultStepCounter)
    setStepCounter, // A method to update the current step (if null we will use setDefaultStepCounter)
    onStepChange, // A method to be called when the step changes
  } = props;

  /* --------------------------------- config --------------------------------- */
  const navigate = useNavigate();

  /* --------------------------------- state --------------------------------- */
  const [defaultStepCounter, setDefaultStepCounter] = useState(0);

  /* --------------------------------- context -------------------------------- */
  const { darkMode } = useContext(DarkMode);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    setIsPrevDisabled(getCurrentStep()?.disablePrevious);
  }, [stepCounter, defaultStepCounter]);

  /* -------------------------------- functions ------------------------------- */
  // handles when the user finishes the final step of the step assistant
  function handleFinish(finished) {
    if (leaveWarning && !finished) {
      const result = window.confirm(leaveWarningMessage);
      if (!result) return;
    }

    if (finishRoute) {
      navigate(finishRoute);
    } else {
      toast.error("Developer has not set an end route.");
    }
  }

  // handles moving to the next step (after verification)
  async function handleNext() {
    const currentStep = getCurrentStep();

    // if next condition is false deny them from progressing
    if (
      currentStep?.nextCondition != null &&
      currentStep?.nextCondition == false
    ) {
      toast.error(
        currentStep.nextConditionMessage ||
          "Please complete the current step before proceeding.",
      );
      // if we have a check method we should use it to verify if the user can progress
    } else if (currentStep.checkMethod && !(await currentStep.checkMethod())) {
      toast.error(
        currentStep.nextConditionMessage ||
          "Please complete the current step before proceeding.",
      );
    } else {
      // if we are on the last step we can handle finish
      if (isLastStep) {
        handleFinish(true);
        // else move to next step
      } else {
        if (currentStep.onStepChange) currentStep.onStepChange();
        progressStep();
      }
    }
  }

  // gets the current step using either the stepCounter or the defaultStepCounter
  function getCurrentStep() {
    return steps[stepCounter || defaultStepCounter];
  }

  // increments the step counter
  function progressStep(distance = 1) {
    // if we don't have a step counter increment the default one
    if (stepCounter == null) {
      setDefaultStepCounter(defaultStepCounter + distance);
    } else {
      // else increment the step counter (from parent)
      setStepCounter(stepCounter + distance);
    }
  }

  /* --------------------------- pre jsx computation -------------------------- */
  const isLastStep = (stepCounter || defaultStepCounter) === steps.length - 1;
  const step = getCurrentStep();

  // return jsx
  return (
    <>
      {!isLastStep && (
        <button
          className={darkMode ? "sa-close-button_dark" : "sa-close-button"}
          onClick={() => handleFinish(false)}
        >
          <LuX />
        </button>
      )}

      <StepBarWidget
        steps={steps}
        progress={(stepCounter || defaultStepCounter) + 1}
      />
      <div className="sa-body" style={step.style}>
        <div className="sa-header">{step.title}</div>
        {step.body}
      </div>
      {(stepCounter || defaultStepCounter) >= 1 && !isPrevDisabled ? (
        <button className="sa-prev-button" onClick={() => progressStep(-1)}>
          Previous
        </button>
      ) : null}
      <button className="sa-next-button" onClick={handleNext}>
        {isLastStep ? "Finish" : "Next"}
      </button>
    </>
  );
}
