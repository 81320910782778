/* ------------------------------ core imports ------------------------------ */
import { useState, useContext } from "react";

/* ---------------------------- internal imports ---------------------------- */

import ConfirmationModal from "../components/modals/ConfirmationModal";
import AuthService from "../services/AuthService";
import { DarkMode } from "../components/App";
import Typography from "./examples/modals/Typography";

/* ---------------------------- external imports ---------------------------- */
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

// Dashboard.js
export default function Dashboard() {
  // - context -
  const { darkMode } = useContext(DarkMode);

  // - state / vars -
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showTypographyModal, setShowTypographyModal] = useState(false);

  // setting up the pages
  const pages = {
    "Component Example Pages": [
      { name: "Forms", path: "/example/forms/basic" },
      { name: "Step Assistant", path: "/example/step-assistant" },
      { name: "Card", path: "/example/cards/cards" },
      { name: "Datatables", path: "/example/datatables/async" },
      { name: "Coloured Counters", path: "/example/coloured-counters" },
      { name: "Dynamic Structure", path: "/example/layouts/dynamic-layout" },
    ],
    Authentication: [
      { name: "Login", path: "/login" },
      { name: "Forgot Password", path: "/forgot-password" },
      { name: "Password Reset", path: "/password-reset" },
      { name: "Blocked", path: "/blocked" },
      { name: "Logout", path: "/logout" },
    ],
    "Core System Pages": [
      { name: "Dashboard", path: "/" },
      { name: "404 Error Page", path: "/404" },
      { name: "Focus Mode", path: "/focus-structure" },
    ],
    "Core System Modals": [
      {
        name: "Confirmation Modal",
        onClick: () => setShowConfirmationModal(true),
      },
    ],
    "Email Verification": [
      { name: "Request Verify Email", path: "/verify-email" },
      { name: "Email Verification", path: "/verify-email/1" },
    ],
    User: [{ name: "My Account", path: "/account" }],
    Admin: [{ name: "User Manager", path: "/user-manager" }],
    Toasts: [
      { name: "Standard", onClick: () => toast("Standard Toast") },
      { name: "Success", onClick: () => toast.success("Success Toast") },
      { name: "Error", onClick: () => toast.error("Error Toast") },
    ],
    Design: [
      { name: "Typography", onClick: () => setShowTypographyModal(true) },
    ],
  };

  // - return -
  return (
    <>
      <div className="mt-3 tw-grid tw-grid-cols-1">
        <p className="mb-3">
          Welcome to the Developer Menu. This is a list of all the pages and
          components that are available to you. This will give you a good idea
          of what components are available to you and how they look.
        </p>
        {/* loop through the sections */}
        {Object.keys(pages).map((key, index) => {
          return (
            <div key={index} className="tw-mr-2 tw-flex tw-flex-col">
              <h6>{key}</h6>
              <div className="tw-mb-5 tw-flex tw-flex-row tw-gap-3">
                {/* loop through the pages */}
                {pages[key].map((page, index) => {
                  return (
                    <Link key={index} to={page?.path}>
                      <button
                        className={
                          darkMode ? "button-regular_dark" : "button-regular"
                        }
                        onClick={page?.onClick}
                      >
                        {page.name}
                      </button>
                    </Link>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {/* confirmation modal component */}
      <ConfirmationModal
        bodyContent="Are you sure?"
        buttons={[
          {
            label: "Confirm",
            variant: "primary",
            onClick: () => console.log("Callback"),
            key: "key",
          },
        ]}
        title="Are you sure?"
        message={"Are you sure you want to do this?"}
        visible={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      />
      <Typography
        visible={showTypographyModal}
        onHide={() => setShowTypographyModal(false)}
      />
    </>
  );
}
