/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

/* ---------------------------- external imports ---------------------------- */
import DatePicker from "react-date-picker";
import { Form } from "react-bootstrap";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { DarkMode } from "../../App";

// form input for selecting a date
export default function FormDatePicker(props) {
  // destruct props passed from parent
  const { 
    isDisabled, 
    isRequired, 
    value, 
    format = "dd/MM/yyyy",
    feedback, // (Optional) The feedback message to be displayed when the input is invalid
  } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  // - jsx -
  return (
    <Form.Group style={{ display: "flex", flexDirection: "column" }}>
      {props.label && (
        <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
          {props.label}
        </Form.Label>
      )}
      <DatePicker
        disabled={isDisabled}
        required={isRequired}
        clearIcon={null}
        calendarAriaLabel="Toggle calendar"
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        yearAriaLabel="Year"
        clearAriaLabel="Clear value"
        {...props} // problem here
        format={format}
        className={`${darkMode ? "spark-datetimeformat-wrapper_dark" : "spark-datetimeformat-wrapper"}`}
        value={
          value && value != "Invalid Date"
            ? value instanceof Date
              ? value
              : new Date(value)
            : null
        } // if the value is not a date attempt to turn into a date
        calendarClassName={
          darkMode ? "spark-date-picker_dark" : "spark-date-picker"
        }
        // Using Inner Ref to fix Safari only issue where the date picker closes when clicking on the calendar see https://app.clickup.com/t/8693npfvz
        inputRef={(ref) => {
          ref?.setAttribute("tabindex", "0");
        }}
      />
      <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    </Form.Group>
  );
}
