import Modal from "./Modal";

export default function ConfirmationModal({
  visible,
  onHide,
  title,
  message,
  actions,
}) {
  return (
    <Modal visible={visible} onHide={onHide} title={title} actions={actions}>
      <p>{message}</p>
    </Modal>
  );
}
