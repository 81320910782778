/* ------------------------------ core imports ------------------------------ */
import { useRef } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs

// Internal Imports
import ToggleableForm from "../../../components/forms/ToggleableForm";

const multipleProfilesAllowed =
  process.env.MIX_PROFILE_USER_HAS_MULTIPLE_PROFILES == "true";

export default function UserDetailsForm({ user, setUser }) {
  const updateUserForm = useRef();

  // Runs when the form submit is successful
  const onSuccess = (response) => {
    setUser(response);

    // Update values based on what is returned from the backend
    updateUserForm.current.form().setValues({
      forename: response.forename,
      surname: response.surname,
      email: response.email,
      roles: response.roles.map(({ name, id }) => {
        return { label: name, value: id };
      }),
    });
  };

  return (
    <ToggleableForm
      ref={updateUserForm}
      submitRoute={`user/${user.id}`}
      submitMethod="patch"
      submitData={{ include: ["roles", "profiles"] }}
      onSuccess={onSuccess}
      id="update-other-user-details"
      resetOnSubmit={false}
      inputData={[
        {
          name: "forename",
          label: "Forename(s)",
          placeholder: "Users forename(s)",
          type: "text",
          initialValue: user.forename,
          validation: Yup.string().required("Forename is required").max(255),
          colSize: 6,
        },
        {
          name: "surname",
          label: "Surname",
          placeholder: "Users Surname",
          type: "text",
          initialValue: user.surname,
          validation: Yup.string().required("Surname is required").max(255),
          colSize: 6,
        },
        {
          name: "email",
          label: "Email",
          placeholder: "Users Email",
          type: "email",
          initialValue: user.email,
          validation: Yup.string()
            .email()
            .required("Email is required")
            .max(255),
          colSize: 12,
        },
        {
          name: "roles",
          label: "Roles",
          type: "select",
          // Map users roles as default roles
          defaultValue: user.roles.map(({ name, id }) => {
            return { label: name, value: id };
          }),
          asyncRoute: "role",
          asyncMap: ({ name, id }) => {
            return { label: name, value: id };
          },
          isMulti: true,
          colSize: 6,
        },
        {
          name: "profiles",
          label: multipleProfilesAllowed ? "Profiles" : "Profile",
          type: "select",
          // Map users roles as default roles
          defaultValue: user.profiles.map(({ name, id }) => {
            return { label: name, value: id };
          }),
          asyncRoute: "profile",
          asyncMap: ({ name, id }) => {
            return { label: name, value: id };
          },
          isMulti: multipleProfilesAllowed,
          colSize: 6,
        },
      ]}
    />
  );
}
