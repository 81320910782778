/* ------------------------------ core imports ------------------------------ */
import { useContext, useState, Fragment } from "react";

// Internal Imports
import { DarkMode } from "../../App";

// external imports
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import { Form } from "react-bootstrap";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { Menu, Transition } from "@headlessui/react";
import { LuChevronDown } from "react-icons/lu";

export default function FormDateTimeRangePicker(props) {
  const { name, label, feedback, isInvalid } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  // setting the default value of the date picker
  const [value, setValue] = useState(
    props.value ? props.value : [new Date(), new Date()],
  );

  // get the current day of the week
  const today = new Date().getDay();

  // create an array of days starting with "Today" and "Tomorrow", and then add the days of the week based on the current day
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const days = ["Today", "Tomorrow"];
  for (let i = today + 2; i < today + 7; i++) {
    days.push(daysOfWeek[i % 7]);
  }
  days.push("Next Week", "Next Month");

  const handleQuickDateChange = (date) => {
    // set time to existing value for both dates
    const startDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      props.value[0].getHours(),
      props.value[0].getMinutes(),
      props.value[0].getSeconds(),
    );
    const endDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      props.value[1].getHours(),
      props.value[1].getMinutes(),
      props.value[1].getSeconds(),
    );
    setValue([startDate, endDate]);

    // run custom on change (if it exists)
    if (props.onChange) props.onChange([startDate, endDate]);
  };

  const handleQuickTimeChange = (minutes) => {
    console.log({ value });

    // add minutes to existing value for both dates
    const startDate = value[0];
    const endDate = new Date(startDate.getTime() + minutes * 60000);
    setValue([startDate, endDate]);

    // run custom on change (if it exists)
    if (props.onChange) props.onChange([startDate, endDate]);
  };

  if (value) {
    return (
      <>
        <Form.Group style={{ display: "flex", flexDirection: "column" }}>
          {props.label && (
            <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
              {props.label}
            </Form.Label>
          )}
          <DateTimeRangePicker
            name={props.name}
            value={value}
            disabled={props.isDisabled}
            required={props.isRequired}
            clearIcon={null}
            minDate={new Date(1900, 0, 1)}
            calendarAriaLabel="Toggle calendar"
            clearAriaLabel="Clear value"
            calendarClassName={
              darkMode ? "spark-date-picker_dark" : "spark-date-picker"
            }
            className={`${darkMode ? "spark-datetimeformat-wrapper_dark" : "spark-datetimeformat-wrapper"}`}
            disableClock={true}
            {...props}
            onChange={(data) => {
              setValue(data);

              // run custom on change (if it exists)
              if (props.onChange) props.onChange(data);
            }}
            // Using Inner Ref to fix Safari only issue where the date picker closes when clicking on the calendar see https://app.clickup.com/t/8693npfvz
            inputRef={(ref) => {
              ref?.setAttribute("tabindex", "0");
            }}
          />
          <Form.Control.Feedback
            type="invalid"
            style={{ display: `${isInvalid ? "block" : "none"}` }}
          >
            {feedback}
          </Form.Control.Feedback>
        </Form.Group>
        {/* quick add buttons */}
        <div className="tw-mt-3 tw-grid tw-grid-cols-2 tw-gap-4">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                className={
                  darkMode
                    ? "quick-selection-picker_dark"
                    : "quick-selection-picker"
                }
              >
                Quick Dates
                <LuChevronDown />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="tw-transition tw-ease-out tw-duration-100"
              enterFrom="tw-transform tw-opacity-0 tw-scale-95"
              enterTo="tw-transform tw-opacity-100 tw-scale-100"
              leave="tw-transition tw-ease-in tw-duration-75"
              leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
              leaveTo="tw-transform tw-opacity-0 tw-scale-95"
            >
              <Menu.Items
                className={`${darkMode ? "tw-bg-hue-700" : "tw-bg-white"} origin-top-right tw-absolute tw-z-10 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none`}
              >
                <div className="tw-py-1">
                  {/* loop through the days array and create the menu items */}
                  {days.map((day, index) => {
                    // if the index is 8, then we are on the "Next Month" option
                    const date =
                      index != 8
                        ? new Date(
                            new Date().setDate(new Date().getDate() + index),
                          )
                        : new Date(
                            new Date().setMonth(new Date().getMonth() + 1),
                          );
                    return (
                      <Menu.Item key={index}>
                        <a
                          onClick={() => handleQuickDateChange(date)}
                          className={`${darkMode ? "tw-text-white hover:tw-bg-hue-900" : "tw-text-hue-700 hover:tw-bg-hue-100"} tw-text tw-block tw-px-4 tw-py-2  tw-text-sm tw-font-normal tw-no-underline`}
                        >
                          {day}
                        </a>
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                className={
                  darkMode
                    ? "quick-selection-picker_dark"
                    : "quick-selection-picker"
                }
              >
                Quick Times <LuChevronDown />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="tw-transition tw-ease-out tw-duration-100"
              enterFrom="tw-transform tw-opacity-0 tw-scale-95"
              enterTo="tw-transform tw-opacity-100 tw-scale-100"
              leave="tw-transition tw-ease-in tw-duration-75"
              leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
              leaveTo="tw-transform tw-opacity-0 tw-scale-95"
            >
              <Menu.Items
                className={`${darkMode ? "tw-bg-hue-700" : "tw-bg-white"} origin-top-right tw-absolute tw-z-10 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none`}
              >
                <div className="tw-py-1">
                  {/* create the menu items for adding 15-minute intervals */}
                  {[5, 10, 15, 30, 45, 60].map((minutes, index) => {
                    const label = `${minutes} minutes`;
                    return (
                      <Menu.Item key={index}>
                        <a
                          onClick={() => handleQuickTimeChange(minutes)}
                          className={`${darkMode ? "tw-text-white hover:tw-bg-hue-900" : "tw-text-hue-700 hover:tw-bg-hue-100"} tw-text tw-block tw-px-4 tw-py-2  tw-text-sm tw-font-normal tw-no-underline`}
                        >
                          {label}
                        </a>
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </>
    );
  } else {
    return <h1>Loading</h1>;
  }
}
