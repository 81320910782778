/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

/* ---------------------------- internal imports ---------------------------- */

import { DarkMode } from "../App";

/* ---------------------------- external imports ---------------------------- */
import { Row, Col, Tooltip, OverlayTrigger, Button } from "react-bootstrap";

export default function DataBadge(props) {
  // destruct props passed from parent
  const {
    label, // text to be displayed on the left, should describe the value
    value, // text to be displayed on the right
    single,
    tooltipPosition, // the position of the on hover tooltip
    className, // the classes applied to the badge by the parent
  } = props;

  // - context imports -
  const { darkMode } = useContext(DarkMode);

  if (single) {
    return (
      <div className={`tw-mr-2 tw-mt-2 ${className}`}>
        <div
          className={
            darkMode ? "data-badge-container_dark" : "data-badge-container"
          }
        >
          <div className="data-badge-badge">{label}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`tw-mr-2 tw-mt-2 ${className}`}
        style={{ maxWidth: "100%" }}
      >
        <div
          className={
            darkMode ? "data-badge-container_dark" : "data-badge-container"
          }
        >
          <div className="data-badge-badge">{label}</div>
          {/* if toolTipPosition exists then display the tooltip */}
          {(tooltipPosition && (
            <OverlayTrigger
              placement={tooltipPosition}
              overlay={<Tooltip>{value}</Tooltip>}
            >
              <p
                className="mx-3"
                style={{
                  flexGrow: 1,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {value}
              </p>
            </OverlayTrigger>
          )) || (
            // else...
            <p
              className="mx-3"
              style={{
                flexGrow: 1,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {value}
            </p>
          )}
        </div>
      </div>
    );
  }
}
