import styled from "styled-components";

export const InvitationAnnouncement = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
  margin: 0 0 0.5rem 0;
  text-align: center;
`;

export const InvitationParagraph = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  color: #262626;
  margin: 0 0 0.5rem 0;
  text-align: center;
`;
