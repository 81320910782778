/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// local imports
import { DarkMode } from "../components/App";

export default function Dashboard() {
  // - context -
  const { darkMode } = useContext(DarkMode);

  // - render -
  return (
    <div className="tw-h-96">
      <h3>Welcome to Spark AI</h3>
      <p>Spark AI is a portal designed for the various AI tool we're currently working on.</p>
    </div>
  );
}
