// limits an array based on the weighting as specified in a getWeight method defaults to array of strings using .length for character length
export function truncateArray(
  array,
  maxWeight = 30,
  getWeight = (value) => value.length,
  addEllipsis = true,
  Ellipsis = "...",
) {
  let totalWeightCounted = 0;
  let arrayIndex = 0;
  let truncatedArray = [];

  if (!array) return array; // If array is null or undefined return it (no need to truncate

  // if array length is not 0
  if (array.length != 0) {
    // While the arrayIndex is included in the array and weight of new item plus next item is less than the total requested weight
    while (
      array[arrayIndex]
        ? totalWeightCounted + getWeight(array[arrayIndex]) < maxWeight
        : false
    ) {
      truncatedArray.push(array[arrayIndex]); // Add item to truncated array
      totalWeightCounted += getWeight(array[arrayIndex]); // Add item weight to total weight
      arrayIndex++; // increment array index so we can look at next item in array
    }

    // Add Ellipsis if set to true and there are more items in the array
    if (addEllipsis && array[arrayIndex]) {
      truncatedArray.push(Ellipsis);
    }
  }

  // Return truncated array
  return truncatedArray;
}
