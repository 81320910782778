// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs

// Internal Imports
import Modal from "../../../components/modals/Modal";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import Form from "../../../components/forms/Form";
import LoadingSpinner from "../../../components/LoadingSpinner";
import APIClient from "../../../services/clients/APIClient";

export default function RemoveProfileConfirmation({
  onHide,
  visible,
  profile,
  onSuccess: pOnSuccess,
}) {
  // Removes a user from the system based on the current selected user
  function deleteProfile() {
    APIClient.delete(`profile/${profile}`).then(() => {
      pOnSuccess();
      onHide();
    });
  }

  return (
    <ConfirmationModal
      visible={visible}
      onHide={onHide}
      title={"Are you sure?"}
      message={`Are you sure you want to remove this profile?`}
      actions={[
        {
          label: "Remove Profile",
          variant: "danger",
          onClick: () => deleteProfile(),
        },
      ]}
    ></ConfirmationModal>
  );
}
