/* ------------------------------ core imports ------------------------------ */
import { useState, useEffect } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs

// Internal Imports
import Modal from "../../../components/modals/Modal";
import Form from "../../../components/forms/Form";
import Roles from "../../../services/data-formatting/profileRoles";

export default function AddUserToProfileModal({
  onHide,
  visible,
  onSuccess: pOnSuccess,
  profileID,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formInputs, setFormInputs] = useState([]); // Stores form inputs so they can be changed dynamically
  const [message, setMessage] = useState(""); // Message to be displayed at the top of the modal

  // Runs when the modal is set to visible or not visible
  useEffect(() => {
    // if visible is being set to true reset form
    if (visible) {
      // set form inputs to include only email
      setFormInputs([
        {
          name: "email",
          label: "Email Address",
          placeholder: "Enter Email Address",
          type: "email",
          validation: Yup.string()
            .email()
            .required("Email is required")
            .max(255),
        },
        {
          name: "role_id",
          label: "Role",
          placeholder: "Users forename(s)",
          type: "select",
          validation: Yup.object().required("Role is required"),
          options: [
            { label: Roles(1), value: 1 },
            { label: Roles(2), value: 2 },
          ],
        },
      ]);

      // Set message to it's initial values
      setMessage(
        <p className="p-3">
          Please enter the email of the user you wish to add to this profile -
          if they do not currently have an account, we'll create one for them.
        </p>,
      );
    }
  }, [visible]);

  // Runs when the form submit is successful
  const onSuccess = async (data) => {
    // If a user was returned
    if (data.data) {
      // run parent's on success
      pOnSuccess();

      // Hide Modal
      onHide();
    } else {
      // set form inputs to include forename and surname
      setFormInputs([
        {
          name: "email",
          label: "Email Address",
          placeholder: "Enter Email Address",
          type: "email",
          validation: Yup.string()
            .email()
            .required("Email is required")
            .max(255),
        },
        {
          name: "role_id",
          label: "Role",
          type: "select",
          validation: Yup.object().required("Role is required"),
          options: [
            { label: Roles(1), value: 1 },
            { label: Roles(2), value: 2 },
          ],
        },
        {
          name: "forename",
          label: "Forename(s)",
          placeholder: "Enter forename(s)",
          type: "text",
          validation: Yup.string().required("Forename is required").max(255),
        },
        {
          name: "surname",
          label: "Surname",
          placeholder: "Enter surname",
          type: "text",
          validation: Yup.string().required("Surname is required").max(255),
        },
      ]);

      // Set message to state that the user was not found
      setMessage(
        <p className="p-3">
          We did not find a user connected to the email entered. Check the email
          is right or if you want to register the user so they can create an
          account enter a forename and surname.
        </p>,
      );
    }
  };

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      title={"Add User to Profile"}
      actions={[
        {
          label: "Invite User",
          variant: "primary",
          key: "invite",
          type: "submit",
          form: "invite-user-to-profile",
          disabled: isSubmitting,
        },
      ]}
    >
      {message}
      <hr />
      <Form
        submitRoute={`profile/${profileID}/user`}
        submitMethod="post"
        resetOnSubmit={false}
        setIsSubmitting={setIsSubmitting}
        id="invite-user-to-profile"
        onSuccess={onSuccess}
        inputData={formInputs}
        successMessage={"User invited to profile"}
        launchToasts={false}
      />
    </Modal>
  );
}
