// Provides a place to quickly change how we format dates system wide

import moment from "moment";

import {
  isStringLongDate,
  isStringABackendDate,
} from "../data-validation/dates";

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const dayNamesShort = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthNamesShort = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const backendDateFormat = "YYYY-MM-DDThh:mm:ss.000000Z";

function gNumberSuffix(number) {
  // if the number is between 3 and 21 then we always return a "th" else use remainder after modulus 10 to defile suffix
  if (number > 3 && number < 21) {
    return "th";
  } else {
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
}

export function formatDateForBackend(date, includeTime = true) {
  return moment(date, backendDateFormat).format(
    `YYYY-MM-DD${includeTime ? " HH:mm:ss" : ""}`,
  );
}

// export function formatDateForDatatable(backendDateString) {
//   if (backendDateString != null) {
//     return moment(backendDateString, backendDateFormat).calendar(null, {
//       sameElse: "DD/MM/YYYY",
//     });
//   } else {
//     return "-";
//   }
// }

export function formatDateForDatatable(value, format = backendDateFormat) {
  if (isStringLongDate(value)) {
    // if the string matches the long date format (used be meta data dates)
    return moment(value, "ddd MMM DD YYYY").calendar(null, {
      sameElse: "DD/MM/YYYY",
    });
  } else if (isStringABackendDate(value)) {
    // else if the string matches the BackendDate format (used by old meta data dates)
    return moment(value, format).calendar(null, {
      sameElse: "DD/MM/YYYY",
    });
  } else {
    return "-";
  }
}

export function formatDateForDateTimeFormInput(backendDateString) {
  if (backendDateString != null) {
    return moment(backendDateString, backendDateFormat).format(
      "YYYY-MM-DDTHH:mm",
    );
  } else {
    return "-";
  }
}

// Return a date time formatted for long text display
export function formatDateTimeLongText(date) {
  return `${formatDateLongText(date)} at ${date.toLocaleTimeString("en-US")}`;
}

// Return a date formatted for long text display
export function formatDateLongText(date) {
  return `${dayNames[date.getDay()]} the ${date.getDate()}${gNumberSuffix(date.getDate())} of ${monthNames[date.getMonth()]} ${date.getUTCFullYear()}`;
}

// Return a date time formatted for text display
export function formatDateTimeText(date) {
  return `${formatDateText(date)} at ${date.toLocaleTimeString("en-US")}`;
}

// Return a date formatted for text display
export function formatDateText(date) {
  return `${dayNamesShort[date.getDay()]}, ${date.getDate()}${gNumberSuffix(date.getDate())} of ${monthNamesShort[date.getMonth()]} ${date.getUTCFullYear()}`;
}

// gets the time between two dates and returns the time between them
export function timeBetween(dateA, dateB) {
  // subtract dateB from dateA giving the milliseconds between, then convert to date and generate ISOString
  const time = new Date(dateA - dateB).toISOString().slice(11, 19);
  return time == "23:59:59" ? "1 Day" : time;
}
