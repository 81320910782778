export {
  validateMetaDataDate,
  isStringABackendDate,
  isStringLongDate,
  isPast,
  isFuture,
};

// validates that a string is a valid meta data date
function validateMetaDataDate(string) {
  // check if the string is a date in the following format 2020/11/20
  if (isStringABackendDate(string)) {
    return true;
    // check if the string is a date starts with the following format Fri Aug 15 2020
  } else if (isStringLongDate(string)) {
    return true;
  } else {
    return false;
  }
}

// check if the string is a date in the following format 2020/11/20
function isStringABackendDate(string) {
  return string?.match(/^\d{1,5}-\d{1,2}-\d{1,2}/);
}

// check if the string is a date starts with the following format Fri Aug 15 2020
function isStringLongDate(string) {
  return string?.match(
    /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{1,2} \d{1,5}/,
  );
}

// check if the date is in the past
function isPast(date) {
  const today = new Date();
  const then = new Date(date);

  return then < today;
}

// check if the date is in the future
function isFuture(date) {
  const today = new Date();
  const then = new Date(date);

  return then > today;
}
