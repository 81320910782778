/* ------------------------------ core imports ------------------------------ */
import { useState, useRef } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs
import Toggle from "react-toggle";
import { VscEdit, VscSave } from "react-icons/vsc";
import ToggleableForm from "../../../components/forms/ToggleableForm";

import Form from "../../../components/forms/Form";

export default function UserDetailsForm({ user, setUser }) {
  const [isEditing, setIsEditing] = useState(false);
  const updateUserForm = useRef();

  // Runs when the form submit is successful
  const onSuccess = (response) => {
    setUser(response);

    // Update values based on what is returned from the backend
    updateUserForm.current.form().setValues(
      {
        forename: response.forename,
        surname: response.surname,
        email: response.email,
      },
      false,
    );

    // Reset green ticks on form items be setting them all to untouched (unchanged)
    updateUserForm.current.form().setTouched({}, false);
    setIsEditing(false);
  };

  return (
    <>
      <ToggleableForm
        // toggleable form props
        canEdit={true}
        // form props
        ref={updateUserForm}
        submitRoute={`authed-user`}
        submitMethod="patch"
        submitData={{ include: ["roles"] }}
        onSuccess={onSuccess}
        gap={1}
        id="update-other-user-details"
        resetOnSubmit={false}
        useCols={true}
        inputData={[
          [
            {
              name: "forename",
              label: "Forename(s)",
              type: "text",
              initialValue: user.forename,
              validation: Yup.string()
                .required("Forename is required")
                .max(255),
            },
            {
              name: "surname",
              label: "Surname",
              type: "text",
              initialValue: user.surname,
              validation: Yup.string().required("Surname is required").max(255),
            },
          ],
        ]}
      />
    </>
  );
}
