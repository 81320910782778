// External Imports
import { SubMenu as RpsSubMenu } from "react-pro-sidebar";
import AuthService from "../../services/AuthService";

export default function SubMenu(props) {
  let isVisible = true;

  // Get permissions from props or get empty array if there are no permissions
  const permissions = props.permissions || [];

  // Loop through each permission checking that the user has each one
  permissions.forEach((permission) => {
    // If the user does not have the permission then the menu item should not be visible
    if (!AuthService.checkPermission(permission)) {
      isVisible = false;
    }
  });

  if (isVisible) {
    return <RpsSubMenu {...props} />;
  } else {
    return null;
  }
}
