import Modal from "../../../components/modals/Modal";

export default function Typography({ visible, onHide }) {
  return (
    <Modal
      visible={visible}
      title="Typography"
      onHide={onHide}
      // size="xl"
      actions={[{ label: "Close", key: "close", onClick: onHide }]}
    >
      <div className="tw-my-10 tw-flex tw-flex-col tw-gap-3">
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
        <p>Paragraph</p>
        <code>Code</code>
      </div>
    </Modal>
  );
}
