/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// Internal Imports
import ProfilePicture from "../ProfilePicture";
import placeholder from "../../../img/placeholder.png";
import { DarkMode } from "../App";
import { Image } from "react-feather";

// Component used to create a profile picture for a given user and size
export default function ProfileWidget({
  user,
  size,
  children,
  image,
  padding,
}) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  // setup image component based on properties from parent
  let imageComponent;
  if (user) {
    // If a user is passed in create a profile picture using the user object
    imageComponent = <ProfilePicture user={user} size="large" />;
  } else if (image) {
    // if an image is passed in use the image for the profile picture
    imageComponent = (
      <img className="profile-invite-image" src={image} alt="profile image" />
    );
  } else {
    // If we have no user or image is passed in use default image
    imageComponent = (
      <img
        className="profile-invite-image"
        src={placeholder}
        alt="profile image"
      />
    );
  }

  // Return profile widget
  return (
    <div
      className={`${darkMode ? "view-container_dark" : "view-container"} tw-flex tw-flex-col tw-items-center`}
    >
      <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
        {imageComponent}
      </div>
      <div className="dataBadgeHolder">{children}</div>
    </div>
  );
}
