/* ------------------------------ core imports ------------------------------ */
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

/* ---------------------------- external imports ---------------------------- */
import { LuUserPlus, LuTrash, LuEye, LuTrash2 } from "react-icons/lu";
import { Container, Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";

// Internal Imports
import Datatable from "../../components/tables/Datatable";
import { truncate } from "../../services/data-formatting/strings";
import ProfileDetailsForm from "./elements/ProfileDetailsForm";
import APIClient from "../../services/clients/APIClient";
import RemoveProfileModal from "./elements/RemoveProfileModal";
import AuthService from "../../services/AuthService";
import ButtonGroup from "../../components/buttons/ButtonGroup";
import AddUserToProfile from "./elements/AddUserToProfileModal";
import UserProfileDetailModal from "./elements/UserProfileDetail.modal";
import RemoveUserFromProfileModal from "./elements/RemoveUserFromProfileModal";
import Profile from "../../models/Profile";
import {
  InvitationAnnouncement,
  InvitationParagraph,
} from "../../../styles/ProfileDetail.style";
import DTPFP from "../../components/DT_Pfp";
import DataRibbon from "../../components/data-display/DataRibbon";
import NavigationWidget from "../../components/widgets/NavigationWidget";

//structure
import FormWidget from "../../components/widgets/FormWidget";
import DataTableWidget from "../../components/widgets/DataTableWidget";
import ProfileWidget from "../../components/widgets/ProfileWidget";
import User from "../../models/User";
import DynamicLayout from "../../components/layouts/DynamicLayout";

// Setup enum to hold users profile role
const role = {
  member: 1,
  manager: 2,
  owner: 3,
};

export default function ProfileDetail() {
  let navigate = useNavigate();

  const [profilePicture, _setProfilePicture] = useState(null);
  const [profile, setProfile] = useState(null);
  const [profileInvite, setProfileInvite] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [userBelongsToProfile, setUserBelongsToProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [ribbonData, setRibbonData] = useState([]);

  const actions = [
    {
      label: "Remove Profile",
      variant: "danger",
      style: { width: "100%" },
      icon: <LuTrash2 />,
      onClick: () => setShowDeleteModal(true),
    },
  ];

  //get the profile id from the url
  const params = useParams();

  // ref to the users datatable
  const userDatatableRef = useRef();

  // Runs when the param.id changed taken from the URL (runs on page load)
  useEffect(() => {
    // Get Profile Data
    fetchProfile();

    // If the datatable is setup already it needs refreshing
    if (userDatatableRef.current) {
      // Refresh Users datatable
      userDatatableRef.current.refresh();
    }
  }, [params.id]);

  useEffect(() => {
    if (profile) {
      // set profile piture
      setProfilePicture(profile.picture); // set profile picture from profile if not null
    }
  }, [profile]);

  useEffect(() => {
    if (profileInvite) setProfilePicture(profileInvite.picture); // set profile picture from profile invite if not null
  }, [profileInvite]);

  useEffect(() => {
    // If the page is done loading and there is no profile or profile invite the user should not be here
    if (!isLoading && !profile && !profileInvite) {
      // if the user is not connected to the profile and is not invited to the profile navigate them away from the page
      navigate("/profile-not-found");
    }
  }, [isLoading]);

  // checking to see if profile details are available for the ribbon
  useEffect(() => {
    if (!profile) return;
    let ribbonData = [
      {
        label: `${profile.users_count} users`,
        icon: LuUserPlus,
      },
      {
        label: `${profile.invited_users_count} pending invites`,
        icon: LuUserPlus,
      },
    ];
    setRibbonData(ribbonData);
  }, [profile]);

  function openUserProfileDetailModal(user) {
    setSelectedUser(new User(user.original));
    setShowUserProfileModal(true);
  }

  // launches the remove profile modal
  function launchRemoveUserModal(data) {
    setSelectedUser(new User(data.original));
    setShowRemoveUserModal(true);
  }

  // handles what happens after we remove a user from the profile
  function onRemoveUser() {
    // If we removed the current user and they do not have permission to manage profiles then redirect them to home
    if (
      selectedUser.id == AuthService.currentUser.id &&
      !AuthService.checkPermission("manage profiles")
    ) {
      navigate("/");
    } else {
      // Refresh users datatable
      userDatatableRef.current.refresh();
    }
  }

  // Uses the passed in profile to create a profile picture that can be used within an img component/element
  function setProfilePicture(profilePicture) {
    if (profilePicture) {
      if (profilePicture.size) {
        // if a profile has a size property, it is a file
        // converting to base64
        const reader = new FileReader();
        reader.readAsDataURL(profilePicture);
        reader.onloadend = () => {
          _setProfilePicture(reader.result);
        };
      } else {
        // if a profile does not have a size property, it is a base64 string
        // checking to see if the profile picture object has loaded in yet
        if (Object.keys(profilePicture).length != 0) {
          //profile picture has loaded in, displaying to UI
          _setProfilePicture(profilePicture);
        }
      }
    } else {
      // If we have no picture set the profile picture to null
      _setProfilePicture(null);
    }
  }

  // Fetches the profile from the backend using the ID in the URL
  async function fetchProfile() {
    // Attempt to get profile details
    await APIClient.get(`profile/${params.id}`, {
      includeImages: true,
      include: ["currentUserRole"],
      includeCount: ["users", "invitedUsers"],
    })
      .then((response) => {
        setProfile(new Profile(response.data));
        setUserBelongsToProfile(true); // If we have a profile then the user must belong to it
      })
      .catch((error) => {
        // If we don't have a profile we should check for a profile invite
        setUserBelongsToProfile(false);
      });

    fetchProfileInvite(); // Fetch profile invite data from auth service
    setIsLoading(false); // Once Profile invite is set we are finished Loading
  }

  // Sets the profile invite state using the current users profile invites along with the ID from the URL
  function fetchProfileInvite() {
    setProfileInvite(
      AuthService.currentUser.profile_invites.find(
        (profile) => profile.id == params.id,
      ),
    );
  }

  // delete a profile
  async function deleteProfile() {
    await APIClient.delete(`profile/${params.id}`);
    window.location.href = "/profiles";
  }

  // Sends a request to the backend to accept the profile invitation, then the profile data is refreshed
  async function acceptProfileInvite() {
    // Submit request using toast to show response
    await APIClient.patch(`profile/${params.id}/accept`)
      .then((response) => {
        toast.success(`Profile invites accepted - ${response.message}`);
      })
      .catch((error) => {
        toast.error(
          `Failed to accept profile invitation - ${error.data.message}`,
        );
      });

    // Refresh user auth
    await AuthService.isAuthenticated();

    // fetch updated profile
    fetchProfile();
  }

  // Sends a request to the backend to decline and remove a profile invitation.
  async function declineProfileInvite() {
    // Submit request using toast to show response
    await APIClient.delete(`profile/${params.id}/invite`)
      .then((response) => {
        toast.success(`Profile invites declined - ${response.message}`);
        navigate("/");
      })
      .catch((error) => {
        toast.error(
          `Failed to accept profile invitation - ${error.data.message}`,
        );
      });

    // Refresh user auth
    await AuthService.isAuthenticated();

    // fetch updated profile invite
    fetchProfileInvite();
  }

  if (isLoading) {
    return <h1>Loading...</h1>;
  } else if (profileInvite) {
    return (
      <ProfileWidget image={profilePicture}>
        <span className="tw-w-full tw-px-4 tw-text-center">
          <InvitationAnnouncement>
            You have an invite to join {profileInvite.name}
          </InvitationAnnouncement>
          <InvitationParagraph>
            If you wish to join of {profileInvite.name} you can accept the
            request below. You may also decline if you think this is likely a
            mistake.
          </InvitationParagraph>
        </span>
        <ButtonGroup
          className="tw-content-center"
          actions={[
            {
              label: "Accept",
              key: "accept",
              onClick: acceptProfileInvite,
              size: "lg",
              className: "tw-text-xl tw-mx-4",
            },
            {
              variant: "danger",
              label: "Decline",
              key: "decline",
              onClick: declineProfileInvite,
              size: "lg",
              className: "tw-text-xl tw-mx-4",
            },
          ]}
        />
      </ProfileWidget>
    );
  } else if (userBelongsToProfile) {
    return (
      <>
        <DataRibbon data={ribbonData} />
        {(profile.current_user_role_id == role.owner ||
          AuthService.checkPermission("manage profiles")) && (
          <NavigationWidget
            actions={actions}
            // if the user can manage profile they can return to profiles
            backRoute={
              AuthService.checkPermission("manage profiles")
                ? "/profiles"
                : null
            }
          />
        )}

        <DynamicLayout
          data={[
            {
              gap: 5,
              cols: [
                {
                  width: 4,
                  content: (
                    <>
                      <ProfileWidget
                        key="profile-widget"
                        image={profilePicture}
                      />

                      <FormWidget title="Details">
                        <ProfileDetailsForm
                          canEdit={
                            profile.current_user_role_id == role.owner ||
                            profile.current_user_role_id == role.manager ||
                            AuthService.checkPermission("manage profiles")
                          }
                          profile={profile}
                          setProfile={(data) => setProfile(new Profile(data))}
                          profileID={params.id}
                        />
                      </FormWidget>
                    </>
                  ),
                },
                {
                  width: 8,
                  content: [
                    <Datatable
                      initialState={{
                        sortBy: [
                          {
                            id: "pivot.created_at",
                            desc: true,
                          },
                        ],
                      }}
                      route={`profile/${params.id}/users`}
                      requestData={{
                        includeInvites: 1,
                        include: ["pivotProfileRole"],
                      }}
                      hasExport={true}
                      hasRefresh={true}
                      ref={userDatatableRef}
                      isDropdown={true}
                      paginated={true}
                      columns={[
                        {
                          header: "Picture",
                          accessorKey: "picture",
                          enableSorting: false,
                          size: 28,
                          cell: ({ row }) => (
                            <DTPFP
                              forename={{ values: row.original.forename }}
                              surname={{ values: row.original.surname }}
                              id={{ values: row.original.id }}
                            />
                          ),
                        },
                        {
                          header: "Forename",
                          accessorKey: "forename",
                          cell: ({ getValue }) => truncate(getValue(), 15),
                        },
                        {
                          header: "Surname",
                          accessorKey: "surname",
                          cell: ({ getValue }) => truncate(getValue(), 15),
                        },
                        {
                          header: "Email",
                          accessorKey: "email",
                          cell: ({ getValue }) => truncate(getValue(), 30),
                        },
                        {
                          header: "Role",
                          accessorKey: "pivot.role_id",
                          cell: ({ row }) => row.original?.pivot?.role?.name,
                        },
                        {
                          header: "Status",
                          accessorKey: "pivot.accepted_at",
                          cell: ({ getValue }) =>
                            getValue() ? "Accepted" : "Pending",
                        },
                      ]}
                      // only display headerActions if the user has permission to edit the profile
                      headerActions={
                        profile.current_user_role_id == role.owner ||
                        profile.current_user_role_id == role.manager ||
                        AuthService.checkPermission("manage profiles")
                          ? [
                              {
                                icon: <LuUserPlus />,
                                label: "Add User to Profile",
                                type: "headerButton",
                                key: "add-profile",
                                onClick: () => setShowAddUserModal(true),
                              },
                            ]
                          : []
                      }
                      rowActions={
                        profile.current_user_role_id > 1 ||
                        AuthService.checkPermission("manage profiles")
                          ? (rowData) => {
                              // Return options for actions dropdown
                              return rowData.original.pivot.accepted_at // if user has accepted the invitation
                                ? // If current user's role is at least equal to the user then show actions
                                  rowData.original.pivot.role_id <=
                                    profile.current_user_role_id ||
                                  AuthService.checkPermission("manage profiles")
                                  ? [
                                      {
                                        variant: "secondary",
                                        label: "View",
                                        key: "delete",
                                        icon: <LuEye />,
                                        onClick: (data) =>
                                          openUserProfileDetailModal(data),
                                      },
                                      {
                                        variant: "danger",
                                        label:
                                          rowData.original.id ==
                                          AuthService.currentUser.id
                                            ? "Leave Profile"
                                            : "Remove",
                                        key: "delete",
                                        icon: <LuTrash />,
                                        onClick: (data) =>
                                          launchRemoveUserModal(data),
                                      },
                                    ] // Else we can view but not remove
                                  : [
                                      {
                                        variant: "secondary",
                                        label: "View",
                                        key: "delete",
                                        icon: <LuEye />,
                                        onClick: (data) =>
                                          openUserProfileDetailModal(data),
                                      },
                                    ] // Else the user hsa not accepted the invitation so we can only remove
                                : [
                                    {
                                      variant: "danger",
                                      label:
                                        rowData.original.id ==
                                        AuthService.currentUser.id
                                          ? "Leave Profile"
                                          : "Remove",
                                      key: "delete",
                                      icon: <LuTrash />,
                                      onClick: (data) =>
                                        launchRemoveUserModal(data),
                                    },
                                  ];
                            }
                          : null
                      }
                    />,
                  ],
                },
              ],
            },
          ]}
        />

        {/* only load if the user has the correct permissions */}
        {(profile.current_user_role_id == role.owner ||
          profile.current_user_role_id == role.manager ||
          AuthService.checkPermission("manage profiles")) && (
          <>
            <AddUserToProfile
              profileID={profile.id}
              visible={showAddUserModal}
              onSuccess={() => {
                userDatatableRef.current.refresh();
              }}
              onHide={() => setShowAddUserModal(false)}
            />
            <UserProfileDetailModal
              visible={showUserProfileModal}
              user={selectedUser}
              profile={profile}
              onHide={() => setShowUserProfileModal(false)}
              setVisibility={setShowUserProfileModal}
              onUpdateUser={() => {
                userDatatableRef.current.refresh();
                // re-render the sider
              }}
            />
            <RemoveUserFromProfileModal
              user={selectedUser}
              profile={profile}
              visible={showRemoveUserModal}
              onHide={() => setShowRemoveUserModal(false)}
              onSuccess={onRemoveUser}
            />
            <RemoveProfileModal
              visible={showDeleteModal}
              profile={profile}
              onHide={() => setShowDeleteModal(false)}
              onSuccess={() => {
                // If the user can manage profiles then return the user to the profiles screen else send them to the root page
                AuthService.checkPermission("manage profiles")
                  ? navigate("/profiles")
                  : navigate("/");
              }}
            />
          </>
        )}
      </>
    );
  } else {
    return (
      <div className="unAuthUtilityContainer mx-5">
        <Container className="p-5">
          <Row className="justify-content-md-center">
            <Col>
              <h2 className="pb-2 text-center">Profile Not Found</h2>
              <p className="pb-3 text-center">
                You do not have permission to see the profile you are are trying
                to access or it may have been removed. If you need access to a
                profile you are currently unable to see please contact a profile
                manager.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
