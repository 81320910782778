/* ------------------------------ core imports ------------------------------ */
import { useState } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs

// Internal Imports
import Modal from "../../../components/modals/Modal";
import Form from "../../../components/forms/Form";

const multipleProfilesAllowed =
  process.env.MIX_PROFILE_USER_HAS_MULTIPLE_PROFILES == "true";

export default function AddNewUserModal({
  onHide,
  visible,
  onSuccess: pOnSuccess,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Runs when the form submit is successful
  const onSuccess = async (data) => {
    // Run On Success method from parent
    pOnSuccess();

    // Hide Modal
    onHide();
  };

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      title={"Add a new user"}
      actions={[
        {
          label: "Add User",
          variant: "primary",
          key: "save",
          type: "submit",
          form: "add-user",
          disabled: isSubmitting,
        },
      ]}
    >
      <Form
        submitRoute="user"
        submitMethod="post"
        onSuccess={onSuccess}
        setIsSubmitting={setIsSubmitting}
        id="add-user"
        inputData={[
          {
            name: "forename",
            label: "Forename(s)",
            placeholder: "Enter forename(s)",
            type: "text",
            validation: Yup.string().required("Forename is required").max(255),
          },
          {
            name: "surname",
            label: "Surname",
            placeholder: "Enter surname",
            type: "text",
            validation: Yup.string().required("Surname is required").max(255),
          },
          {
            name: "email",
            label: "Email Address",
            placeholder: "Enter Email Address",
            type: "email",
            validation: Yup.string()
              .email()
              .required("Email is required")
              .max(255),
          },
          {
            name: "password",
            label: "Password",
            placeholder: "Enter users password",
            type: "password",
            validation: Yup.string()
              //.required("Password is required")
              .matches(
                new RegExp(process.env.MIX_PASSWORD_REGEX),
                "The password must contain one uppercase and lowercase character as well as one number and special character",
              ),
          },
          {
            name: "confirmPassword",
            label: "Confirm password",
            placeholder: "Confirm users new password",
            type: "password",
            validation: Yup.string()
              //.required("Confirm password is required")
              .oneOf(
                [Yup.ref("password"), null],
                "Password and confirm password must match",
              ),
          },
          {
            name: "roles",
            label: "Roles",
            type: "select",
            defaultValue: [],
            asyncRoute: "role",
            asyncMap: ({ name, id }) => {
              return { label: name, value: id };
            },
            isMulti: true,
          },
          {
            name: "profiles",
            label: multipleProfilesAllowed ? "Profiles" : "Profile",
            type: "select",
            defaultValue: [],
            asyncRoute: "profile",
            asyncMap: ({ name, id }) => {
              return { label: name, value: id };
            },
            isMulti: multipleProfilesAllowed,
          },
        ]}
      />
      <p>
        If you leave the password blank a password will be generated and sent to
        the user. Otherwise they will not receive their password.
      </p>
    </Modal>
  );
}
