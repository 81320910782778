import { indexDependencies } from "mathjs";
import { DarkMode } from "../../App";
import { Col, Form, Row } from "react-bootstrap";

/* ---------------------------- external imports ---------------------------- */
import toast from "react-hot-toast";

export default function FormRadio(props) {
  const { options, id, isDisabled, onChange, name, label } = props;

  if (options) {
    return (
      <>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          {options.map((option, index) => {
            return (
              <Form.Check
                key={index}
                type="radio"
                label={option.label}
                name={`name-${id}`}
                id={name + index}
                onChange={onChange}
                value={option.value}
                isDisabled={isDisabled}
              />
            );
          })}
        </Form.Group>
      </>
    );
  }
  return null;
}
