import { useContext } from "react";

//  internal imports
import BadgeGroup from "../BadgeGroup";
import { DarkMode } from "../App";

export default function BadgeGroupWidget({
  data,
  labelKey,
  colorIDKey,
  keyKey,
}) {
  // - context -
  const { darkMode } = useContext(DarkMode);
  return (
    <>
      <div
        className={`view-container ${darkMode ? "view-container_dark" : "view-container_light"}`}
      >
        <span className={darkMode ? "title-font_dark" : "title-font"}>
          Roles
        </span>
        <div>
          <BadgeGroup
            data={data}
            labelKey={labelKey}
            colorIDKey={colorIDKey}
            keyKey={keyKey}
          />
        </div>
      </div>
    </>
  );
}
