/* ------------------------------ core imports ------------------------------ */
import { useState, useRef, useEffect, useContext } from "react";

// - internal imports-
import { sanitiseObject } from "../../../services/data-formatting/objects";
import CardGroup from "../../data-display/CardGroup";
import ButtonGroup from "../../buttons/ButtonGroup";
import spark_file from "../../../../img/spark_file.png";
import { DarkMode } from "../../App";
import APIClient from "../../../services/clients/APIClient";
import LoadingSpinner from "../../LoadingSpinner";

// External Imports
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { LuPlus, LuTrash, LuFileDown } from "react-icons/lu";

// The File Types that the image view considers images
const acceptedImageTypes = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/webp",
];

// Basic Form Template including validation
export default function FormFileUpload(props) {
  // destruct props passed from parent
  const {
    name,
    onChange,
    label,
    onBlur,
    isValid,
    isInvalid,
    feedback,
    value = [],
    limit = Infinity,
    buttonLabel,
    style,
    isDisabled = false, // A Boolean used to specify if the upload and archive buttons within the component should be disabled or not
    isLoading = false, // A Boolean used to control if the component is loading
    showThumbnail = true, // A Boolean used to specify if the thumbnail should be shown
  } = props;
  // - context -
  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */
  // gets the file from the event target and passes it to the on change property method
  const [uploadedFiles, setUploadedFiles] = useState([]);

  /* ---------------------------------- refs ---------------------------------- */
  let inputRef = useRef();

  /* --------------------------------- effects -------------------------------- */
  // when the value is updated we need to update the uploadedFiles
  useEffect(() => {
    // if the value is not null then we can update the uploaded files
    if(value){
      if (Array.isArray(value)) {
        // if both arrays are empty we have noting to update otherwise we can update
        if (value.length != 0 || uploadedFiles.length != 0) {
          // Get array of files and attach source to files if needed
          const newUploadedFiles = Array.from(value).map(attachSourceToFile);
  
          // Get the number of files that should be added to the newUploadedFiles array
          const filesToAdd = limit - uploadedFiles.length;
  
          // Get the final array of files to be added to the state
          const finalUploadedFiles = newUploadedFiles.slice(0, filesToAdd);
  
          // Updates uploaded files if there are any changes
          if (uploadedFiles != finalUploadedFiles)
            setUploadedFiles(finalUploadedFiles);
        }
      } else {
        // attach source to file if needed
        const fileWithSource = attachSourceToFile(value);
  
        // Update uploaded files if there are any changes
        if (uploadedFiles != fileWithSource) setUploadedFiles([fileWithSource]);
      }
    } else {
      // set uploaded files to it's initial state (empty array)
      setUploadedFiles([]);
    }
  }, [value]);

  /* -------------------------------- functions ------------------------------- */
  // attaches a source to a file if it is an image
  // otherwise returns the file within an object with no source
  function attachSourceToFile(file) {
    // if file.source already exists then we don't need to attach our own
    if (file.source) {
      return file;
    } else {
      if (acceptedImageTypes.includes(file.type)) {
        // if the file is a file
        if (file instanceof File) {
          // create source using file and add meta data to object from File object
          return {
            file: file,
            source: URL.createObjectURL(file),
          };
        } else {
          // else treat file as object containing thumbnail
          return {
            ...file,
            file: file.thumbnail,
            source: file.thumbnail && URL.createObjectURL(file.thumbnail),
          };
        }
      } else {
        // Return file within object
        return file;
      }
    }
  }

  // a function that handles the file upload by saving the file to the state
  function handleFileUpload(e) {
    // Get files from event target
    const files = Array.from(e.target.files);

    // If limit is one, get the first file
    if (limit === 1) {
      // Get first file from files list
      const file = files[0];

      // Run on Change method after validation
      if(validateFile(file)) onChange(file);
    } else {
      // Get the number of files that should be added to the newUploadedFiles array
      const filesToAdd = limit - uploadedFiles.length;

      // Alert the user if they have reached the file limit
      if (files.length > filesToAdd) {
        toast.error(
          `Some files were not added to the upload list, You may only upload ${limit} files. `,
        );
      }
    
      // validate each file returning true if each one passes
      const filesValid = files.every(validateFile);

      // If the files are valid Run on Change method
      if(filesValid) onChange([...(value || []), ...files]);
    }
  }

  // validate a file
  function validateFile(file){
    // if file is null or undefined, return null
    if (!file) {
      toast.error("File is null or undefined, please try again");
      return false;
    } else if (file.size === 0) {
      toast.error("File has no size, please try again");
      return false;
    } else {
      return true
    }
  }

  // handle when a file is selected for removal
  function handleFileRemove(file) {
    const newUploadedFiles = uploadedFiles.filter((uploadedFile) => {
      // If the uploaded file it a File object
      if (uploadedFile instanceof File) {
        return uploadedFile?.name !== file?.name;
      } else if (uploadedFile?.file) {
        return uploadedFile?.file?.name !== file?.file?.name;
      } else {
        return uploadedFile?.name !== file?.name;
      }
    });

    setUploadedFiles(newUploadedFiles);

    if (limit == 1) {
      onChange(null);
    } else {
      onChange(newUploadedFiles);
    }
  }

  /* --------------------------- pre jsx computation -------------------------- */
  // sanitise props for the form control by removing any excluded keys
  const sanitisedProps = sanitiseObject({ ...props }, [
    "initialValue",
    "buttonLabel",
    "elementStyle",
    "colStyle",
    "isDisabled",
    "isLoading",
    "isVisible",
    "showThumbnail",
  ]);

  // - jsx -
  if (
    uploadedFiles.length > 0 &&
    !Array.isArray(uploadedFiles[0]) &&
    uploadedFiles[0] !== null
  ) {
    return (
      <Form.Group controlId={name} key={name}>
        {label && (
          <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
            {label}
          </Form.Label>
        )}
        <div
          style={{
            display: uploadedFiles.length === 0 ? "none" : "block",
            backgroundColor: "#f3f3f3",
            borderRadius: 10,
            padding: 20,
            marginBottom: 20,
            position: "relative",
          }}
        >
          <CardGroup
            shrinkAt={"xs"}
            cols={limit > 1 ? 6 : 12}
            data={uploadedFiles.map((file) => {
              let fileSize = file?.file?.size || file?.size || null;
              const fileSizeString = fileSize
                ? fileSize < 1048576
                  ? ` ${(fileSize / 1024).toFixed(2)} KB`
                  : ` ${(fileSize / 1048576).toFixed(2)} MB`
                : "";
              return {
                // if the file type is an image then display the image
                // otherwise display the file icon
                thumbnail: showThumbnail && {
                  // if file is null or undefined, use a default image
                  source:
                    acceptedImageTypes.includes(file.file?.type) && file.source
                      ? file.source
                      : spark_file,
                  alt: file.file?.name || "",
                  style: {
                    height: "150px",
                    objectFit: file.source ? "contain" : "cover",
                    backgroundColor: "#d9d9d9",
                  },
                },
                body: (
                  <div className="tw-flex tw-flex-wrap tw-justify-between">
                    <span className="tw-mt-2">
                      {file.name || file.file?.name}
                    </span>
                    <span className="tw-ml-auto tw-mr-0 tw-mt-1">
                      <ButtonGroup
                        className="tw-h-9 tw-whitespace-normal"
                        actions={[
                          ...(file?.downloadRoute
                            ? [
                                {
                                  icon: LuFileDown,
                                  tooltip: isLoading
                                    ? "Download (Disabled)"
                                    : `Download${fileSizeString}`,
                                  key: "download",
                                  type: "button",
                                  variant: "outline-secondary",
                                  disabled: isLoading,
                                  onClick: () =>
                                    APIClient.download(
                                      file.downloadRoute,
                                      file.name,
                                    ),
                                },
                              ]
                            : []),
                          {
                            icon: LuTrash,
                            tooltip:
                              isDisabled || isLoading
                                ? "Remove (Disabled)"
                                : "Remove",
                            key: "remove",
                            type: "button",
                            variant: "danger",
                            disabled: isDisabled || isLoading,
                            onClick: () => handleFileRemove(file),
                          },
                        ]}
                      />
                    </span>
                  </div>
                ),
              };
            })}
          />
          <LoadingSpinner isActive={isLoading} />
        </div>
        {/* Only allow option to upload files if we have not reached the max uploaded items value */}
        {uploadedFiles.length < limit && (
          <>
            <ButtonGroup
              actions={[
                {
                  icon: LuPlus,
                  label: buttonLabel
                    ? buttonLabel
                    : `Upload File${limit != 1 ? "s" : ""}`,
                  variant: "outline-secondary",
                  style: style && style,
                  type: "button",
                  disabled: isDisabled || isLoading,
                  onClick: () => {
                    inputRef.current.click();
                  },
                },
              ]}
              useRowsAndCols={true}
            />
            <Form.Control
              {...sanitisedProps}
              multiple={limit != 1 ? true : false}
              as="input"
              type="file"
              onChange={handleFileUpload}
              value={""}
              style={{ display: "none" }}
              ref={inputRef}
            />
          </>
        )}
        <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
      </Form.Group>
    );
  } else if (
    uploadedFiles.length === 0 ||
    Array.isArray(uploadedFiles[0]) ||
    uploadedFiles[0] === null
  ) {
    return (
      <Form.Group controlId={name} key={name}>
        <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
          {label}
        </Form.Label>
        <ButtonGroup
          buttonStyle={{ marginBottom: 20 }}
          actions={[
            {
              icon: LuPlus,
              label: buttonLabel
                ? buttonLabel
                : `Upload File${limit != 1 ? "s" : ""}`,
              variant: "outline-secondary",
              style: style && style,
              type: "button",
              disabled: isDisabled,
              onClick: () => {
                inputRef.current.click();
              },
            },
          ]}
          useRowsAndCols={true}
        />
        <Form.Control
          {...sanitisedProps}
          multiple={limit != 1 ? true : false}
          as="input"
          type="file"
          onChange={handleFileUpload}
          value={""}
          style={{ display: "none" }}
          ref={inputRef}
        />
        <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
      </Form.Group>
    );
  }
}
