/* ------------------------------ core imports ------------------------------ */
import { useContext, useRef } from "react";

/* ---------------------------- internal imports ---------------------------- */

import { DarkMode } from "../../components/App";

/* ---------------------------- external imports ---------------------------- */
import { useSortable } from "@dnd-kit/sortable"; // used to make sortable selects have draggable options
import { CSS } from "@dnd-kit/utilities";
import { LuGrip } from "react-icons/lu";

// Component that renders a single column in the draggable view column
export default function DraggableViewColumn(props) {
  // destruct props passed from parent
  const { column, columnData, setColumnData } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* ---------------------------------- refs ---------------------------------- */
  const ref = useRef();

  /* -------------------------------- functions ------------------------------- */
  // custom logic for transformation during dragging of selected option (Used for orderable multiselect)
  function transformAdapter(dndTransform, attributes, element) {
    // if the element is currently being dragged
    if (attributes && attributes["aria-pressed"]) {
      // use default translation
      return {
        transform: CSS.Translate.toString(dndTransform),
      };
    } else {
      // else the element is not being dragged so we should check if the dragged element is causing the element to move
      // determine if the option is moving down
      const movingDown = dndTransform?.y > 0;
      const movingUp = dndTransform?.y < 0;

      // if we are moving down transform y using the dndTransform
      let transformY = movingDown ? dndTransform?.y : 0;
      transformY = movingUp ? dndTransform?.y : transformY;

      // return styles to apply to option
      return { transform: `Translate(${0}px, ${transformY}px)` };
    }
  }

  // handles when the value of a checkbox is changed
  function handleCheckboxChange(toggleCol) {
    // create a copy of the column data
    const newColumnData = [...columnData];

    // get the index of the toggled column
    const activeIndex = columnData.findIndex(
      (column) => toggleCol.accessorKey === column.accessorKey,
    );

    // remove the toggled column from the array
    newColumnData.splice(activeIndex, 1);

    // toggle the show property of the toggled column
    toggleCol.show = !toggleCol.show;

    // add the toggled column back into the array at the end
    newColumnData.push(toggleCol);

    // use the new column data to update the column data of the parent
    setColumnData(newColumnData);
  }

  /* --------------------------- pre jsx computation -------------------------- */
  // get sortable properties to pass into draggable span if isSortable is true
  const {
    attributes,
    listeners,
    setNodeRef = null,
    transform,
    transition,
  } = useSortable({ id: column.accessorKey });

  // get styles for drag and drop if isSortable
  const style = {
    ...transformAdapter(transform, attributes, ref.current?.parentNode),
    transition,
  };

  return (
    <span ref={setNodeRef || null} style={style}>
      <label
        ref={ref}
        key={column.accessorKey}
        className={`${darkMode ? "tw-border-hue-500" : "tw-border-hue-200"} tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between tw-border-b tw-border-t tw-py-2 tw-text-sm`}
      >
        <LuGrip
          {...listeners}
          {...attributes}
          className={`"tw-text-black" tw-mx-1 tw-h-auto tw-text-sm`}
        />
        <span className="tw-font-sans tw-font-normal">{column.header}</span>
        <input
          type="checkbox"
          checked={column.show}
          onChange={() => handleCheckboxChange(column)}
        />
      </label>
    </span>
  );
}
