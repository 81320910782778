/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

// Internal Imports
import { DarkMode } from "../../App";

// external imports
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import { Form } from "react-bootstrap";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";

export default function FormTimeRangePicker(props) {
  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <Form.Group style={{ display: "flex", flexDirection: "column" }}>
      {props.label && (
        <Form.Label className={darkMode ? "form-label_dark" : "form-label"}>
          {props.label}
        </Form.Label>
      )}
      <TimeRangePicker
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.isDisabled}
        required={props.isRequired}
        clearIcon={null}
        calendarClassName={
          darkMode ? "spark-date-picker_dark" : "spark-date-picker"
        }
        className={`${darkMode ? "spark-datetimeformat-wrapper_dark" : "spark-datetimeformat-wrapper"}`}
        disableClock={true}
        // Using Inner Ref to fix Safari only issue where the date picker closes when clicking on the calendar see https://app.clickup.com/t/8693npfvz
        inputRef={(ref) => {
          ref?.setAttribute("tabindex", "0");
        }}
        {...props}
      />
    </Form.Group>
  );
}
