/* ------------------------------ core imports ------------------------------ */
import { useState, useRef, useEffect, forwardRef } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs

// Internal Imports
import ToggleableForm from "../../../components/forms/ToggleableForm";

export default forwardRef((props, ref) => {
  // destruct props
  const { profile, setProfile, profileID, canEdit } = props;

  // use ref passed in from parent as form ref or create new form ref
  const updateProfileForm = ref ? ref : useRef();

  const [isImageVisible, setIsImageVisible] = useState(false);

  useEffect(() => {
    // Update form with new profile details
    if (updateProfileForm.current) {
      updateProfileForm.current.initialValues = { name: profile.name };
      updateProfileForm.current.form() &&
        updateProfileForm.current.form().setValues({ name: profile.name });
    }
  }, [profile]);

  // Runs when the form submit is successful
  function onSuccess(response) {
    setProfile(response);

    // Update values based on what is returned from the backend
    updateProfileForm.current.form().setValues(
      {
        name: response.name,
      },
      false,
    );

    setIsImageVisible(false);
  }

  // handles when the user selects to edit the profile detail
  function onStartEdit() {
    setIsImageVisible(true);
  }

  if (profile) {
    return (
      <ToggleableForm
        ref={updateProfileForm}
        canEdit={canEdit}
        submitRoute={`profile/${profileID}`}
        submitMethod="patch"
        submitData={{
          includeImages: true,
          include: ["currentUserRole"],
          includeCount: ["users", "invitedUsers"],
        }}
        onSuccess={onSuccess}
        onStartEdit={onStartEdit}
        id="update-profile"
        resetOnSubmit={false}
        inputData={[
          {
            name: "name",
            label: "Profile Name",
            placeholder: "Name",
            type: "text",
            initialValue: profile.name,
            validation: Yup.string().required("A name is required").max(255),
          },
          {
            name: "picture",
            label: "Image",
            type: "file",
            isVisible: isImageVisible,
            limit: 1,
          },
        ]}
      />
    );
  } else {
    return <h1>Loading...</h1>;
  }
});
