/* ------------------------------ core imports ------------------------------ */
import { useState } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs
import { Button, Container, Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";

// Internal Imports
import Form from "../components/forms/Form";
import Spark from "../../img/SparkLogo.png";
import Linen from "../../img/black-linen.png";

// ForgotPassword.js
export default function ForgotPassword() {
  const [isSubmitting, setIsSubmitting] = useState(false); // stores errors for the backend

  // Runs when the form submit is successful
  const onSuccess = (response) => {
    // launch success toast
    toast.success(
      "Password reset email sent, please check your email inbox. Don't forget to check our junk folder as well.",
    );
  };

  // - styles -
  const styles = {
    background: {
      background: "rgb(119 119 119)",
      backgroundRepeat: "repeat",
      backgroundImage: `url(${Linen})`,
    },
  };

  return (
    <>
      <div
        className="tw-flex tw-h-screen tw-items-start tw-justify-center sm:tw-items-center "
        style={styles.background}
      >
        {/* white container box */}
        <div className="tw-m-5 tw-max-w-full tw-rounded-lg tw-bg-neutral-100 tw-p-10 tw-shadow-md sm:tw-max-w-md sm:tw-p-14 md:tw-m-0">
          {/* back button */}
          <button
            className="button-outline tw-mb-5"
            onClick={() => window.history.back()}
          >
            Back
          </button>
          {/* spark logo */}
          <div className="tw-mb-5 tw-flex tw-justify-center">
            <img src={Spark} alt="Bolt Logo" className="tw-h-auto tw-w-1/2" />
          </div>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2 className="tw-mb-3 tw-text-center tw-font-sans tw-text-xl tw-font-bold">
                Forgot your password?
              </h2>
              <p className="tw-mb-5 tw-text-center tw-font-sans">
                Input your email below and we'll send you some instructions on
                how to reset your password.
              </p>
              <div className="tw-mt-2 tw-w-full">
                <Form
                  submitRoute="forgot-password"
                  submitMethod="post"
                  onSuccess={onSuccess}
                  setIsSubmitting={setIsSubmitting}
                  id="forgot-password"
                  inputData={[
                    {
                      name: "email",
                      placeholder: "Enter you account email",
                      type: "text",
                      validation: Yup.string()
                        .email()
                        .required("Email is required"),
                    },
                  ]}
                />
              </div>
              <button
                className="button-regular tw-mt-2 tw-w-full"
                type="submit"
                form="forgot-password"
                disabled={isSubmitting}
              >
                Send reset instructions
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
