/* ------------------------------ core imports ------------------------------ */
import { useState } from "react";

// External Imports
import * as Yup from "yup"; // Used to define the validation for our inputs

// Internal Imports
import Modal from "../../../components/modals/Modal";
import Form from "../../../components/forms/Form";

// Launches a modal that can be used to change the selected users password
export default function UpdateUserPasswordModal({ onHide, visible, id }) {
  const [isSubmitting, setIsSubmitting] = useState(false); // stores errors for the backend

  // Runs when the form submit is successful
  const onSuccess = () => {
    // hide modal
    onHide();
  };

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      title={"Change Password"}
      actions={[
        {
          label: "Update Password",
          variant: "primary",
          key: "save",
          type: "submit",
          form: "update-password",
          disabled: isSubmitting,
        },
      ]}
    >
      <Form
        submitRoute={`user/${id}`}
        submitMethod="patch"
        onSuccess={onSuccess}
        setIsSubmitting={setIsSubmitting}
        id="update-password"
        inputData={[
          {
            name: "password",
            label: "New Password",
            placeholder: "Enter users new password",
            type: "password",
            validation: Yup.string()
              .min(10, "New password must be at least 10 characters")
              .required("New password is required")
              .matches(
                new RegExp(process.env.MIX_PASSWORD_REGEX),
                "The new password must contain one uppercase and lowercase character as well as one number and special character",
              ),
          },
          {
            name: "confirmPassword",
            label: "Confirm password",
            placeholder: "Confirm users new password",
            type: "password",
            validation: Yup.string()
              .required("Confirm password is required")
              .oneOf(
                [Yup.ref("password"), null],
                "New password and confirm password must match",
              ),
          },
        ]}
      />
    </Modal>
  );
}
