// Imports
import Model from "./Model";
import Profile from "./Profile";

export default class User extends Model {
  constructor(data) {
    // create modals for any objects that have models
    super(data, (key, value) => {
      switch (key) {
        case "active_profile":
          // Create single profile
          return new Profile(value);
        case "profiles":
        case "profile_invites":
        case "all_profiles":
          // create list of profiles
          return value.map((profile) => new Profile(profile));
        default:
          return value;
      }
    });

    // Once we have added the data to the object check if we don't have a active profile
    if (!this.active_profile) {
      // if we have an active profile ID use that to attach the matching Profile object
      if (this.active_profile_id && this.profiles) {
        // search profiles for matching ID
        this.active_profile = this.profiles.find(
          (profile) => profile.id == this.active_profile_id,
        );
      }

      // if active profile is still null set current profile to first profile in profiles
      if (!this.active_profile && this.profiles) {
        this.active_profile = this.profiles[0];
      }
    }
  }

  // returns true or false if the user has the permission
  checkPermission(permission) {
    switch (permission) {
      case "manage active profile":
        // check if the user manages the active profile or manages all profiles
        return this.active_profile
          ? this.active_profile.pivot.role_id >= 2 ||
              this.hasPermission("manage profiles")
          : false;
      default:
        // Check if the current user has the correct permission
        return this.hasPermission(permission);
    }
  }

  // Check if the user has a permission
  hasPermission(permission) {
    let hasPermission = false;

    this.permissions.forEach((usersPermission) => {
      if (permission == usersPermission.name) {
        hasPermission = true; // if permission is found return true
      }
    });

    // return if the user has permission
    return hasPermission;
  }

  // returns the users initials in uppercase
  getInitials() {
    let initials = "";

    if (AuthService.currentUser != null) {
      // split surnames based on space " "
      let forenames = AuthService.currentUser.forename.split(" ");

      let surnames = AuthService.currentUser.surname.split(" ");

      // Create initials by taking first char of each forename
      forenames.forEach((forename) => {
        initials += [...forename][0]; // get first char
      });

      // Create initials by taking first char of each surname
      surnames.forEach((surname) => {
        initials += [...surname][0]; // get first char
      });

      // make initials uppercase
      initials = initials.toUpperCase();
    }

    return initials;
  }
}
