/* ------------------------------ core imports ------------------------------ */
import { useEffect, useState } from "react";

/* ---------------------------- external imports ---------------------------- */
import ButtonGroup from "../buttons/ButtonGroup";
import styled from "styled-components";
import {
  LuSearch,
  LuFile,
  LuDatabase,
  LuDownload,
  LuRefreshCw,
} from "react-icons/lu";
import { toast } from "react-hot-toast";

/* ---------------------------- internal imports ---------------------------- */

import OngoingRefresh from "./OngoingRefresh";
import ViewManagerSelect from "../../views/view-manager/ViewManagerSelect";
import FilterManagerSelect from "../../views/filter-manager/elements/FilterManagerSelect";
import FormInput from "../../components/forms/inputs/FormInput";
import APIClient from "../../services/clients/APIClient";

// create styled div for button group container
const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default function DatatableActionHeader(props) {
  // destruct props passed from parent
  const {
    id, // the key/id of the datatable
    initialValue, // (Optional) the initial value of the search input
    onSearch, // callback method to run when the search input is changed
    hasViewManager = false, // (Optional) should the datatable header have a view manager
    hasFilterManager = false, // (Optional) should the datatable header have a filter manager
    hasExport = false, // (Optional) should the datatable header have an export button
    exportData, // (Optional) data to be exported
    hasRefresh = true, // (Optional) should the datatable header have a refresh button
    autoRefresh = false, // (Optional) should the datatable automatically refresh
    actions: additionalHeaderActions = [], // (Optional) additional header actions to be added to the header
    refreshDatatable, // function to run when the headers wants to refresh the datatable
    selectedView, // The selected view
    setSelectedView, // Callback function to run when the selected view is changed
    initialView, // The initial view id
    initialFilterId, // The id of the initial filter
    setSelectedFilter, // Callback function to run when the selected filter is changed
    columns, // The initial columns of the datatable
    initialAutoRefreshRate = 30, // The initial auto refresh rate
    route, // The route of the datatable (Used to fetch csv export data)
    requestData = { requestData },
  } = props;

  /* --------------------------------- state --------------------------------- */
  const [autoRefreshRate, setAutoRefreshRate] = useState(
    initialAutoRefreshRate,
  );

  /* --------------------------------- effects -------------------------------- */
  // Listen for changes in auto refresh
  useEffect(() => {
    if (
      autoRefresh != "false" &&
      autoRefresh != false &&
      autoRefreshRate != false
    ) {
      const interval = setInterval(() => {
        refreshDatatable("auto-refresh");
      }, autoRefreshRate * 1000);
      return () => clearInterval(interval);
    }
  }, [autoRefresh, autoRefreshRate]);

  /* -------------------------------- functions ------------------------------- */
  // handles when the selected filter manager is changed
  function HandleFilterManagerChange(option) {
    // if the filter has a connected view also set the view
    if (option) {
      setSelectedFilter(option);
      if (option.view)
        setSelectedView({
          label: `${option.view.name}`,
          value: option.view.id,
          data: option.view,
        });
    } else {
      // else reset the view to blank
      setSelectedFilter(null);
    }
  }

  // function to export all data form the backend
  function exportAllData() {
    return toast.promise(
      APIClient.download(route, "export.csv", {
        ...requestData, // add request data passed from parent component,
        "as-csv": true,
        limit: 100000, // Download up to 100,000 records
        after: 0,
      }),
      {
        loading: "Exporting all data please wait...",
        success: "Export complete",
        error: "Export failed",
      },
    );
  }

  return (
    <div className="action-header">
      <div className="tw-relative tw-min-w-28">
        <FormInput
          name="search"
          type="search"
          aria-label="Search"
          icon={LuSearch}
          placeholder="Search"
          defaultValue={initialValue}
          onChange={(e) => {
            onSearch(e.target.value);
          }}
        />
      </div>
      <ButtonGroupContainer>
        {/* all imported buttons from parent component */}
        <ButtonGroup
          containerStyle={{ height: "100%" }}
          actions={[
            ...additionalHeaderActions,
            ...(hasFilterManager
              ? [
                  {
                    type: "component",
                    component: (
                      <FilterManagerSelect
                        refresh={refreshDatatable}
                        columns={columns}
                        datatableKey={id}
                        initialFilterId={initialFilterId}
                        onChange={HandleFilterManagerChange}
                      />
                    ),
                  },
                ]
              : []),
            ...(hasViewManager
              ? [
                  {
                    type: "component",
                    component: (
                      <ViewManagerSelect
                        refresh={refreshDatatable}
                        columns={columns}
                        datatableKey={id}
                        initialViewId={initialView}
                        selectedView={selectedView}
                        onChange={setSelectedView}
                      />
                    ),
                  },
                ]
              : []),
            ...(autoRefresh
              ? [
                  {
                    type: "component",
                    component: (
                      <OngoingRefresh
                        autoRefreshRate={autoRefreshRate}
                        setAutoRefreshRate={setAutoRefreshRate}
                      />
                    ),
                  },
                ]
              : []),
            ...(hasExport && exportData
              ? [
                  route
                    ? {
                        label: "CSV Download",
                        key: "csv-download",
                        type: "dropdown",
                        icon: <LuDownload />,
                        actions: [
                          {
                            label: "Export Table",
                            filename: "export.csv",
                            type: "csvlink",
                            data: exportData,
                            icon: <LuFile />,
                          },
                          {
                            label: "Export All",
                            onClick: exportAllData,
                            icon: <LuDatabase />,
                          },
                        ],
                      }
                    : {
                        label: "CSV Download",
                        filename: "export.csv",
                        key: "csv-download",
                        variant: "secondary",
                        type: "csvlink",
                        data: exportData,
                      },
                ]
              : []),
            ...(hasRefresh
              ? [
                  {
                    tooltip: "Refresh Data",
                    key: "refresh",
                    type: "headerButton",
                    icon: <LuRefreshCw />,
                    className: "noRightMargin",
                    onClick: refreshDatatable,
                  },
                ]
              : []),
          ]}
        />
      </ButtonGroupContainer>
    </div>
  );
}
