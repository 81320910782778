/* ------------------------------ core imports ------------------------------ */
import { useState } from "react";
import ReactDOM from "react-dom";
import { Link, useNavigate } from "react-router-dom";

/* ---------------------------- external imports ---------------------------- */
import * as Yup from "yup"; // Used to define the validation for our inputs
import toast from "react-hot-toast";

// Internal Imports
import AuthService from "../services/AuthService";
import Form from "../components/forms/Form";
import Linen from "../../img/black-linen.png";
import Spark from "../../img/SparkLogo.png";
import UpdateMessages from "../../../updateMessages.json";

// Login.js
export default function Login() {
  let navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false); // stores errors for the backend

  // Runs when the form submit is successful
  const onSuccess = (response) => {
    // launch success toast
    toast.success("Logged in, welcome to Spark ⚡️");

    AuthService.storeAccessToken(
      response.access_token,
      response.token_expires_at,
    );

    // Navigate to home
    navigate("/");
  };

  const styles = {
    background: {
      background: "rgb(119 119 119)",
      backgroundRepeat: "repeat",
      backgroundImage: `url(${Linen})`,
    },
  };

  return (
    // background
    <div
      className="tw-flex tw-h-screen tw-items-start tw-justify-center sm:tw-items-center "
      style={styles.background}
    >
      {/* white container box */}
      <div className="tw-m-5 tw-w-full tw-rounded-2xl tw-bg-neutral-100 tw-p-14 tw-shadow-md sm:tw-w-[30rem] sm:tw-p-14 md:tw-m-0">
        {/* spark logo */}
        <div className="tw-mb-5 tw-flex tw-flex-col tw-items-center tw-justify-center">
          <img src={Spark} alt="Bolt Logo" className="tw-h-auto tw-w-8/12" />
          <span className="tw-text-lg tw-font-light tw-text-neutral-400">
            Version {UpdateMessages.version}
          </span>
        </div>
        <Form
          submitRoute="login"
          submitMethod="post"
          onSuccess={onSuccess}
          setIsSubmitting={setIsSubmitting}
          id="login"
          inputData={[
            {
              name: "email",
              label: "Email",
              placeholder: "Enter you account email",
              type: "text",
              validation: Yup.string().email().required("Email is required"),
            },
            {
              name: "password",
              label: "Password",
              placeholder: "Enter your account password",
              type: "password",
              validation: Yup.string().required("Password is required"),
            },
            {
              name: "remember_me",
              label: "Stay signed in",
              type: "checkbox",
              tooltip:
                "Check to stay signed in for a while or until you logout",
            },
          ]}
        />
        <button
          className="button-regular tw-w-full tw-text-lg"
          type="submit"
          form="login"
          disabled={isSubmitting}
        >
          Sign in
        </button>
        <div className="text-end">
          <Link to="/forgot-password" className="tw-text-hue-600">
            <span
              className="tw-font-sans tw-font-medium"
              style={{ fontSize: 14 }}
            >
              Forgot password?
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
