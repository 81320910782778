/* ------------------------------ core imports ------------------------------ */
import { useState, useEffect } from "react";

// External Imports
import UpdateAccountPasswordModal from "./elements/UpdateAccountPasswordModal";
import { LuActivity, LuMailCheck, LuListChecks } from "react-icons/lu";

// Internal Imports
import AuthService from "../../services/AuthService";
import LoadingSpinner from "../../components/LoadingSpinner";
import AccountDetailsForm from "./elements/AccountDetailsForm";
import { formatDateForDatatable } from "../../services/data-formatting/dates";
import DataRibbon from "../../components/data-display/DataRibbon";
import NavigationWidget from "../../components/widgets/NavigationWidget";

// structure
import DynamicLayout from "../../components/layouts/DynamicLayout";
import ProfileWidget from "../../components/widgets/ProfileWidget";
import FormWidget from "../../components/widgets/FormWidget";
import BadgeGroupWidget from "../../components/widgets/BadgeGroupWidget";

export default function Account(props) {
  /* --------------------------------- state --------------------------------- */
  const [showPasswordModal, setPasswordModal] = useState(false);
  const [user, setUser] = useState(AuthService.currentUser);
  const [ribbonData, setRibbonData] = useState([]);

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    if (!user) return;
    let ribbonData = [
      {
        label: `Last Active: ${formatDateForDatatable(user.last_active_at)}`,
        icon: LuActivity,
      },
      {
        label: `Email Verified: ${formatDateForDatatable(user.email_verified_at)}`,
        icon: LuMailCheck,
      },
      {
        label: `Registered: ${formatDateForDatatable(user.created_at)}`,
        icon: LuListChecks,
      },
    ];
    setRibbonData(ribbonData);
  }, [user]);

  if (AuthService.currentUser == null) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <>
        <DataRibbon data={ribbonData} />
        <NavigationWidget
          backRoute="/"
          backMessage="Dashboard"
          actions={[
            {
              variant: "outline-secondary",
              label: "Reset Users Password",
              key: "reset-password",
              onClick: () => setPasswordModal(true),
            },
          ]}
        />
        <DynamicLayout
          data={[
            {
              gap: 5,
              cols: [
                {
                  width: 4,
                  content: (
                    <>
                      <ProfileWidget user={user} />
                      <BadgeGroupWidget
                        data={AuthService.currentUser.roles}
                        labelKey="name"
                        colorIDKey="id"
                        keyKey="id"
                      />
                    </>
                  ),
                },
                {
                  width: 8,
                  content: (
                    <FormWidget title="About">
                      <AccountDetailsForm user={user} setUser={setUser} />
                    </FormWidget>
                  ),
                },
              ],
            },
          ]}
        />
        <UpdateAccountPasswordModal
          visible={showPasswordModal}
          onHide={() => setPasswordModal(false)}
        />
      </>
    );
  }
}
