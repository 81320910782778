/* ------------------------------ core imports ------------------------------ */
import {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";

/* ---------------------------- internal imports ---------------------------- */

import ButtonGroup from "../../../components/buttons/ButtonGroup";
import SingleRule from "./elements/SingleRule";
import { DarkMode } from "../../../components/App";

/* ---------------------------- external imports ---------------------------- */
import { LuPlus } from "react-icons/lu";
import { Row, Col } from "react-bootstrap";

const organisationBaseData = [
  "id",
  "name",
  "claims_count",
  "current_users_deals_count",
  "template_id",
  "primary_contact_id",
  "signatory_contact_id",
  "import_id",
  "metric_type_id",
  "created_at",
  "updated_at",
  "do_not_call",
];
const dealBaseData = [
  "id",
  "name",
  "users_count",
  "template_id",
  "created_at",
  "updated_at",
];

export default forwardRef((props, ref) => {
  // destructure props
  const {
    initialRules, // An array of objects representing the initial state of the rules
    isEditor, // Can the current user edit the rules group
    fieldOptions, // The fieldOptions that can be used for filtering with the datatable
  } = props;

  /* --------------------------------- state --------------------------------- */
  // Holds the rules in the rules group
  const [rules, setRules] = useState(initialRules);
  // Holds the id of the next new rule
  const [nextNewRuleId, setNextNewRuleId] = useState(-1);

  // - context -
  const { darkMode } = useContext(DarkMode);

  // - refs
  // instantiate ref array to hold the role refs
  const ruleRefs = useRef([]);

  /* --------------------------------- effects -------------------------------- */
  // When we update the initial rules we should reset the current rules
  useEffect(() => {
    if (initialRules) {
      setRules(initialRules);
    } else {
      return;
    }
  }, [initialRules]);

  // use imperative handle allows the parent to access functions from this component
  useImperativeHandle(ref, () => ({
    gRules,
  }));

  /* -------------------------------- functions ------------------------------- */
  // gets the rules from children as array of objects ready for backend submission
  function gRules(conjunction_id) {
    // For each rules ref
    return ruleRefs.current.map((ruleRef) => {
      if (ruleRef?.form().values != null) {
        const values = ruleRef.form().values;

        // Build rule object ready for backend submission
        return {
          id: nextNewRuleId,
          operator_id: values.operator.value,
          conjunction_id: conjunction_id,
          values: [
            encodeRuleValue(values.type_1.value, values.value_1),
            encodeRuleValue(values.type_2.value, values.value_2),
          ],
        };
      }
    });
  }

  // encodes a rule value ready for backend storage
  function encodeRuleValue(type_id, value) {
    return { type_id, value: value?.value || value };
  }

  // adds a new rule to the rule array
  function nRule() {
    if (Array.isArray(rules)) {
      // add item to rules array
      setRules([
        ...rules,
        {
          id: nextNewRuleId,
          operator_id: 1,
          values: [
            { type_id: 1, value: "" },
            { type_id: 2, value: "" },
          ],
        },
      ]);
    } else {
      // create new rules array with one new item
      setRules([
        {
          id: nextNewRuleId,
          operator_id: 1,
          values: [
            { type_id: 1, value: "" },
            { type_id: 2, value: "" },
          ],
        },
      ]);
    }

    // Reduce ID of next role by 1
    setNextNewRuleId(nextNewRuleId - 1);
  }

  // removes a rule from the rules array by id
  function dRule(id) {
    // removes any rules that have an id matching the one passed in as parameter
    setRules(
      rules.filter((rule) => {
        return rule.id != id;
      }),
    );
  }

  /* --------------------------------- markup --------------------------------- */
  return (
    <div>
      {rules && rules.length !== 0 && (
        <div className="tw-flex tw-pb-1">
          <div className="tw-flex tw-w-full tw-items-center">
            <span className="tw-w-full tw-px-2 tw-font-sans tw-text-sm tw-font-bold">
              Type
            </span>
            <span className="tw-w-full tw-px-2 tw-font-sans tw-text-sm tw-font-bold">
              Value
            </span>
            <span className="tw-w-full tw-px-2 tw-font-sans tw-text-sm tw-font-bold">
              Operator
            </span>
            <span className="tw-w-full tw-px-2 tw-font-sans tw-text-sm tw-font-bold">
              Type
            </span>
            <span className="tw-w-full tw-px-2 tw-font-sans tw-text-sm tw-font-bold">
              Value
            </span>
          </div>
          <div className="tw-min-w-12">
            <span className="tw-font-sans tw-text-sm tw-font-bold">Action</span>
          </div>
        </div>
      )}

      {rules &&
        rules?.map((rule, index) => (
          <SingleRule
            disabled={!isEditor}
            key={rule.id}
            initialRule={rule}
            onRemove={() => dRule(rule.id)}
            ref={(element) => (ruleRefs.current[index] = element)}
            fieldOptions={fieldOptions}
          />
        ))}
      {(!rules || rules?.length === 0) && (
        <div
          className={`${darkMode ? "tw-bg-hue-600" : "tw-bg-white"} tw-flex tw-flex-col tw-justify-center tw-rounded-md tw-p-10 tw-shadow-sm`}
        >
          <h4
            className={` ${darkMode ? "tw-text-hue-50" : "tw-text-hue-900"}tw-font-sans tw-text-center tw-text-xl tw-font-bold`}
          >
            No Rules
          </h4>
          <p
            className={`${darkMode ? "tw-text-hue-100" : "tw-text-hue-900"} tw-text-center tw-font-sans`}
          >
            Add a rule to get started
          </p>
        </div>
      )}

      <div className="tw-flex tw-items-center tw-justify-center">
        <ButtonGroup
          actions={[
            {
              disabled: !isEditor,
              tooltip: "New Rule",
              icon: <LuPlus />,
              variant: "secondary",
              onClick: nRule,
              label: "New Rule",
              style: { marginTop: 20, marginBottom: 20 },
            },
          ]}
        />
      </div>
    </div>
  );
});
