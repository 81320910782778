import { useContext } from "react";
import { DarkMode } from "../../components/App";

export default function HiddenViewColumn(props) {
  // destructuring data
  const {
    columnsToShow, // the data that is currently being displayed, is a result of the search
    columnData, // the data that is being used to render the checkboxes, is the full list of columns, before the search
    setColumnData, // the function that will be used to update the column data
  } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  /* -------------------------------- functions ------------------------------- */
  // handles when the value of a checkbox is changed
  function handleCheckboxChange(toggleCol) {
    // create a copy of the column data
    const newColumnData = [...columnData];

    // get the index of the toggled column
    const activeIndex = columnData.findIndex(
      (column) => toggleCol.accessorKey === column.accessorKey,
    );

    // remove the toggled column from the array
    newColumnData.splice(activeIndex, 1);

    // toggle the show property of the toggled column
    toggleCol.show = !toggleCol.show;

    // add the toggled column back into the array at the end
    newColumnData.push(toggleCol);

    // use the new column data to update the column data of the parent
    setColumnData(newColumnData);
  }

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-border-hue-200 tw-py-2">
      {columnsToShow.map((col, index) => {
        return (
          <label
            key={index}
            className={`${darkMode ? "tw-border-hue-500" : "tw-border-hue-200"} tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between tw-border-t tw-py-2 tw-text-sm`}
          >
            <span className="tw-font-sans tw-font-normal">{col.header}</span>
            <input
              type="checkbox"
              checked={col.show}
              onChange={() => handleCheckboxChange(col)}
            />
          </label>
        );
      })}
    </div>
  );
}
