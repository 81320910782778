// External Imports
import chroma from "chroma-js";

// Internal Imports
import { getFirstLetters } from "../services/data-formatting/strings";
import { getColorFromValue } from "../services/AutomaticColourPicker";

// Component used to create a profile picture for a given user and size
export default function ProfilePicture({ user, size }) {
  // setting the color to the id colour equvilient from a function we have passed into this component
  const color = getColorFromValue(user.id);

  let render = null;

  // Get container and picture text classes
  switch (size) {
    case "full":
      render = (
        <div
          className="profile-picture-container-full"
          style={{ backgroundColor: color }}
        >
          <span
            className="profile-picture-text-large"
            style={{
              color: chroma.contrast(color, "white") > 2 ? "white" : "black",
            }}
          >
            {getFirstLetters(user.forename + " " + user.surname)}
          </span>
        </div>
      );
      break;
    case "large":
      render = (
        <div
          className="profile-picture-container-large"
          style={{ backgroundColor: color }}
        >
          <span
            className="profile-picture-text-large"
            style={{
              color: chroma.contrast(color, "white") > 2 ? "white" : "black",
            }}
          >
            {getFirstLetters(user.forename + " " + user.surname)}
          </span>
        </div>
      );
      break;
    case "medium":
      render = (
        <div
          className="profile-picture-container-medium"
          style={{ backgroundColor: color }}
        >
          <span
            className="profile-picture-text-medium"
            style={{
              color: chroma.contrast(color, "white") > 2 ? "white" : "black",
            }}
          >
            {getFirstLetters(user.forename + " " + user.surname)}
          </span>
        </div>
      );
      break;
    default: // Small
      render = (
        <div
          className="profile-picture-container-small"
          style={{ backgroundColor: color }}
        >
          <span
            className="profile-picture-text-small"
            style={{
              color: chroma.contrast(color, "white") > 2 ? "white" : "black",
            }}
          >
            {getFirstLetters(user.forename + " " + user.surname)}
          </span>
        </div>
      );
  }

  // Return our profile picture
  return render;
}
