/* ------------------------------ core imports ------------------------------ */
import { useContext } from "react";

/* ---------------------------- external imports ---------------------------- */
import {
  LuChevronsLeft,
  LuChevronsRight,
  LuArrowLeft,
  LuArrowRight,
} from "react-icons/lu";

/* ---------------------------- internal imports ---------------------------- */

import { DarkMode } from "../App";

// A component to display the pagination controls for a table
export default function DatatablePagination(props) {
  // destruct props passed from parent
  const {
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
    pageSize,
    lengthOptions, // any custom data table legnths we'd like to display ([{'Label: ' : 20, 'Label: ' : 30] ... })
  } = props;

  // - context -
  const { darkMode } = useContext(DarkMode);

  return (
    <div className="mb-5">
      <div className="tw-columns-1 xl:tw-columns-3">
        <div className="tw-mb-4 tw-flex tw-columns-4 tw-justify-center xl:tw-justify-start">
          <div
            className={
              darkMode
                ? "pagination-arrow-button_dark"
                : "pagination-arrow-button"
            }
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {<LuChevronsLeft />}
          </div>
          <div
            className={
              darkMode
                ? "pagination-arrow-button_dark"
                : "pagination-arrow-button"
            }
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {<LuArrowLeft />}
          </div>
          <div
            className={
              darkMode
                ? "pagination-arrow-button_dark"
                : "pagination-arrow-button"
            }
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {<LuArrowRight />}
          </div>
          <div
            className={
              darkMode
                ? "pagination-arrow-button_dark"
                : "pagination-arrow-button"
            }
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {<LuChevronsRight />}
          </div>
        </div>

        <div className="tw-mb-4 tw-flex tw-items-center tw-justify-center">
          <div className="pagination-listing">
            <span>
              {"Page "}
              <strong>
                {Number(pageIndex) + 1} of {pageCount + " "}
              </strong>
            </span>
          </div>
          <span className="tw-pl-2">
            {"| Go to page: "}
            <input
              className={
                darkMode
                  ? "paginate-dropdown_dark tw-ml-2"
                  : "paginate-dropdown tw-ml-2"
              }
              type="number"
              value={Number(pageIndex) + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>
        </div>

        <div className="tw-mb-4 tw-flex tw-flex-row tw-justify-center xl:tw-flex-row-reverse xl:tw-justify-between">
          <select
            className={darkMode ? "paginate-option_dark" : "paginate-option"}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {lengthOptions.map((lengthOption) => (
              <option key={lengthOption.value} value={lengthOption.value}>
                {lengthOption.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
