/* ------------------------------ core imports ------------------------------ */
import { useContext, Fragment, useState, useEffect } from "react";

/* ---------------------------- external imports ---------------------------- */
import { Tab, Popover, Transition } from "@headlessui/react";

/* ---------------------------- internal imports ---------------------------- */

import { DarkMode } from "../App";
import { LuChevronDown } from "react-icons/lu";
import { truncate } from "../../services/data-formatting/strings";

// Houses the core structure for most pages in the system
export default function GeneralTabs(props) {
  // destruct props passed from parent
  const {
    categories = [],
    overflowLimit = 5, // The amount of items to display in the general tab before the overflow dropdown is utilized
    backgroundClasses, // An object that contains light and dark classes that can be applied to the background of the tab system
    closePopoverOnTabChange = true, // A boolean that determines if the popover should be closed when the tab is changed
  } = props;

  const { darkMode } = useContext(DarkMode);

  /* --------------------------------- state --------------------------------- */
  const [primaryTabs, setPrimaryTabs] = useState([]);
  const [overflowGroupTabs, setOverflowGroupTabs] = useState([]);
  const [overflowSingleTabs, setOverflowSingleTabs] = useState([]);

  /* --------------------------------- effects -------------------------------- */
  useEffect(() => {
    const categoryKeys = Object.keys(categories);

    // slice categories up until overflow limit for primary tabs
    setPrimaryTabs(categoryKeys.slice(0, overflowLimit));

    const overflow = categoryKeys.slice(overflowLimit);

    let overflowGroups = [];
    let overflowSingles = [];

    // foreach overflow category key determine if it is a group of single
    overflow.forEach((categoryKey) => {
      const categoryData = categories[categoryKey];

      // if the category data is an object that is not a component add it to the overflowGroups array
      if (typeof categoryData === "object" && !categoryData.$$typeof) {
        overflowGroups.push(categoryKey);
      } else {
        // else add it to the overflowSingles
        overflowSingles.push(categoryKey);
      }
    });

    // update overflow group tabs with new data
    setOverflowGroupTabs(overflowGroups);

    // update overflow singles with new data
    setOverflowSingleTabs(overflowSingles);
  }, [categories]);

  // runs when the current tab is changed
  function onTabChanged() {
    if (closePopoverOnTabChange) {
      // get all the general tab triggers so we can attempt to close any open popovers
      const generalTabTriggers = [
        ...document.getElementsByClassName("general-tab-trigger"),
        ...document.getElementsByClassName("general-tab-trigger_dark"),
      ];

      // loop through the general tab triggers
      generalTabTriggers.forEach((generalTabTrigger) => {
        // get the popover button from the general tab trigger
        const popoverButton = generalTabTrigger.querySelector("button");

        // if we have found a popover button we should attempt to close it
        if (popoverButton) {
          // check if the popover button is open
          const isOpen =
            popoverButton.getAttribute("data-headlessui-state") == "open";

          // if the popover button is open we should close it
          if (isOpen) {
            popoverButton.click();
          }
        }
      });
    }
  }

  return (
    <div className={
      darkMode
        ? `general-tab-content-holder_dark ${backgroundClasses && backgroundClasses.dark ? backgroundClasses.dark : "tw-bg-hue-800"}`
        : `general-tab-content-holder ${backgroundClasses && backgroundClasses.light ? backgroundClasses.light : "tw-bg-white"}`
    } >
      <Tab.Group onChange={onTabChanged}>
        {/* The Tab.List component renders the tab list */}
        <Tab.List
          className={darkMode ? "general-tab-list_dark" : "general-tab-list"}
        >
          {/* filter out the overflow items and map through whats left */}
          {primaryTabs.map((categoryName) => {
            // get the category data from the list of categories
            const categoryData = categories[categoryName];

            // if the category is an array and has more than one item, then it is a dropdown
            if (typeof categoryData === "object" && !categoryData.$$typeof) {
              // render a Popover component for the dropdown
              return (
                <Popover
                  key={categoryName}
                  className={`${darkMode ? "general-tab-trigger_dark" : "general-tab-trigger"} tw-relative`}
                >
                  {({ open }) => (
                    <>
                      {/* render the dropdown trigger */}
                      <Popover.Button>
                        <div className="tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-whitespace-nowrap">
                          {categoryName}
                          <LuChevronDown
                            className={`${open && "tw-rotate-180 tw-transform"} tw-float-right tw-ml-2 tw-text-lg tw-transition-transform`}
                          />
                        </div>
                      </Popover.Button>
                      {/* render the dropdown content */}
                      <Transition
                        unmount={false}
                        as={Fragment}
                        enter="tw-transition tw-ease-out tw-duration-200"
                        enterFrom="tw-opacity-0 tw-Translate-y-1"
                        enterTo="tw-opacity-100 tw-Translate-y-0"
                        leave="tw-transition tw-ease-in tw-duration-150"
                        leaveFrom="tw-opacity-100 tw-Translate-y-0"
                        leaveTo="tw-opacity-0 tw-Translate-y-1"
                      >
                        <Popover.Panel
                          unmount={false}
                          className="tw-absolute tw-right-0 tw-z-10 tw-mt-5 tw-flex tw-w-screen tw-max-w-max tw-p-0"
                        >
                          <div
                            className={
                              darkMode
                                ? "general-tab-overflow-container_dark"
                                : "general-tab-overflow-container"
                            }
                          >
                            <div className="tw-flex tw-flex-col">
                              {/* render the dropdown items */}
                              {Object.keys(categoryData).map((key) => (
                                <Tab
                                  key={key}
                                  className={({ selected }) =>
                                    selected
                                      ? darkMode
                                        ? "general-tab-overflow_selected_dark"
                                        : "general-tab-overflow_selected"
                                      : darkMode
                                        ? "general-tab-overflow_dark"
                                        : "general-tab-overflow"
                                  }
                                >
                                  {truncate(key, 25)}
                                </Tab>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              );
            } else {
              // render a regular tab
              return (
                <Tab
                  key={categoryName}
                  className={({ selected }) =>
                    selected
                      ? darkMode
                        ? "general-tab_selected_dark"
                        : "general-tab_selected_light"
                      : darkMode
                        ? "general-tab_dark"
                        : "general-tab_light"
                  }
                >
                  {truncate(categoryName, 25)}
                </Tab>
              );
            }
          })}

          {/* render the overflow dropdown if there are any overFlowTabs */}
          {(overflowGroupTabs.length > 0 || overflowSingleTabs.length > 0) && (
            <Popover
              className={
                darkMode
                  ? "general-tab-overflow-button_dark"
                  : "general-tab-overflow-button"
              }
            >
              {({ open }) => (
                <>
                  <Popover.Button class="general-tab-popover-button">
                    <LuChevronDown
                      className={`${open && "tw-rotate-180 tw-transform"} tw-float-right tw-mx-2 tw-ml-2 tw-text-lg tw-transition-transform`}
                    />
                  </Popover.Button>
                  {/* render the overflow dropdown content */}
                  <Transition
                    as={Fragment}
                    ent="tw-transition tw-ease-out tw-duration-200"
                    enterFrom="tw-opacity-0 tw-Translate-y-1"
                    enterTo="tw-opacity-100 tw-Translate-y-0"
                    leave="tw-transition tw-ease-in tw-duration-150"
                    leaveFrom="tw-opacity-100 tw-Translate-y-0"
                    leaveTo="tw-opacity-0 tw-Translate-y-1"
                  >
                    <Popover.Panel className="tw-absolute tw-right-0 tw-z-10 tw-mt-5 tw-flex tw-w-screen tw-max-w-max tw-p-0">
                      <div
                        className={
                          darkMode
                            ? "general-tab-overflow-container_dark tw-top-8"
                            : "general-tab-overflow-container tw-top-8"
                        }
                      >
                        {overflowGroupTabs.map((categoryName) => {
                          // get the category data from the list of categories
                          const categoryData = categories[categoryName];
                          return (
                            <span key={categoryName}>
                              <span
                                className={
                                  darkMode
                                    ? "tw-text-left tw-font-sans tw-text-xs tw-font-bold tw-text-hue-200"
                                    : "tw-text-left tw-font-sans tw-text-xs tw-font-bold tw-text-hue-500"
                                }
                              >
                                {categoryName}
                              </span>
                              <hr className="tw-my-2 tw-text-hue-400" />
                              <div className="tw-grid tw-grid-cols-4 tw-gap-2">
                                {Object.keys(categoryData).map((key) => (
                                  <Tab
                                    key={key}
                                    className={({ selected }) =>
                                      selected
                                        ? darkMode
                                          ? "general-tab-overflow_selected_dark"
                                          : "general-tab-overflow_selected"
                                        : darkMode
                                          ? "general-tab-overflow_dark"
                                          : "general-tab-overflow"
                                    }
                                  >
                                    {truncate(key, 25)}
                                  </Tab>
                                ))}
                              </div>
                            </span>
                          );
                        })}
                        {/* If we have single overflow tabs and group overflow tabs then display a title for the single overflow tabs */}
                        {overflowSingleTabs.length > 0 &&
                          overflowGroupTabs.length > 0 && (
                            <>
                              <span
                                className={
                                  darkMode
                                    ? "tw-pt-43 tw-text-left tw-font-sans tw-text-xs tw-font-bold tw-text-hue-200"
                                    : "tw-pt-4 tw-text-left tw-font-sans tw-text-xs tw-font-bold tw-text-hue-500"
                                }
                              >
                                Other
                              </span>
                              <hr className="tw-my-2 tw-text-hue-400" />
                            </>
                          )}
                        <div className="tw-grid tw-grid-cols-4 tw-gap-2">
                          {/* render the overflow dropdown items */}
                          {overflowSingleTabs.map((categoryName) => (
                            <Tab
                              key={categoryName}
                              className={({ selected }) =>
                                selected
                                  ? darkMode
                                    ? "general-tab-overflow_selected_dark"
                                    : "general-tab-overflow_selected"
                                  : darkMode
                                    ? "general-tab-overflow_dark"
                                    : "general-tab-overflow"
                              }
                            >
                              {categoryName}
                            </Tab>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          )}
        </Tab.List>
        {/* The Tab.Panels component renders the content for each tab */}
        <Tab.Panels className="sm:tw-m-7 tw-m-2">
          {Object.values(categories).map((categoryData, idx) => {
            // if the category is an array and has more than one item, then it is a dropdown
            if (typeof categoryData === "object" && !categoryData.$$typeof) {
              // if the content is an array and has more than one item, then render each item as a separate tab panel
              return Object.values(categoryData).map((item, idx) => (
                <Tab.Panel
                  unmount={false}
                  key={idx}
                >
                  <span>{item}</span>
                </Tab.Panel>
              ));
            } else {
              // render a single tab panel for the content
              return (
                <Tab.Panel
                  unmount={false}
                  key={idx}
                >
                  {categoryData}
                </Tab.Panel>
              );
            }
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
